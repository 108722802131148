import * as i0 from "@angular/core";
/**
 * Type of name to be shown
 */
export var NameStringType;
(function (NameStringType) {
    NameStringType[NameStringType["FirstNameAndLastName"] = 0] = "FirstNameAndLastName";
    NameStringType[NameStringType["FirstNameAndFirstCharOfLastName"] = 1] = "FirstNameAndFirstCharOfLastName";
    NameStringType[NameStringType["FirstName"] = 2] = "FirstName";
    NameStringType[NameStringType["LastName"] = 3] = "LastName";
})(NameStringType || (NameStringType = {}));
var PersonService = /** @class */ (function () {
    function PersonService() {
    }
    /**
     * It returns the desired name string from ClientChatMessage.
     * @param person The person.
     * @param type The desired type of name.
     * @returns desired type of name. By default returns FirstNameAndLastName.
     */
    PersonService.getNameStringFromClientChatMessage = function (msg, type) {
        switch (type) {
            case NameStringType.FirstNameAndLastName: {
                return msg.senderFirstName + ' ' + msg.senderLastName;
            }
            case NameStringType.FirstNameAndFirstCharOfLastName: {
                return msg.senderFirstName + ' ' + msg.senderLastName.charAt(0) + '.';
            }
            case NameStringType.LastName: {
                return msg.senderLastName;
            }
            case NameStringType.FirstName: {
                return msg.senderFirstName;
            }
            default: {
                return msg.senderFirstName + ' ' + msg.senderLastName;
            }
        }
    };
    /**
     * It returns the desired name string from ClientPersonReference.
     * @param person The person.
     * @param type The desired type of name.
     * @returns desired type of name. By default returns FirstNameAndLastName.
     */
    PersonService.getNameStringFromClientPersonReference = function (person, type) {
        if (person.status == 'DELETED')
            return 'gelöschter Nutzer';
        switch (type) {
            case NameStringType.FirstNameAndLastName: {
                return person.firstName + ' ' + person.lastName;
            }
            case NameStringType.FirstNameAndFirstCharOfLastName: {
                return person.firstName + ' ' + person.lastName.charAt(0) + '.';
            }
            case NameStringType.LastName: {
                return person.lastName;
            }
            case NameStringType.FirstName: {
                return person.firstName;
            }
            default: {
                return person.firstName + ' ' + person.lastName;
            }
        }
    };
    /**
     * It returns the desired name string from ClientPerson.
     * @param person The person.
     * @param type The desired type of name.
     * @returns desired type of name. By default returns FirstNameAndLastName.
     */
    PersonService.getNameStringFromClientPerson = function (person, type) {
        if (person.status == 'DELETED')
            return 'gelöschter Nutzer';
        switch (type) {
            case NameStringType.FirstNameAndLastName: {
                return person.firstName + ' ' + person.lastName;
            }
            case NameStringType.FirstNameAndFirstCharOfLastName: {
                return person.firstName + ' ' + person.lastName.charAt(0);
            }
            case NameStringType.LastName: {
                return person.lastName;
            }
            case NameStringType.FirstName: {
                return person.firstName;
            }
            default: {
                return person.firstName + ' ' + person.lastName;
            }
        }
    };
    /**
     * Shorten name of a person
     * @param person The person
     * @param maxLen The desired lenght (>3)
     */
    PersonService.getShortenedName = function (person, maxLen) {
        if (person.status == 'DELETED')
            return 'gelöschter Nutzer';
        var original = person.firstName + ' ' + person.lastName;
        if (original.length <= maxLen) {
            return original;
        }
        else {
            return original.substr(0, maxLen - 3) + '...';
        }
    };
    PersonService.ngInjectableDef = i0.defineInjectable({ factory: function PersonService_Factory() { return new PersonService(); }, token: PersonService, providedIn: "root" });
    return PersonService;
}());
export { PersonService };
