import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AllCurrentSuggestionsLoadedSuccess, AllCurrentSuggestionsLoadedFail, SuggestionActionTypes, SuggestionStatusChangeRequestSuccess, SuggestionStatusChangeRequestFail, AllArchiveSuggestionsLoadedSuccess, AllArchiveSuggestionsLoadedFail, SuggestionWorkerAddRequestSuccess, SuggestionWorkerAddRequestFail, SuggestionWorkerRemoveRequestSuccess, SuggestionWorkerRemoveRequestFail, GetExtendedSuggestioRequest, GetExtendedSuggestioRequestSuccess, GetExtendedSuggestioRequestFail, SuggestionCategoryChangeRequestFail, SuggestionCategoryChangeRequestSuccess, GetSuggestionCategoriesRequestSuccess, GetSuggestionCategoriesRequestFail, UpdateSuggestionRequestSuccess, LatestSuggestionsRequestSuccess, LatestSuggestionsRequestFail, UpdateLastCurrentSelectedSuggestionRequest, UpdateLastArchivedSelectedSuggestionRequest, UpdateSuggestionRequestFail, ShowSuggestionInlineError, SuggestionUpdateCounters, SetActiveSuggestion, GetExtendedSuggestionSilentSuccess, GetExtendedSuggestioSilentFail, SuggestionCreateRequestSuccess, SuggestionCreateRequestFail, } from './suggestions.action';
import { catchError, map, mergeMap, withLatestFrom, switchMap, concatMap, } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { GrapevineSuggestionService } from '@app/api/services/grapevine-suggestion.service';
import { GrapevineSuggestionEventsService } from '@app/api/services';
import { throwError, of, EMPTY } from 'rxjs';
import { selectCurrentSuggestions, selectArchivedSuggestions, selectSuggestionById, selectCurrentSuggestionStatusFilter, selectAssignmentFilter, selectFirstCurrentSuggestion, selectFirstArchivedSuggestion, } from './suggestion.selector';
import { SuggestionStatus } from '@app/loesbar/businesslogic/models/suggestion-status';
import { AssignmentFilterType } from '@app/loesbar/businesslogic/models/assignment-filter';
import { NotificationService, MessageType, } from '@app/core/services/notificationService';
var SuggestionEffects = /** @class */ (function () {
    function SuggestionEffects(actions$, suggestionsServices, suggestionsEventService, store, notificationService) {
        var _this = this;
        this.actions$ = actions$;
        this.suggestionsServices = suggestionsServices;
        this.suggestionsEventService = suggestionsEventService;
        this.store = store;
        this.notificationService = notificationService;
        this.loadAllCurrentSuggestions$ = this.actions$.pipe(ofType(SuggestionActionTypes.AllCurrentSuggestionRequest), withLatestFrom(this.store.pipe(select(selectCurrentSuggestions)), this.store.pipe(select(selectCurrentSuggestionStatusFilter)), this.store.pipe(select(selectAssignmentFilter))), mergeMap(function (payload) {
            var params = {
                excludedStatus: [SuggestionStatus.DONE],
                includedStatus: payload[2],
                count: 25,
                page: Math.floor(payload[1].length / 25),
            };
            if (payload[3].type === AssignmentFilterType.UNASSIGNED) {
                params['unassigned'] = true;
            }
            else if (payload[3].type === AssignmentFilterType.MINE) {
                params['containedWorkerId'] = payload[3].containedWorkerId;
            }
            return _this.suggestionsServices
                .getExtendedSuggestionsUsingGET(tslib_1.__assign({}, params, { sortBy: 'LAST_ACTIVITY' }))
                .pipe(map(function (response) {
                return new AllCurrentSuggestionsLoadedSuccess({
                    suggestions: response.content,
                    total: response.totalElements,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new AllCurrentSuggestionsLoadedFail(err));
                return throwError(err);
            }));
        }));
        this.loadAllArchiveSuggestions$ = this.actions$.pipe(ofType(SuggestionActionTypes.AllArchiveSuggestionsRequested), withLatestFrom(this.store.pipe(select(selectArchivedSuggestions)), this.store.pipe(select(selectAssignmentFilter))), mergeMap(function (payload) {
            var params = {
                includedStatus: [SuggestionStatus.DONE],
                count: 15,
                page: Math.floor(payload[1].length / 15),
                sortBy: 'LAST_ACTIVITY',
            };
            if (payload[2].type === AssignmentFilterType.UNASSIGNED) {
                params['unassigned'] = true;
            }
            else if (payload[2].type === AssignmentFilterType.MINE) {
                params['containedWorkerId'] = payload[2].containedWorkerId;
            }
            return _this.suggestionsServices
                .getExtendedSuggestionsUsingGET(tslib_1.__assign({}, params, { sortBy: 'LAST_ACTIVITY' }))
                .pipe(map(function (response) {
                return new AllArchiveSuggestionsLoadedSuccess({
                    suggestions: response.content,
                    total: response.totalElements,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new AllArchiveSuggestionsLoadedFail(err));
                return throwError(err);
            }));
        }));
        this.loadLatestUpdatedSuggestions$ = this.actions$.pipe(ofType(SuggestionActionTypes.LatestSuggestionsRequest), mergeMap(function () {
            return _this.suggestionsServices
                .getExtendedSuggestionsUsingGET({
                count: 10,
                page: 0,
                sortBy: 'LAST_ACTIVITY',
            })
                .pipe(map(function (response) {
                return new LatestSuggestionsRequestSuccess({
                    suggestions: response.content,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new LatestSuggestionsRequestFail(err));
                return throwError(err);
            }));
        }));
        this.saveSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionStatusChangeRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsEventService
                .onSuggestionStatusChangeRequestUsingPOST({
                request: {
                    suggestionId: payload.suggestion.id,
                    newStatus: payload.suggestion.suggestionStatus,
                },
            })
                .pipe(map(function (response) {
                var changes = response.changedSuggestion;
                var updatedSuggestion = {
                    id: response.changedSuggestion.id,
                    changes: changes,
                };
                _this.updateLastSelectedSuggestion(payload.lastSuggestionStatus, response.changedSuggestion.suggestionStatus, updatedSuggestion.id);
                _this.updateSuggestionCounters(response, payload);
                return new SuggestionStatusChangeRequestSuccess({
                    suggestion: updatedSuggestion,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new SuggestionStatusChangeRequestFail(err));
                return throwError(err);
            }));
        }));
        this.addWorkerToSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionWorkerAddRequest), concatMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsEventService
                .onSuggestionWorkerAddRequestUsingPOST({
                request: {
                    suggestionId: payload.suggestionId,
                    workerPersonId: payload.workerPersonId,
                },
            })
                .pipe(map(function (response) {
                var changes = response.changedSuggestion;
                var updatedSuggestion = {
                    id: response.changedSuggestion.id,
                    changes: changes,
                };
                return new SuggestionWorkerAddRequestSuccess({
                    suggestion: updatedSuggestion,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new SuggestionWorkerAddRequestFail(err));
                return throwError(err);
            }));
        }));
        this.removeWorkerFromSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionWorkerRemoveRequest), concatMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsEventService
                .onSuggestionWorkerRemoveRequestUsingPOST({
                request: { suggestionId: payload.suggestionId },
            })
                .pipe(map(function (response) {
                var changes = response.changedSuggestion;
                var updatedSuggestion = {
                    id: response.changedSuggestion.id,
                    changes: changes,
                };
                return new SuggestionWorkerRemoveRequestSuccess({
                    suggestion: updatedSuggestion,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new SuggestionWorkerRemoveRequestFail(err));
                return throwError(err);
            }));
        }));
        this.getExtendedSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.GetExtendedSuggestioRequest), concatMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsServices
                .getExtendedSuggestionUsingGET({
                suggestionId: payload.suggestionId,
            })
                .pipe(map(function (response) {
                return new GetExtendedSuggestioRequestSuccess({
                    suggestion: response,
                });
            }), catchError(function (error) {
                if (error.status == 403) {
                    return of(new ShowSuggestionInlineError(error));
                }
                else {
                    _this.store.dispatch(new GetExtendedSuggestioRequestFail(error));
                    return throwError(error);
                }
            }));
        }));
        // Effect triggered by GetExtendedSuggestionRequestSilent
        // used by unread-message-counter-service, fails silently
        this.getExtendedSuggestionSilent$ = this.actions$.pipe(ofType(SuggestionActionTypes.GetExtendedSuggestionRequestSilent), concatMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsServices
                .getExtendedSuggestionUsingGET({
                suggestionId: payload.suggestionId,
            })
                .pipe(map(function (response) {
                return new GetExtendedSuggestionSilentSuccess({
                    suggestion: response,
                });
            }), catchError(function (error) {
                return of(new GetExtendedSuggestioSilentFail(error));
            }));
        }));
        this.updateSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.UpdateSuggestionRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsServices
                .getExtendedSuggestionUsingGET({
                suggestionId: payload.suggestionId,
            })
                .pipe(map(function (response) {
                var updatedSuggestion = {
                    id: response.id,
                    changes: response,
                };
                return new UpdateSuggestionRequestSuccess({
                    suggestion: updatedSuggestion,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new UpdateSuggestionRequestFail(err));
                return throwError(err);
            }));
        }));
        this.changeSuggestionCategory$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionCategoryChangeRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionsEventService
                .onSuggestionCategoryChangeRequestUsingPOST({
                request: payload.suggestion,
            })
                .pipe(map(function (response) {
                var updatedSuggestion = {
                    id: response.changedSuggestion.id,
                    changes: response.changedSuggestion,
                };
                return new SuggestionCategoryChangeRequestSuccess({
                    suggestion: updatedSuggestion,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new SuggestionCategoryChangeRequestFail(err));
                return throwError(err);
            }));
        }));
        this.getSuggestionCategories$ = this.actions$.pipe(ofType(SuggestionActionTypes.GetSuggestionCategoriesRequest), switchMap(function () {
            return _this.suggestionsServices.getSuggestionCategoriesUsingGET().pipe(map(function (response) {
                return new GetSuggestionCategoriesRequestSuccess({ categories: response });
            }), catchError(function (err) {
                _this.store.dispatch(new GetSuggestionCategoriesRequestFail(err));
                return throwError(err);
            }));
        }));
        this.setActiveSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.SetActiveSuggestionRequest), map(function (action) { return action.payload; }), mergeMap(function (payload) {
            return of(payload).pipe(withLatestFrom(_this.store.pipe(select(selectSuggestionById(payload.suggestionId)))));
        }, function (payload, latestStoreData) { return latestStoreData; }), switchMap(function (_a) {
            var foundSuggestion = _a[1];
            var activeSuggestionId = foundSuggestion ? foundSuggestion.id : '';
            return of(new SetActiveSuggestion({ suggestionId: activeSuggestionId }));
        }));
        this.setFirstCurrentSuggestionActive$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionSetFirstCurrentActive), withLatestFrom(this.store.pipe(select(selectFirstCurrentSuggestion))), switchMap(function (payload) {
            if (payload[1]) {
                return of(new SetActiveSuggestion({ suggestionId: payload[1].id }));
            }
            else {
                return EMPTY;
            }
        }));
        this.setFirstArchiveSuggestionActive$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionSetFirstArchiveActive), withLatestFrom(this.store.pipe(select(selectFirstArchivedSuggestion))), switchMap(function (payload) {
            if (payload[1]) {
                return of(new SetActiveSuggestion({ suggestionId: payload[1].id }));
            }
            else {
                return EMPTY;
            }
        }));
        this.createSuggestion$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionCreateRequest), switchMap(function (createRequest) {
            return _this.suggestionsEventService
                .onSuggestionCreateRequestUsingPOST({
                request: createRequest.payload.clientSuggestionCreateRequest,
                appVariantIdentifier: '',
            })
                .pipe(map(function (confirmation) {
                return new SuggestionCreateRequestSuccess({
                    clientPostCreateConfirmation: confirmation,
                });
            }), catchError(function (err) {
                _this.notificationService.showToastMessage(err.error.message, 'Fehler bei Übermittlung', MessageType.ERROR);
                _this.store.dispatch(new SuggestionCreateRequestFail(err));
                return throwError(err);
            }));
        }));
        this.createSuggestionSuccess$ = this.actions$.pipe(ofType(SuggestionActionTypes.SuggestionCreateRequestSuccess), switchMap(function (confirmation) {
            return of(new GetExtendedSuggestioRequest({
                suggestionId: confirmation.payload.clientPostCreateConfirmation.post.id,
            }));
        }));
    }
    SuggestionEffects.prototype.updateSuggestionCounters = function (response, payload) {
        if (response.changedSuggestion.suggestionStatus === SuggestionStatus.DONE) {
            // move from current --> archive
            this.store.dispatch(new SuggestionUpdateCounters({
                deltaArchive: 1,
                deltaCurrent: -1,
            }));
        }
        else {
            // new status != DONE, what was it before?
            if (payload.lastSuggestionStatus === SuggestionStatus.DONE) {
                // move from archive --> current
                this.store.dispatch(new SuggestionUpdateCounters({
                    deltaArchive: -1,
                    deltaCurrent: 1,
                }));
            }
        }
    };
    /**
     * Updates the lastCurrentSelectedSuggestionID and lastArchivedSelectedSuggestionID if needed.
     * 1. If the Updated suggestion changes the status from 'DONE' to 'IN_PROGRESS' or 'OPEN'  is needed to update
     *    lastArchivedSelectedSuggestionID to undefined and lastCurrentSelectedSuggestionID to the updated suggestion ID
     * 2. If the Updated suggestion changes the status from 'IN_PROGRESS' or 'OPEN' to 'DONE'  is needed to update
     *    lastCurrentSelectedSuggestionID to undefined and lastArchivedSelectedSuggestionID to the updated suggestion ID
     * 3. If the Updated suggestion changes the status from 'IN_PROGRESS' to 'OPEN' or vice-versa, no change is needed
     * @param lastSuggestionStatus
     * @param currentSuggestionStatus
     * @param suggestionId
     */
    SuggestionEffects.prototype.updateLastSelectedSuggestion = function (lastSuggestionStatus, currentSuggestionStatus, suggestionId) {
        if (lastSuggestionStatus === SuggestionStatus.DONE &&
            currentSuggestionStatus !== SuggestionStatus.DONE) {
            this.store.dispatch(new UpdateLastCurrentSelectedSuggestionRequest({ suggestionId: suggestionId }));
            this.store.dispatch(new UpdateLastArchivedSelectedSuggestionRequest({
                suggestionId: undefined,
            }));
        }
        else if (lastSuggestionStatus !== SuggestionStatus.DONE &&
            currentSuggestionStatus === SuggestionStatus.DONE) {
            this.store.dispatch(new UpdateLastArchivedSelectedSuggestionRequest({ suggestionId: suggestionId }));
            this.store.dispatch(new UpdateLastCurrentSelectedSuggestionRequest({
                suggestionId: undefined,
            }));
        }
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "loadAllCurrentSuggestions$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "loadAllArchiveSuggestions$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "loadLatestUpdatedSuggestions$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "saveSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "addWorkerToSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "removeWorkerFromSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "getExtendedSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "getExtendedSuggestionSilent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "updateSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "changeSuggestionCategory$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "getSuggestionCategories$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "setActiveSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "setFirstCurrentSuggestionActive$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "setFirstArchiveSuggestionActive$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "createSuggestion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SuggestionEffects.prototype, "createSuggestionSuccess$", void 0);
    return SuggestionEffects;
}());
export { SuggestionEffects };
