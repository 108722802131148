/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader2.component";
var styles_Loader2Component = [i0.styles];
var RenderType_Loader2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Loader2Component, data: {} });
export { RenderType_Loader2Component as RenderType_Loader2Component };
export function View_Loader2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "app-loading"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, ":svg:svg", [["class", "spinner"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 0, 0, currVal_0); }); }
export function View_Loader2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader2", [], null, null, null, View_Loader2Component_0, RenderType_Loader2Component)), i1.ɵdid(1, 114688, null, 0, i2.Loader2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Loader2ComponentNgFactory = i1.ɵccf("app-loader2", i2.Loader2Component, View_Loader2Component_Host_0, { isLoading: "isLoading", message: "message" }, {}, []);
export { Loader2ComponentNgFactory as Loader2ComponentNgFactory };
