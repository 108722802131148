/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-out.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./logged-out.component";
var styles_LoggedOutComponent = [i0.styles];
var RenderType_LoggedOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedOutComponent, data: {} });
export { RenderType_LoggedOutComponent as RenderType_LoggedOutComponent };
export function View_LoggedOutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "logged-out container-flex h-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["You are logged out"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["routerLink", "/login"], ["translate", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(9, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_3 = ""; _ck(_v, 8, 0, currVal_3); var currVal_4 = "/login"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).target; var currVal_2 = i1.ɵnov(_v, 9).href; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_LoggedOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logged-out", [], null, null, null, View_LoggedOutComponent_0, RenderType_LoggedOutComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoggedOutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedOutComponentNgFactory = i1.ɵccf("app-logged-out", i5.LoggedOutComponent, View_LoggedOutComponent_Host_0, {}, {}, []);
export { LoggedOutComponentNgFactory as LoggedOutComponentNgFactory };
