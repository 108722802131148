import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { RouterModule } from '@angular/router';
import { BurgerMenuComponent } from './burger-menu/burger-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Loader2Component } from './loader2/loader2.component';
import { InputCacheDirective } from './input-cache.directive';
import { LinkyModule } from 'ngx-linky';
import { InlineErrorComponent } from './inline-error/inline-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropDirective } from './drag-drop.directive';
import { UserProfileComponent } from './burger-menu/user-profile/user-profile.component';

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule, LinkyModule, FormsModule, ReactiveFormsModule],
  declarations: [
    LoaderComponent,
    Loader2Component,
    BurgerMenuComponent,
    InputCacheDirective,
    InlineErrorComponent,
    DragDropDirective,
    UserProfileComponent,
  ],
  exports: [
    LoaderComponent,
    Loader2Component,
    BurgerMenuComponent,
    InputCacheDirective,
    LinkyModule,
    InlineErrorComponent,
    DragDropDirective,
  ],
})
export class SharedModule {}
