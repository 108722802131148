import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../services/logger.service';
import { AuthenticationService } from '../authentication/authentication.service';

const log = new Logger('SilentAuthInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class SilentAuthInterceptor implements HttpInterceptor {
  private _refreshSubject: Subject<any> = new Subject<any>();

  constructor(private authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (this._checkTokenExpiryErr(error)) {
            console.log('_checkTokenExpiryErr is true');
            return this._ifTokenExpired().pipe(
              switchMap(() => {
                return next.handle(this.updateHeader(req));
              }),
            );
          } else {
            return throwError(error);
          }
        }
        return caught;
      }),
    );
  }

  updateHeader(req: HttpRequest<any>) {
    const authToken = this.authService.authToken.accessToken;
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });
    return req;
  }

  private _ifTokenExpired() {
    this._refreshSubject.subscribe({
      error: err => {
        log.error(err);
        log.debug('Not authenticated, redirecting to login...');
        this.authService.login(); // Try normal login on error
      },
      complete: () => {
        console.log('_refreshSubject complete');
        this._refreshSubject = new Subject<any>();
      },
    });
    if (this._refreshSubject.observers.length === 1) {
      this.authService.renewTokens().subscribe(this._refreshSubject);
    }
    return this._refreshSubject;
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return error.status && error.status === 401;
  }
}
