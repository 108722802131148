import { Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { preload: true }, ɵ1 = { preload: true };
var routes = [
    Shell.childRoutes([
        {
            path: '',
            redirectTo: 'vorschlag/aktuell',
            pathMatch: 'full',
        },
        {
            path: 'vorschlag',
            loadChildren: './suggestion-ui/suggestion-ui.module#SuggestionUIModule',
            data: ɵ0,
        },
        {
            path: 'chat',
            loadChildren: './chat-ui/chat-ui.module#ChatUIModule',
            data: ɵ1,
        },
    ]),
];
var LoesbarRoutingModule = /** @class */ (function () {
    function LoesbarRoutingModule() {
    }
    return LoesbarRoutingModule;
}());
export { LoesbarRoutingModule };
export { ɵ0, ɵ1 };
