import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { burgerMenuReducer } from './userdata/userdata.reducer';
import { BurgerMenuEffects } from './userdata/userdata.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('userdata', burgerMenuReducer),
    EffectsModule.forFeature([BurgerMenuEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class SharedStoreModule {}
