import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotificationService } from './notificationService';
import { Store } from '@ngrx/store';
import { NetworkStatusAction, CoreState } from '@app/core/store/core-store';
import { NetworkStatus } from '@app/loesbar/businesslogic/models/network-status';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private store: Store<CoreState>,
  ) {}

  manageActionServerError(error: HttpErrorResponse): void {
    // CASE: 403. Should redirect in any 403 cases
    if (error.status === 403) {
      this.router.navigate(['/error', error.status], {
        replaceUrl: true,
      });
    }

    // CASE: 0. Unknown Error and not internet connection
    if (error.status === 0) {
      if (!navigator.onLine) {
        this.store.dispatch(
          new NetworkStatusAction({
            networkStatus: NetworkStatus.OFFLINE_MODE,
          }),
        );
      } else {
        this.store.dispatch(
          new NetworkStatusAction({
            networkStatus: NetworkStatus.NO_CONNECTION,
          }),
        );
      }
    }

    // server error
    if (error.status > 499) {
      this.store.dispatch(
        new NetworkStatusAction({ networkStatus: NetworkStatus.SERVER_ERROR }),
      );
    }
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'Keine Internetverbindung';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.error.message;
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return error.error.type;
  }
}
