import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { LoginComponent } from './login.component';
import { CallbackComponent } from './callback/callback.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
var ɵ0 = { title: extract('Login') };
var routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0,
    },
    { path: 'callback', component: CallbackComponent },
    { path: 'logged-out', component: LoggedOutComponent },
];
var LoginRoutingModule = /** @class */ (function () {
    function LoginRoutingModule() {
    }
    return LoginRoutingModule;
}());
export { LoginRoutingModule };
export { ɵ0 };
