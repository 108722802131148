import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/reducers';
import * as fromSuggestion from './suggestion.reducer';
import { ClientSuggestionExtended } from '@app/api/models';
import {
  AssignmentFilter,
  AssignmentFilterType,
} from '@app/loesbar/businesslogic/models/assignment-filter';
import { SuggestionStatus } from '@app/loesbar/businesslogic/models/suggestion-status';

function isAssignmentFiltered(
  suggestion: ClientSuggestionExtended,
  filter: AssignmentFilter,
) {
  switch (filter.type) {
    case AssignmentFilterType.ALL:
      return true;
      break;
    case AssignmentFilterType.UNASSIGNED:
      return suggestion.suggestionWorkerMappings.length == 0;
      break;
    case AssignmentFilterType.MINE:
      let personIds = suggestion.suggestionWorkerMappings.map(
        wm => wm.worker.id,
      );
      return personIds.includes(filter.containedWorkerId);
    default:
      return true;
  }
}
export const selectSuggestionState = createFeatureSelector<
  fromSuggestion.SuggestionState
>('suggestions');

export const selectCurrentSuggestionStatusFilter = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.currentSuggestionStatusFilter,
);

export const selectAssignmentFilter = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.assignmentFilter,
);

export const selectSuggestionsAreFiltered = createSelector(
  selectSuggestionState,
  suggestionState =>
    suggestionState.assignmentFilter.type != AssignmentFilterType.ALL ||
    suggestionState.currentSuggestionStatusFilter.length > 0,
);

export const selectAllSuggestions = createSelector(
  selectSuggestionState,
  fromSuggestion.selectAll,
);

export const allCurrentSuggestionsLoaded = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.allCurrentSuggestionsLoaded,
);

export const selectCurrentSuggestions = createSelector(
  selectAllSuggestions,
  selectCurrentSuggestionStatusFilter,
  selectAssignmentFilter,
  (currentSuggestions, suggestionStatusFilter, assignmentFilter) =>
    currentSuggestions.filter(
      suggestion =>
        suggestion.suggestionStatus !== 'DONE' &&
        isAssignmentFiltered(suggestion, assignmentFilter) &&
        (suggestionStatusFilter.length === 0 ||
          suggestion.suggestionStatus === suggestionStatusFilter[0]),
    ),
);

export const selectArchivedSuggestions = createSelector(
  selectAllSuggestions,
  selectAssignmentFilter,
  (archiveSuggestions, assignmentFilter) =>
    archiveSuggestions.filter(
      suggestion =>
        suggestion.suggestionStatus === 'DONE' &&
        isAssignmentFiltered(suggestion, assignmentFilter),
    ),
);

export const isLoadingCurrentSuggestion = createSelector(
  selectSuggestionState,
  fromSuggestion.loadingCurrentSuggestion,
);

export const isLoadingArchiveSuggestion = createSelector(
  selectSuggestionState,
  fromSuggestion.loadingArchiveSuggestion,
);

export const isLoadingSingleSuggestion = createSelector(
  selectSuggestionState,
  fromSuggestion.loadingSingleSuggestion,
);

export const selectSuggestionById = (suggestionId: string) =>
  createSelector(
    selectSuggestionState,
    suggestionState => suggestionState.entities[suggestionId],
  );

export const selectSuggestionByRouteParam = createSelector(
  selectSuggestionState,
  fromRoot.getRouterState,
  (suggestionState, router): ClientSuggestionExtended => {
    return router.state && suggestionState.entities[router.state.params.id];
  },
);

export const selectSuggestionCategories = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.categories,
);

export const selectLastCurrentSuggestionId = createSelector(
  selectSuggestionState,
  suggestionState => {
    return suggestionState.lastCurrentSelectedSuggestionID
      ? suggestionState.lastCurrentSelectedSuggestionID
      : '';
  },
);

export const selectLastArchivedSuggestionId = createSelector(
  selectSuggestionState,
  suggestionState => {
    return suggestionState.lastArchivedSelectedSuggestionID
      ? suggestionState.lastArchivedSelectedSuggestionID
      : '';
  },
);

export const selectFirstCurrentSuggestion = createSelector(
  selectCurrentSuggestions,
  suggestions => {
    if (suggestions && suggestions.length > 0) {
      return suggestions[0];
    } else {
      return undefined;
    }
  },
);

export const selectFirstArchivedSuggestion = createSelector(
  selectArchivedSuggestions,
  suggestions => {
    if (suggestions && suggestions.length > 0) {
      return suggestions[0];
    } else {
      return undefined;
    }
  },
);

export const selectInlineError = createSelector(
  selectSuggestionState,
  fromSuggestion.inlineError,
);

export const selectTotalNumberOfCurrentSuggestions = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.totalCurrentSuggestions,
);

export const selectTotalNumberOfArchiveSuggestions = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.totalArchiveSuggestions,
);

export const selectSuggestionEntities = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.entities,
);

export const selectActiveSuggestionId = createSelector(
  selectSuggestionState,
  suggestionState => suggestionState.activeSuggestionId,
);

export const selectActiveSuggestion = createSelector(
  selectSuggestionEntities,
  selectActiveSuggestionId,
  (suggestions, selectedSuggedstionId) => suggestions[selectedSuggedstionId],
);
