import { Injectable } from '@angular/core';
import {
  ClientPersonReference,
  ClientChatMessage,
  ClientPerson,
} from '@app/api/models';

/**
 * Type of name to be shown
 */
export enum NameStringType {
  FirstNameAndLastName,
  FirstNameAndFirstCharOfLastName,
  FirstName,
  LastName,
}
@Injectable({
  providedIn: 'root',
})
export class PersonService {
  /**
   * It returns the desired name string from ClientChatMessage.
   * @param person The person.
   * @param type The desired type of name.
   * @returns desired type of name. By default returns FirstNameAndLastName.
   */
  static getNameStringFromClientChatMessage(
    msg: ClientChatMessage,
    type: NameStringType,
  ): string {
    switch (type) {
      case NameStringType.FirstNameAndLastName: {
        return msg.senderFirstName + ' ' + msg.senderLastName;
      }
      case NameStringType.FirstNameAndFirstCharOfLastName: {
        return msg.senderFirstName + ' ' + msg.senderLastName.charAt(0) + '.';
      }
      case NameStringType.LastName: {
        return msg.senderLastName;
      }
      case NameStringType.FirstName: {
        return msg.senderFirstName;
      }
      default: {
        return msg.senderFirstName + ' ' + msg.senderLastName;
      }
    }
  }

  /**
   * It returns the desired name string from ClientPersonReference.
   * @param person The person.
   * @param type The desired type of name.
   * @returns desired type of name. By default returns FirstNameAndLastName.
   */
  static getNameStringFromClientPersonReference(
    person: ClientPersonReference,
    type: NameStringType,
  ): string {
    if (person.status == 'DELETED') return 'gelöschter Nutzer';
    switch (type) {
      case NameStringType.FirstNameAndLastName: {
        return person.firstName + ' ' + person.lastName;
      }
      case NameStringType.FirstNameAndFirstCharOfLastName: {
        return person.firstName + ' ' + person.lastName.charAt(0)+'.';
      }
      case NameStringType.LastName: {
        return person.lastName;
      }
      case NameStringType.FirstName: {
        return person.firstName;
      }
      default: {
        return person.firstName + ' ' + person.lastName;
      }
    }
  }
  /**
   * It returns the desired name string from ClientPerson.
   * @param person The person.
   * @param type The desired type of name.
   * @returns desired type of name. By default returns FirstNameAndLastName.
   */
  static getNameStringFromClientPerson(
    person: ClientPerson,
    type: NameStringType,
  ): string {
    if (person.status == 'DELETED') return 'gelöschter Nutzer';
    switch (type) {
      case NameStringType.FirstNameAndLastName: {
        return person.firstName + ' ' + person.lastName;
      }
      case NameStringType.FirstNameAndFirstCharOfLastName: {
        return person.firstName + ' ' + person.lastName.charAt(0);
      }
      case NameStringType.LastName: {
        return person.lastName;
      }
      case NameStringType.FirstName: {
        return person.firstName;
      }
      default: {
        return person.firstName + ' ' + person.lastName;
      }
    }
  }
  constructor() {}
  /**
   * Shorten name of a person
   * @param person The person
   * @param maxLen The desired lenght (>3)
   */
  static getShortenedName(
    person: ClientPersonReference,
    maxLen: number,
  ): string {
    if (person.status == 'DELETED') return 'gelöschter Nutzer';
    const original = person.firstName + ' ' + person.lastName;
    if (original.length <= maxLen) {
      return original;
    } else {
      return original.substr(0, maxLen - 3) + '...';
    }
  }
}
