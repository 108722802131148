import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import {
  CommunicationChatEventsService,
  CommunicationChatService,
} from '@app/api/services';
import {
  SendMessageRequest,
  MessagesActionTypes,
  SendMessageRequestSuccess,
  SendMessageRequestFail,
  PrevPageReadRequest,
  PrevPageReadRequestSuccess,
  PrevPageReadRequestFail,
} from './messages.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers';
import { UpdateLastReceivedMessageTimestampRequestME } from '../chats/chats.actions';

@Injectable()
export class MessagesEffects {
  @Effect()
  sendMessage$ = this.actions$.pipe(
    ofType<SendMessageRequest>(MessagesActionTypes.SendMessageRequest),
    switchMap(({ payload }) =>
      this.communicationChatEventsService
        .sendMessageUsingPOST({ clientRequest: payload.message })
        .pipe(
          map(response => {
            this.store.dispatch(
              new UpdateLastReceivedMessageTimestampRequestME({
                lastReceivedMessageTimestamp: response.chatMessage.created,
              }),
            );
            return new SendMessageRequestSuccess({
              message: response.chatMessage,
            });
          }),
          catchError(err => {
            this.store.dispatch(new SendMessageRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  prevPageRead$ = this.actions$.pipe(
    ofType<PrevPageReadRequest>(MessagesActionTypes.PrevPageReadRequest),
    switchMap(({ payload }) =>
      this.communicationChatService
        .getChatMessagesPagedUsingGET({
          chatId: payload.chatID,
          page: Math.floor(payload.nbMessagesLoaded / 10),
          count: 10,
        })
        .pipe(
          map(response => {
            return new PrevPageReadRequestSuccess({
              messages: response.content,
            });
          }),
          catchError(err => {
            return of(new PrevPageReadRequestFail(err));
          }),
        ),
    ),
  );

  constructor(
    private actions$: Actions,
    private communicationChatEventsService: CommunicationChatEventsService,
    private communicationChatService: CommunicationChatService,
    private store: Store<AppState>,
  ) {}
}
