import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Suggestion Event Controller
 */
var GrapevineSuggestionEventsService = /** @class */ (function (_super) {
    tslib_1.__extends(GrapevineSuggestionEventsService, _super);
    function GrapevineSuggestionEventsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Changes the category of a suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionCategoryChangeRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionCategoryChangeRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Changes the category of a suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionCategoryChangeRequestUsingPOST = function (params) {
        return this.onSuggestionCategoryChangeRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Updates an existing Suggestion. Text, images and category can be updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Only the creator can update the suggestion.
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionChangeRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionChangeRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Updates an existing Suggestion. Text, images and category can be updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Only the creator can update the suggestion.
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionChangeRequestUsingPOST = function (params) {
        return this.onSuggestionChangeRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Suggestion. Only enabled if the feature SuggestionCreationFeature is enabled for the requesting app variant and user
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|The feature is not enabled for this user|
     * |`POST_COULD_NOT_BE_CREATED`|400 BAD_REQUEST|The post could not be created because the creator has no home area set|
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionCreateRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionCreateRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Suggestion. Only enabled if the feature SuggestionCreationFeature is enabled for the requesting app variant and user
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|The feature is not enabled for this user|
     * |`POST_COULD_NOT_BE_CREATED`|400 BAD_REQUEST|The post could not be created because the creator has no home area set|
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionCreateRequestUsingPOST = function (params) {
        return this.onSuggestionCreateRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Changes the status of a suggestion. Currently these transitions are possible:
     * OPEN -> IN_PROGRESS, ON_HOLD, DONE
     * IN_PROGRESS -> ON_HOLD, DONE
     * ON_HOLD -> IN_PROGRESS, DONE
     * DONE -> IN_PROGRESS
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_STATUS_CHANGE_INVALID`|400 BAD_REQUEST|The suggestion status change is invalid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionStatusChangeRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionStatusChangeRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Changes the status of a suggestion. Currently these transitions are possible:
     * OPEN -> IN_PROGRESS, ON_HOLD, DONE
     * IN_PROGRESS -> ON_HOLD, DONE
     * ON_HOLD -> IN_PROGRESS, DONE
     * DONE -> IN_PROGRESS
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_STATUS_CHANGE_INVALID`|400 BAD_REQUEST|The suggestion status change is invalid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionStatusChangeRequestUsingPOST = function (params) {
        return this.onSuggestionStatusChangeRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Withdraws an existing Suggestion. It can still be read by SuggestionFirstResponder and SuggestionWorker afterwards.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Only the creator can withdraw the suggestion.
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWithdrawRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionWithdrawRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Withdraws an existing Suggestion. It can still be read by SuggestionFirstResponder and SuggestionWorker afterwards.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Only the creator can withdraw the suggestion.
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWithdrawRequestUsingPOST = function (params) {
        return this.onSuggestionWithdrawRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Adds a worker to a suggestion. The worker has to have the role SuggestionWorker for the tenant of the suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_WORKER_ALREADY_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is already working on the suggestion|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWorkerAddRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionWorkerAddRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Adds a worker to a suggestion. The worker has to have the role SuggestionWorker for the tenant of the suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_WORKER_ALREADY_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is already working on the suggestion|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWorkerAddRequestUsingPOST = function (params) {
        return this.onSuggestionWorkerAddRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Removes the current user as worker from a suggestion. The worker has to be an active worker of the suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_WORKER_NOT_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is not on the suggestion|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWorkerRemoveRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/event/suggestionWorkerRemoveRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Removes the current user as worker from a suggestion. The worker has to be an active worker of the suggestion.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
     * |`SUGGESTION_WORKER_NOT_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is not on the suggestion|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionEventsService.prototype.onSuggestionWorkerRemoveRequestUsingPOST = function (params) {
        return this.onSuggestionWorkerRemoveRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    GrapevineSuggestionEventsService.onSuggestionCategoryChangeRequestUsingPOSTPath = '/grapevine/event/suggestionCategoryChangeRequest';
    GrapevineSuggestionEventsService.onSuggestionChangeRequestUsingPOSTPath = '/grapevine/event/suggestionChangeRequest';
    GrapevineSuggestionEventsService.onSuggestionCreateRequestUsingPOSTPath = '/grapevine/event/suggestionCreateRequest';
    GrapevineSuggestionEventsService.onSuggestionStatusChangeRequestUsingPOSTPath = '/grapevine/event/suggestionStatusChangeRequest';
    GrapevineSuggestionEventsService.onSuggestionWithdrawRequestUsingPOSTPath = '/grapevine/event/suggestionWithdrawRequest';
    GrapevineSuggestionEventsService.onSuggestionWorkerAddRequestUsingPOSTPath = '/grapevine/event/suggestionWorkerAddRequest';
    GrapevineSuggestionEventsService.onSuggestionWorkerRemoveRequestUsingPOSTPath = '/grapevine/event/suggestionWorkerRemoveRequest';
    GrapevineSuggestionEventsService.ngInjectableDef = i0.defineInjectable({ factory: function GrapevineSuggestionEventsService_Factory() { return new GrapevineSuggestionEventsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: GrapevineSuggestionEventsService, providedIn: "root" });
    return GrapevineSuggestionEventsService;
}(__BaseService));
export { GrapevineSuggestionEventsService };
