/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PageOfClientSuggestionExtended } from '../models/page-of-client-suggestion-extended';
import { ClientSuggestionCategory } from '../models/client-suggestion-category';
import { ClientSuggestionExtended } from '../models/client-suggestion-extended';
import { ClientComment } from '../models/client-comment';
import { ClientPersonReference } from '../models/client-person-reference';

/**
 * Suggestion Controller
 */
@Injectable({
  providedIn: 'root',
})
class GrapevineSuggestionService extends __BaseService {
  static readonly getExtendedSuggestionsUsingGETPath = '/grapevine/suggestion/';
  static readonly getSuggestionCategoriesUsingGETPath = '/grapevine/suggestion/suggestionCategory';
  static readonly getSuggestionCategoryByIdUsingGETPath = '/grapevine/suggestion/suggestionCategory/{suggestionCategoryId}';
  static readonly getExtendedSuggestionUsingGETPath = '/grapevine/suggestion/{suggestionId}';
  static readonly getSuggestionCommentsUsingGETPath = '/grapevine/suggestion/{suggestionId}/comments';
  static readonly getPotentialSuggestionWorkersUsingGETPath = '/grapevine/suggestion/{suggestionId}/potentialWorker';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns all extended suggestions, containing more details than the standard posts. The response is paged.
   *
   * | sorting criteria | attribute used for filtering and sorting | returned posts are subtype of | direction | default start if null | default end if null |
   * | ---- | ---- | ---- | ---- | ---- | ---- |
   * | CREATED | created | Post | DESC | MIN | MAX |
   * | LAST_SUGGESTION_ACTIVITY | lastActivity | Suggestion | DESC | MIN | MAX |
   * | LAST_ACTIVITY | lastComment | Post | DESC | MIN | MAX |
   *
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`INVALID_FILTER_CRITERIA`|400 BAD_REQUEST|The provided filter criteria are not valid|
   * |`INVALID_SORTING_CRITERION`|400 BAD_REQUEST|The provided sorting criterion is not valid|
   * |`PAGE_PARAMETER_INVALID`|400 BAD_REQUEST|The page parameters are invalid|
   * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams` containing the following parameters:
   *
   * - `unassigned`: Filter criterion. When supplied, only suggestions without assigned suggestion workers will be listed.
   *   Can not be applied with containsWorker! This will throw an InvalidFilterCriteriaException.
   *
   * - `startTime`: Start time from which the suggestions should be returned, if it is 0 then all since 01.01.1970 will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
   *
   * - `sortBy`: Sorting criterion. When CREATED, newest suggestions are listed first. When LAST_SUGGESTION_ACTIVITY, suggestions with the most recent suggestion activity are listed first. Considered as an suggestion activity is the creation, update or deletion of a comment or a new chat message. When LAST_ACTIVITY, suggestions with the most recent change in terms of comments are listed first. Difference between this two options is the consideration of new chat messages.
   *
   * - `page`: page number of the paged list of suggestions
   *
   * - `includedStatus`: Only suggestions with `suggestionStatus` *in* `includedStatus` are returned. If not set, this filter is not applied.
   *
   * - `excludedStatus`: Only suggestions with `suggestionStatus` *not in* `excludedStatus` are returned. If not set, this filter is not applied.
   *
   * - `endTime`: End time from which the suggestions should be returned, if 0 all suggestions between startTime and now will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
   *
   * - `count`: maximum number of suggestions returned in a page, if it is 0 no pagination is applied
   *
   * - `containedWorkerId`: Filter criterion. When a personId is supplied, only suggestions with the given person as an assigned suggestion worker will be listed.
   *   Can not be applied with 'unassigned' = true! This will throw an InvalidFilterCriteriaException.
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getExtendedSuggestionsUsingGETResponse(params: GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams): __Observable<__StrictHttpResponse<PageOfClientSuggestionExtended>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.unassigned != null) __params = __params.set('unassigned', params.unassigned.toString());
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    if (params.sortBy != null) __params = __params.set('sortBy', params.sortBy.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.includedStatus || []).forEach(val => {if (val != null) __params = __params.append('includedStatus', val.toString())});
    (params.excludedStatus || []).forEach(val => {if (val != null) __params = __params.append('excludedStatus', val.toString())});
    if (params.endTime != null) __params = __params.set('endTime', params.endTime.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.containedWorkerId != null) __params = __params.set('containedWorkerId', params.containedWorkerId.toString());
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageOfClientSuggestionExtended>;
      })
    );
  }
  /**
   * Returns all extended suggestions, containing more details than the standard posts. The response is paged.
   *
   * | sorting criteria | attribute used for filtering and sorting | returned posts are subtype of | direction | default start if null | default end if null |
   * | ---- | ---- | ---- | ---- | ---- | ---- |
   * | CREATED | created | Post | DESC | MIN | MAX |
   * | LAST_SUGGESTION_ACTIVITY | lastActivity | Suggestion | DESC | MIN | MAX |
   * | LAST_ACTIVITY | lastComment | Post | DESC | MIN | MAX |
   *
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`INVALID_FILTER_CRITERIA`|400 BAD_REQUEST|The provided filter criteria are not valid|
   * |`INVALID_SORTING_CRITERION`|400 BAD_REQUEST|The provided sorting criterion is not valid|
   * |`PAGE_PARAMETER_INVALID`|400 BAD_REQUEST|The page parameters are invalid|
   * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams` containing the following parameters:
   *
   * - `unassigned`: Filter criterion. When supplied, only suggestions without assigned suggestion workers will be listed.
   *   Can not be applied with containsWorker! This will throw an InvalidFilterCriteriaException.
   *
   * - `startTime`: Start time from which the suggestions should be returned, if it is 0 then all since 01.01.1970 will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
   *
   * - `sortBy`: Sorting criterion. When CREATED, newest suggestions are listed first. When LAST_SUGGESTION_ACTIVITY, suggestions with the most recent suggestion activity are listed first. Considered as an suggestion activity is the creation, update or deletion of a comment or a new chat message. When LAST_ACTIVITY, suggestions with the most recent change in terms of comments are listed first. Difference between this two options is the consideration of new chat messages.
   *
   * - `page`: page number of the paged list of suggestions
   *
   * - `includedStatus`: Only suggestions with `suggestionStatus` *in* `includedStatus` are returned. If not set, this filter is not applied.
   *
   * - `excludedStatus`: Only suggestions with `suggestionStatus` *not in* `excludedStatus` are returned. If not set, this filter is not applied.
   *
   * - `endTime`: End time from which the suggestions should be returned, if 0 all suggestions between startTime and now will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
   *
   * - `count`: maximum number of suggestions returned in a page, if it is 0 no pagination is applied
   *
   * - `containedWorkerId`: Filter criterion. When a personId is supplied, only suggestions with the given person as an assigned suggestion worker will be listed.
   *   Can not be applied with 'unassigned' = true! This will throw an InvalidFilterCriteriaException.
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getExtendedSuggestionsUsingGET(params: GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams): __Observable<PageOfClientSuggestionExtended> {
    return this.getExtendedSuggestionsUsingGETResponse(params).pipe(
      __map(_r => _r.body as PageOfClientSuggestionExtended)
    );
  }

  /**
   * ---
   *
   * ## No authentication required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getSuggestionCategoriesUsingGETResponse(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<Array<ClientSuggestionCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/suggestionCategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientSuggestionCategory>>;
      })
    );
  }
  /**
   * ---
   *
   * ## No authentication required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getSuggestionCategoriesUsingGET(appVariantIdentifier?: string): __Observable<Array<ClientSuggestionCategory>> {
    return this.getSuggestionCategoriesUsingGETResponse(appVariantIdentifier).pipe(
      __map(_r => _r.body as Array<ClientSuggestionCategory>)
    );
  }

  /**
   * ---
   *
   * ## No authentication required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * | ― | ― | ― |
   * @param params The `GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams` containing the following parameters:
   *
   * - `suggestionCategoryId`: suggestionCategoryId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getSuggestionCategoryByIdUsingGETResponse(params: GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams): __Observable<__StrictHttpResponse<ClientSuggestionCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/suggestionCategory/${params.suggestionCategoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionCategory>;
      })
    );
  }
  /**
   * ---
   *
   * ## No authentication required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * | ― | ― | ― |
   * @param params The `GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams` containing the following parameters:
   *
   * - `suggestionCategoryId`: suggestionCategoryId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getSuggestionCategoryByIdUsingGET(params: GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams): __Observable<ClientSuggestionCategory> {
    return this.getSuggestionCategoryByIdUsingGETResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionCategory)
    );
  }

  /**
   * Returns one extended suggestion, containing more details than the standard posts
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getExtendedSuggestionUsingGETResponse(params: GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams): __Observable<__StrictHttpResponse<ClientSuggestionExtended>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/${params.suggestionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionExtended>;
      })
    );
  }
  /**
   * Returns one extended suggestion, containing more details than the standard posts
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getExtendedSuggestionUsingGET(params: GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams): __Observable<ClientSuggestionExtended> {
    return this.getExtendedSuggestionUsingGETResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionExtended)
    );
  }

  /**
   * in contrast to /grapevine/comment this method only works for comments, but also returns comments of suggestions that are withdrawn (and, thus as being deleted, not returned by the other endpoint)
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getSuggestionCommentsUsingGETResponse(params: GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams): __Observable<__StrictHttpResponse<Array<ClientComment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/${params.suggestionId}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientComment>>;
      })
    );
  }
  /**
   * in contrast to /grapevine/comment this method only works for comments, but also returns comments of suggestions that are withdrawn (and, thus as being deleted, not returned by the other endpoint)
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getSuggestionCommentsUsingGET(params: GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams): __Observable<Array<ClientComment>> {
    return this.getSuggestionCommentsUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ClientComment>)
    );
  }

  /**
   * Returns all potential suggestion workers for the given suggestion. These are all persons with roles SuggestionFirstResponder or SuggestionWorker for the tenant of the suggestion
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getPotentialSuggestionWorkersUsingGETResponse(params: GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams): __Observable<__StrictHttpResponse<Array<ClientPersonReference>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/${params.suggestionId}/potentialWorker`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientPersonReference>>;
      })
    );
  }
  /**
   * Returns all potential suggestion workers for the given suggestion. These are all persons with roles SuggestionFirstResponder or SuggestionWorker for the tenant of the suggestion
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams` containing the following parameters:
   *
   * - `suggestionId`: suggestionId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getPotentialSuggestionWorkersUsingGET(params: GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams): __Observable<Array<ClientPersonReference>> {
    return this.getPotentialSuggestionWorkersUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ClientPersonReference>)
    );
  }
}

module GrapevineSuggestionService {

  /**
   * Parameters for getExtendedSuggestionsUsingGET
   */
  export interface GetExtendedSuggestionsUsingGETParams {

    /**
     * Filter criterion. When supplied, only suggestions without assigned suggestion workers will be listed.
     * Can not be applied with containsWorker! This will throw an InvalidFilterCriteriaException.
     */
    unassigned?: boolean;

    /**
     * Start time from which the suggestions should be returned, if it is 0 then all since 01.01.1970 will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     */
    startTime?: number;

    /**
     * Sorting criterion. When CREATED, newest suggestions are listed first. When LAST_SUGGESTION_ACTIVITY, suggestions with the most recent suggestion activity are listed first. Considered as an suggestion activity is the creation, update or deletion of a comment or a new chat message. When LAST_ACTIVITY, suggestions with the most recent change in terms of comments are listed first. Difference between this two options is the consideration of new chat messages.
     */
    sortBy?: 'CREATED' | 'LAST_SUGGESTION_ACTIVITY' | 'LAST_ACTIVITY';

    /**
     * page number of the paged list of suggestions
     */
    page?: number;

    /**
     * Only suggestions with `suggestionStatus` *in* `includedStatus` are returned. If not set, this filter is not applied.
     */
    includedStatus?: Array<'OPEN' | 'IN_PROGRESS' | 'ON_HOLD' | 'DONE'>;

    /**
     * Only suggestions with `suggestionStatus` *not in* `excludedStatus` are returned. If not set, this filter is not applied.
     */
    excludedStatus?: Array<'OPEN' | 'IN_PROGRESS' | 'ON_HOLD' | 'DONE'>;

    /**
     * End time from which the suggestions should be returned, if 0 all suggestions between startTime and now will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     */
    endTime?: number;

    /**
     * maximum number of suggestions returned in a page, if it is 0 no pagination is applied
     */
    count?: number;

    /**
     * Filter criterion. When a personId is supplied, only suggestions with the given person as an assigned suggestion worker will be listed.
     * Can not be applied with 'unassigned' = true! This will throw an InvalidFilterCriteriaException.
     */
    containedWorkerId?: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getSuggestionCategoryByIdUsingGET
   */
  export interface GetSuggestionCategoryByIdUsingGETParams {

    /**
     * suggestionCategoryId
     */
    suggestionCategoryId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getExtendedSuggestionUsingGET
   */
  export interface GetExtendedSuggestionUsingGETParams {

    /**
     * suggestionId
     */
    suggestionId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getSuggestionCommentsUsingGET
   */
  export interface GetSuggestionCommentsUsingGETParams {

    /**
     * suggestionId
     */
    suggestionId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getPotentialSuggestionWorkersUsingGET
   */
  export interface GetPotentialSuggestionWorkersUsingGETParams {

    /**
     * suggestionId
     */
    suggestionId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { GrapevineSuggestionService }
