import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import {
  SharedDataprivacyService, ParticipantsPersonService, ParticipantsPersonEventsService,
} from '@app/api/services';

import{GetSavedUserDataRequest, GetSavedUserDataRequestSuccess, GetSavedUserDataRequestFail, BurgerMenuActionTypes, UploadProfilePictureRequest, UploadProfilePictureRequestSuccess, UploadProfilePictureRequestFail, UpdateProfileNameRequestFail, UpdateProfileNameRequestSuccess, UpdateProfileNameRequest, DeleteUserRequest, DeleteUserRequestSuccess, DeleteUserRequestFail} from './userdata.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers';

@Injectable()
export class BurgerMenuEffects {
  @Effect()
  getSavedUserData$ = this.actions$.pipe(
    ofType<GetSavedUserDataRequest>(BurgerMenuActionTypes.GetSavedUserDataRequest),
    switchMap(({ payload }) =>
      this.dataPrivacyService
        .collectUserDataAndSendMailUsingPOST()
        .pipe(
          map(response => {
            return new GetSavedUserDataRequestSuccess(response);
          }),
          catchError(err => {
            this.store.dispatch(new GetSavedUserDataRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  uploadProfilePicture$ = this.actions$.pipe(
    ofType<UploadProfilePictureRequest>(BurgerMenuActionTypes.UploadProfilePictureRequest),
    switchMap(({ payload }) =>
      this.personService
        .uploadProfilePictureUsingPOST(payload)
        .pipe(
          map(response => {
            return new UploadProfilePictureRequestSuccess(response);
          }),
          catchError(err => {
            this.store.dispatch(new UploadProfilePictureRequestFail(err));
            return throwError(err);
          }),
        )
    ),
  );

  @Effect()
  uploadProfileName$ = this.actions$.pipe(
    ofType<UpdateProfileNameRequest>(BurgerMenuActionTypes.UpdateProfileNameRequest),
    switchMap(({ payload }) =>
      this.personEventService
        .onPersonUpdateRequestUsingPOST(payload)
        .pipe(
          map(response => {
            return new UpdateProfileNameRequestSuccess(response);
          }),
          catchError(err => {
            this.store.dispatch(new UpdateProfileNameRequestFail(err));
            return throwError(err);
          }),
        )
    ),
  );

  @Effect()
  deleteUser$ = this.actions$.pipe(
    ofType<DeleteUserRequest>(BurgerMenuActionTypes.DeleteUserRequest),
    switchMap(() =>
      this.dataPrivacyService
        .deletePersonUsingDELETE1()
        .pipe(
          map(response => {
            return new DeleteUserRequestSuccess(response);
          }),
          catchError(err => {
            this.store.dispatch(new DeleteUserRequestFail(err));
            return throwError(err);
          }),
        )
    ),
  );

  constructor(
    private actions$: Actions,
    private dataPrivacyService: SharedDataprivacyService,
    private personService: ParticipantsPersonService,
    private personEventService: ParticipantsPersonEventsService,
    private store: Store<AppState>,
  ) {}
}
