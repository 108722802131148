import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {
  ClientSuggestionExtended,
  ClientSuggestionCategoryChangeRequest,
  ClientSuggestionCategory,
  ClientSuggestionCreateRequest,
  ClientPostCreateConfirmation,
  ClientExceptionEntity,
} from '@app/api/models';
import { HttpErrorResponse } from '@angular/common/http';
import { SuggestionStatus } from '@app/loesbar/businesslogic/models/suggestion-status';
import { AssignmentFilter } from '@app/loesbar/businesslogic/models/assignment-filter';

export enum SuggestionActionTypes {
  AllCurrentSuggestionRequest = '[Loesbar Home Page] Suggestions Requested',
  AllCurrentSuggestionsLoadedSuccess = '[DVP API]  Load Suggestions Success',
  AllCurrentSuggestionsLoadedFail = '[DVP API] Load Suggestions Fail',
  AllArchiveSuggestionsRequested = '[Loesbar Home Page] Archive Suggestions Requested',
  AllArchiveSuggestionsLoadedSuccess = '[DVP API] Archive Load Suggestions Success',
  AllArchiveSuggestionsLoadedFail = '[DVP API] Archive Load Suggestions Fail',
  LatestSuggestionsRequest = '[Suggestion Polling Service] Lastest Suggestions Request',
  LatestSuggestionsRequestSuccess = '[DVP API] Lastest Suggestions Request Success',
  LatestSuggestionsRequestFail = '[DVP API] Lastest Suggestions Request Fail',
  SuggestionStatusChangeRequest = '[Dropdown Home Page] Update Suggestion Status',
  SuggestionStatusChangeRequestSuccess = '[DVP API] Update Suggestion Status Success',
  SuggestionStatusChangeRequestFail = '[DVP API]  Update Suggestion Status Fail',
  SuggestionWorkerAddRequest = '[Loesbar Home Page] Add worker to suggestion',
  SuggestionWorkerAddRequestSuccess = '[DVP API] Update Suggestion Status Success',
  SuggestionWorkerAddRequestFail = '[DVP API]  Update Suggestion Status Fail',
  SuggestionWorkerRemoveRequest = '[Loesbar Home Page] Remove worker from suggestion',
  SuggestionWorkerRemoveRequestSuccess = '[DVP API] Remove worker from suggestion Success',
  SuggestionWorkerRemoveRequestFail = '[DVP API]  Remove worker from suggestion Fail',
  GetExtendedSuggestionRequestSilent = '[Suggestion List] Get Extended Suggestion Request, no error on fail thrown',
  GetExtendedSuggestionSilentSuccess = '[DVP API] Get Extended Suggestion Success',
  GetExtendedSuggestioSilentFail = '[DVP API] Get Extended Suggestion Fail',
  GetExtendedSuggestioRequest = '[Suggestion List] Get Extended Suggestion Request',
  GetExtendedSuggestioRequestSuccess = '[DVP API]  Get Extended Suggestion Request Success',
  GetExtendedSuggestioRequestFail = '[DVP API] Get Extended Suggestion Request Fail',
  UpdateSuggestionRequest = '[Suggestion Detail] Update Suggestion Request',
  UpdateSuggestionRequestSuccess = '[DVP API]  Update Suggestion Request Success',
  UpdateSuggestionRequestFail = '[DVP API] Update Suggestion Request Fail',
  SuggestionCategoryChangeRequest = '[Dropdown Home Page] Update Suggestion Category',
  SuggestionCategoryChangeRequestSuccess = '[DVP API] Update Suggestion Category Success',
  SuggestionCategoryChangeRequestFail = '[DVP API]  Update Suggestion Category Fail',
  GetSuggestionCategoriesRequest = '[Loesbar Home Page] Get Suggestion Categories Request',
  GetSuggestionCategoriesRequestSuccess = '[DVP API] Get Suggestion Categories Request Success',
  GetSuggestionCategoriesRequestFail = '[DVP API] Get Suggestion Categories Request Fail',
  UpdateLastCurrentSelectedSuggestionRequest = '[Suggestion List] Update Last Current Selected Suggestion Request',
  UpdateLastArchivedSelectedSuggestionRequest = '[Suggestion List] Update Last Archived Selected Suggestion Request',
  ShowSuggestionInlineError = '[DVP API] Show Suggestion Inline Error',
  SuggestionUpdateCounters = '[DVP API] update counters of current/archive',
  SetActiveSuggestionRequest = '[Suggestion Main] Set Active Suggestion Request',
  SetActiveSuggestion = '[Suggestion Main] Set Active Suggestion',
  SuggestionSetStatusFilter = '[DVP API] set status filter for current suggestions',
  SuggestionSetAssignmentFilter = '[DVP API] set assignment filter for suggestions',
  SuggestionSetFirstCurrentActive = '[DVP API] set the first current suggestion active',
  SuggestionSetFirstArchiveActive = '[DVP API] set the first archive suggestion active',
  SuggestionCreateRequest = '[DVP API] create a new suggestion',
  SuggestionCreateRequestSuccess = '[DVP API] create a new suggestion success',
  SuggestionCreateRequestFail = '[DVP API] create a new suggestion fail',
}

export class AllCurrentSuggestionRequest implements Action {
  readonly type = SuggestionActionTypes.AllCurrentSuggestionRequest;
}

export class AllCurrentSuggestionsLoadedSuccess implements Action {
  readonly type = SuggestionActionTypes.AllCurrentSuggestionsLoadedSuccess;
  constructor(
    public payload: { suggestions: ClientSuggestionExtended[]; total: number },
  ) {}
}

export class AllCurrentSuggestionsLoadedFail implements Action {
  readonly type = SuggestionActionTypes.AllCurrentSuggestionsLoadedFail;
  constructor(public payload: any) {}
}

export class AllArchiveSuggestionsRequested implements Action {
  readonly type = SuggestionActionTypes.AllArchiveSuggestionsRequested;
}

export class AllArchiveSuggestionsLoadedSuccess implements Action {
  readonly type = SuggestionActionTypes.AllArchiveSuggestionsLoadedSuccess;
  constructor(
    public payload: { suggestions: ClientSuggestionExtended[]; total: number },
  ) {}
}

export class AllArchiveSuggestionsLoadedFail implements Action {
  readonly type = SuggestionActionTypes.AllArchiveSuggestionsLoadedFail;
  constructor(public payload: any) {}
}

export class LatestSuggestionsRequest implements Action {
  readonly type = SuggestionActionTypes.LatestSuggestionsRequest;
}

export class LatestSuggestionsRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.LatestSuggestionsRequestSuccess;
  constructor(public payload: { suggestions: ClientSuggestionExtended[] }) {}
}

export class LatestSuggestionsRequestFail implements Action {
  readonly type = SuggestionActionTypes.LatestSuggestionsRequestFail;
  constructor(public payload: any) {}
}

export class SuggestionStatusChangeRequest implements Action {
  readonly type = SuggestionActionTypes.SuggestionStatusChangeRequest;
  constructor(
    public payload: {
      suggestion: ClientSuggestionExtended;
      lastSuggestionStatus: string;
    },
  ) {}
}

export class SuggestionStatusChangeRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.SuggestionStatusChangeRequestSuccess;
  constructor(
    public payload: { suggestion: Update<ClientSuggestionExtended> },
  ) {}
}

export class SuggestionStatusChangeRequestFail implements Action {
  readonly type = SuggestionActionTypes.SuggestionStatusChangeRequestFail;
  constructor(public payload: any) {}
}

export class SuggestionWorkerAddRequest implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerAddRequest;
  constructor(
    public payload: { suggestionId: string; workerPersonId: string },
  ) {}
}

export class SuggestionWorkerAddRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerAddRequestSuccess;
  constructor(
    public payload: { suggestion: Update<ClientSuggestionExtended> },
  ) {}
}

export class SuggestionWorkerAddRequestFail implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerAddRequestFail;
  constructor(public payload: any) {}
}

export class SuggestionWorkerRemoveRequest implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerRemoveRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class SuggestionWorkerRemoveRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerRemoveRequestSuccess;
  constructor(
    public payload: { suggestion: Update<ClientSuggestionExtended> },
  ) {}
}

export class SuggestionWorkerRemoveRequestFail implements Action {
  readonly type = SuggestionActionTypes.SuggestionWorkerRemoveRequestFail;
  constructor(public payload: any) {}
}

export class GetExtendedSuggestionRequestSilent implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestionRequestSilent;
  constructor(public payload: { suggestionId: string }) {}
}

export class GetExtendedSuggestionSilentSuccess implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestionSilentSuccess;
  constructor(public payload: { suggestion: ClientSuggestionExtended }) {}
}

export class GetExtendedSuggestioSilentFail implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestioSilentFail;
  constructor(public payload: any) {}
}
export class GetExtendedSuggestioRequest implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestioRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class GetExtendedSuggestioRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestioRequestSuccess;
  constructor(public payload: { suggestion: ClientSuggestionExtended }) {}
}

export class GetExtendedSuggestioRequestFail implements Action {
  readonly type = SuggestionActionTypes.GetExtendedSuggestioRequestFail;
  constructor(public payload: any) {}
}

export class UpdateSuggestionRequest implements Action {
  readonly type = SuggestionActionTypes.UpdateSuggestionRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class UpdateSuggestionRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.UpdateSuggestionRequestSuccess;
  constructor(
    public payload: { suggestion: Update<ClientSuggestionExtended> },
  ) {}
}

export class UpdateSuggestionRequestFail implements Action {
  readonly type = SuggestionActionTypes.UpdateSuggestionRequestFail;
  constructor(public payload: any) {}
}

export class SuggestionCategoryChangeRequest implements Action {
  readonly type = SuggestionActionTypes.SuggestionCategoryChangeRequest;
  constructor(
    public payload: { suggestion: ClientSuggestionCategoryChangeRequest },
  ) {}
}

export class SuggestionCategoryChangeRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.SuggestionCategoryChangeRequestSuccess;
  constructor(
    public payload: { suggestion: Update<ClientSuggestionExtended> },
  ) {}
}

export class SuggestionCategoryChangeRequestFail implements Action {
  readonly type = SuggestionActionTypes.SuggestionCategoryChangeRequestFail;
  constructor(public payload: any) {}
}

export class GetSuggestionCategoriesRequest implements Action {
  readonly type = SuggestionActionTypes.GetSuggestionCategoriesRequest;
}

export class GetSuggestionCategoriesRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.GetSuggestionCategoriesRequestSuccess;
  constructor(public payload: { categories: ClientSuggestionCategory[] }) {}
}

export class GetSuggestionCategoriesRequestFail implements Action {
  readonly type = SuggestionActionTypes.GetSuggestionCategoriesRequestFail;
  constructor(public payload: any) {}
}

export class UpdateLastCurrentSelectedSuggestionRequest implements Action {
  readonly type =
    SuggestionActionTypes.UpdateLastCurrentSelectedSuggestionRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class UpdateLastArchivedSelectedSuggestionRequest implements Action {
  readonly type =
    SuggestionActionTypes.UpdateLastArchivedSelectedSuggestionRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class ShowSuggestionInlineError implements Action {
  readonly type = SuggestionActionTypes.ShowSuggestionInlineError;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SuggestionUpdateCounters implements Action {
  readonly type = SuggestionActionTypes.SuggestionUpdateCounters;
  constructor(public payload: { deltaCurrent: number; deltaArchive: number }) {}
}
export class SetActiveSuggestionRequest implements Action {
  readonly type = SuggestionActionTypes.SetActiveSuggestionRequest;
  constructor(public payload: { suggestionId: string }) {}
}

export class SetActiveSuggestion implements Action {
  readonly type = SuggestionActionTypes.SetActiveSuggestion;
  constructor(public payload: { suggestionId: string }) {}
}

export class SuggestionSetStatusFilter implements Action {
  readonly type = SuggestionActionTypes.SuggestionSetStatusFilter;
  constructor(public payload: { suggestionStatus: SuggestionStatus }) {}
}

export class SuggestionSetAssignmentFilter implements Action {
  readonly type = SuggestionActionTypes.SuggestionSetAssignmentFilter;
  constructor(public payload: { assignmentFilter: AssignmentFilter }) {}
}

export class SuggestionSetFirstCurrentActive implements Action {
  readonly type = SuggestionActionTypes.SuggestionSetFirstCurrentActive;
}

export class SuggestionSetFirstArchiveActive implements Action {
  readonly type = SuggestionActionTypes.SuggestionSetFirstArchiveActive;
}

export class SuggestionCreateRequest implements Action {
  readonly type = SuggestionActionTypes.SuggestionCreateRequest;
  constructor(
    public payload: {
      clientSuggestionCreateRequest: ClientSuggestionCreateRequest;
    },
  ) {}
}

export class SuggestionCreateRequestSuccess implements Action {
  readonly type = SuggestionActionTypes.SuggestionCreateRequestSuccess;
  constructor(
    public payload: {
      clientPostCreateConfirmation: ClientPostCreateConfirmation;
    },
  ) {}
}

export class SuggestionCreateRequestFail implements Action {
  readonly type = SuggestionActionTypes.SuggestionCreateRequestFail;
  constructor(public payload: any) {}
}

export type SuggestionActions =
  | AllCurrentSuggestionRequest
  | AllCurrentSuggestionsLoadedSuccess
  | AllCurrentSuggestionsLoadedFail
  | AllArchiveSuggestionsRequested
  | AllArchiveSuggestionsLoadedSuccess
  | AllArchiveSuggestionsLoadedFail
  | LatestSuggestionsRequest
  | LatestSuggestionsRequestSuccess
  | LatestSuggestionsRequestFail
  | SuggestionStatusChangeRequest
  | SuggestionStatusChangeRequestSuccess
  | SuggestionStatusChangeRequestFail
  | SuggestionWorkerAddRequest
  | SuggestionWorkerAddRequestSuccess
  | SuggestionWorkerAddRequestFail
  | SuggestionWorkerRemoveRequest
  | SuggestionWorkerRemoveRequestSuccess
  | SuggestionWorkerRemoveRequestFail
  | GetExtendedSuggestioRequest
  | GetExtendedSuggestionRequestSilent
  | GetExtendedSuggestionSilentSuccess
  | GetExtendedSuggestioSilentFail
  | GetExtendedSuggestioRequestSuccess
  | GetExtendedSuggestioRequestFail
  | UpdateSuggestionRequest
  | UpdateSuggestionRequestSuccess
  | UpdateSuggestionRequestFail
  | SuggestionCategoryChangeRequest
  | SuggestionCategoryChangeRequestSuccess
  | SuggestionCategoryChangeRequestFail
  | GetSuggestionCategoriesRequest
  | GetSuggestionCategoriesRequestSuccess
  | GetSuggestionCategoriesRequestFail
  | UpdateLastCurrentSelectedSuggestionRequest
  | UpdateLastArchivedSelectedSuggestionRequest
  | ShowSuggestionInlineError
  | SuggestionUpdateCounters
  | SetActiveSuggestionRequest
  | SetActiveSuggestion
  | SuggestionSetStatusFilter
  | SuggestionSetAssignmentFilter
  | SuggestionSetFirstCurrentActive
  | SuggestionSetFirstArchiveActive
  | SuggestionCreateRequest
  | SuggestionCreateRequestSuccess
  | SuggestionCreateRequestFail;
