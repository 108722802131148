import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Chat Controller
 */
var CommunicationChatService = /** @class */ (function (_super) {
    tslib_1.__extends(CommunicationChatService, _super);
    function CommunicationChatService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Checked if the current user is a participant
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessageUsingGETParams` containing the following parameters:
     *
     * - `chatMessageId`: chatMessageId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessageUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/communication/chat/message/" + params.chatMessageId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Checked if the current user is a participant
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessageUsingGETParams` containing the following parameters:
     *
     * - `chatMessageId`: chatMessageId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessageUsingGET = function (params) {
        return this.getChatMessageUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Checked if the current user is a participant
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatUsingGETParams` containing the following parameters:
     *
     * - `chatId`: chatId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/communication/chat/" + params.chatId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Checked if the current user is a participant
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatUsingGETParams` containing the following parameters:
     *
     * - `chatId`: chatId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatUsingGET = function (params) {
        return this.getChatUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * The received timestamp for the requesting participant is updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessagesUsingGETParams` containing the following parameters:
     *
     * - `from`: from
     *
     * - `chatId`: chatId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessagesUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.from != null)
            __params = __params.set('from', params.from.toString());
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/communication/chat/" + params.chatId + "/message"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * The received timestamp for the requesting participant is updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessagesUsingGETParams` containing the following parameters:
     *
     * - `from`: from
     *
     * - `chatId`: chatId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessagesUsingGET = function (params) {
        return this.getChatMessagesUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * The received timestamp for the requesting participant is updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessagesPagedUsingGETParams` containing the following parameters:
     *
     * - `chatId`: chatId
     *
     * - `page`: page number of the paged list of chat messages
     *
     * - `from`: time from which on the chat messages should be returned
     *
     * - `count`: maximum number of chat messages returned in a page, if 0 no pagination is applied
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessagesPagedUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.page != null)
            __params = __params.set('page', params.page.toString());
        if (params.from != null)
            __params = __params.set('from', params.from.toString());
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/communication/chat/" + params.chatId + "/message/paged"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * The received timestamp for the requesting participant is updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * User must be participant of chat
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
     * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `CommunicationChatService.GetChatMessagesPagedUsingGETParams` containing the following parameters:
     *
     * - `chatId`: chatId
     *
     * - `page`: page number of the paged list of chat messages
     *
     * - `from`: time from which on the chat messages should be returned
     *
     * - `count`: maximum number of chat messages returned in a page, if 0 no pagination is applied
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    CommunicationChatService.prototype.getChatMessagesPagedUsingGET = function (params) {
        return this.getChatMessagesPagedUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    CommunicationChatService.getChatMessageUsingGETPath = '/communication/chat/message/{chatMessageId}';
    CommunicationChatService.getChatUsingGETPath = '/communication/chat/{chatId}';
    CommunicationChatService.getChatMessagesUsingGETPath = '/communication/chat/{chatId}/message';
    CommunicationChatService.getChatMessagesPagedUsingGETPath = '/communication/chat/{chatId}/message/paged';
    CommunicationChatService.ngInjectableDef = i0.defineInjectable({ factory: function CommunicationChatService_Factory() { return new CommunicationChatService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CommunicationChatService, providedIn: "root" });
    return CommunicationChatService;
}(__BaseService));
export { CommunicationChatService };
