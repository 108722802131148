import { Router } from '@angular/router';
import { NotificationService } from './notificationService';
import { Store } from '@ngrx/store';
import { NetworkStatusAction } from '@app/core/store/core-store';
import { NetworkStatus } from '@app/loesbar/businesslogic/models/network-status';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./notificationService";
import * as i3 from "@ngrx/store";
var ErrorService = /** @class */ (function () {
    function ErrorService(router, notificationService, store) {
        this.router = router;
        this.notificationService = notificationService;
        this.store = store;
    }
    ErrorService.prototype.manageActionServerError = function (error) {
        // CASE: 403. Should redirect in any 403 cases
        if (error.status === 403) {
            this.router.navigate(['/error', error.status], {
                replaceUrl: true,
            });
        }
        // CASE: 0. Unknown Error and not internet connection
        if (error.status === 0) {
            if (!navigator.onLine) {
                this.store.dispatch(new NetworkStatusAction({
                    networkStatus: NetworkStatus.OFFLINE_MODE,
                }));
            }
            else {
                this.store.dispatch(new NetworkStatusAction({
                    networkStatus: NetworkStatus.NO_CONNECTION,
                }));
            }
        }
        // server error
        if (error.status > 499) {
            this.store.dispatch(new NetworkStatusAction({ networkStatus: NetworkStatus.SERVER_ERROR }));
        }
    };
    ErrorService.prototype.getClientMessage = function (error) {
        if (!navigator.onLine) {
            return 'Keine Internetverbindung';
        }
        return error.message ? error.message : error.toString();
    };
    ErrorService.prototype.getClientStack = function (error) {
        return error.stack;
    };
    ErrorService.prototype.getServerMessage = function (error) {
        return error.error.message;
    };
    ErrorService.prototype.getServerStack = function (error) {
        // handle stack trace
        return error.error.type;
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.inject(i1.Router), i0.inject(i2.NotificationService), i0.inject(i3.Store)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
