import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AllCommentsRequest,
  AllCommentsRequestSuccess,
  AllCommentsRequestFail,
  CommentsActionTypes,
  CommentChangeRequest,
  CommentChangeRequestSuccess,
  CommentChangeRequestFail,
  CommentCreateRequest,
  CommentCreateRequestSuccess,
  CommentCreateRequestFail,
  CommentDeleteRequest,
  CommentDeleteRequestSuccess,
  CommentDeleteRequestFail,
} from './comments.action';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from '../../../store/reducers';
import { Store } from '@ngrx/store';
import {
  GrapevineCommentEventsService,
  GrapevineSuggestionService,
} from '@app/api/services';
import { of, throwError } from 'rxjs';
import { Update } from '@ngrx/entity';
import { ClientComment } from '@app/api/models';
import { UpdateSuggestionRequest } from '../suggestions/suggestions.action';

@Injectable()
export class CommentEffects {
  @Effect()
  loadComments$ = this.actions$.pipe(
    ofType<AllCommentsRequest>(CommentsActionTypes.AllCommentsRequest),
    mergeMap(({ payload }) =>
      this.suggestionService
        .getSuggestionCommentsUsingGET({ suggestionId: payload.postId })
        .pipe(
          catchError(err => {
            console.log('error loading a comments', err);
            this.store.dispatch(new AllCommentsRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
    map(comments => new AllCommentsRequestSuccess({ comments })),
  );

  @Effect()
  changeComment$ = this.actions$.pipe(
    ofType<CommentChangeRequest>(CommentsActionTypes.CommentChangeRequest),
    switchMap(({ payload }) =>
      this.commentEventService
        .onCommentChangeRequestUsingPOST({
          request: payload.comment,
        })
        .pipe(
          map(response => {
            const updatedClientComment: ClientComment = response.comment;
            const changes = updatedClientComment;
            const updateComment: Update<ClientComment> = {
              id: updatedClientComment.id,
              changes,
            };
            return new CommentChangeRequestSuccess({ comment: updateComment });
          }),
          catchError(err => {
            this.store.dispatch(new CommentChangeRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  createComment$ = this.actions$.pipe(
    ofType<CommentCreateRequest>(CommentsActionTypes.CommentCreateRequest),
    switchMap(({ payload }) =>
      this.commentEventService
        .onCommentCreateRequestBySuggestionWorkerUsingPOST({
          clientRequest: payload.comment,
        })
        .pipe(
          map(response => {
            return new CommentCreateRequestSuccess({
              comment: response.comment,
            });
          }),
          catchError(err => {
            this.store.dispatch(new CommentCreateRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  withdrawnComment$ = this.actions$.pipe(
    ofType<CommentDeleteRequest>(CommentsActionTypes.CommentDeleteRequest),
    switchMap(({ payload }) =>
      this.commentEventService
        .onCommentDeleteRequestUsingPOST({
          request: payload,
        })
        .pipe(
          map(response => {
            const updatedClientComment: ClientComment = { id: response.commentId, text: null };
            const changes = updatedClientComment;
            const updateComment: Update<ClientComment> = {
              id: response.commentId,
              changes,
            };
            return new CommentDeleteRequestSuccess({ comment: updateComment });
          }),
          catchError(err => {
            this.store.dispatch(new CommentDeleteRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  updateSuggestionOnNewComment$ = this.actions$.pipe(
    ofType<CommentCreateRequestSuccess>(
      CommentsActionTypes.CommentCreateRequestSuccess,
    ),
    switchMap(({ payload }) => {
      return of(
        new UpdateSuggestionRequest({ suggestionId: payload.comment.postId }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private suggestionService: GrapevineSuggestionService,
    private commentEventService: GrapevineCommentEventsService,
    private store: Store<AppState>,
  ) {}
}
