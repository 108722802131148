import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AllCurrentSuggestionsLoadedSuccess,
  AllCurrentSuggestionRequest,
  AllCurrentSuggestionsLoadedFail,
  SuggestionActionTypes,
  SuggestionStatusChangeRequestSuccess,
  SuggestionStatusChangeRequest,
  SuggestionStatusChangeRequestFail,
  AllArchiveSuggestionsRequested,
  AllArchiveSuggestionsLoadedSuccess,
  AllArchiveSuggestionsLoadedFail,
  SuggestionWorkerAddRequest,
  SuggestionWorkerAddRequestSuccess,
  SuggestionWorkerAddRequestFail,
  SuggestionWorkerRemoveRequest,
  SuggestionWorkerRemoveRequestSuccess,
  SuggestionWorkerRemoveRequestFail,
  GetExtendedSuggestioRequest,
  GetExtendedSuggestioRequestSuccess,
  GetExtendedSuggestioRequestFail,
  SuggestionCategoryChangeRequest,
  SuggestionCategoryChangeRequestFail,
  SuggestionCategoryChangeRequestSuccess,
  GetSuggestionCategoriesRequest,
  GetSuggestionCategoriesRequestSuccess,
  GetSuggestionCategoriesRequestFail,
  UpdateSuggestionRequestSuccess,
  UpdateSuggestionRequest,
  LatestSuggestionsRequest,
  LatestSuggestionsRequestSuccess,
  LatestSuggestionsRequestFail,
  UpdateLastCurrentSelectedSuggestionRequest,
  UpdateLastArchivedSelectedSuggestionRequest,
  UpdateSuggestionRequestFail,
  ShowSuggestionInlineError,
  SuggestionUpdateCounters,
  GetExtendedSuggestionRequestSilent,
  SetActiveSuggestionRequest,
  SetActiveSuggestion,
  GetExtendedSuggestionSilentSuccess,
  GetExtendedSuggestioSilentFail,
  SuggestionSetFirstCurrentActive,
  SuggestionSetFirstArchiveActive,
  SuggestionCreateRequest,
  SuggestionCreateRequestSuccess,
  SuggestionCreateRequestFail,
} from './suggestions.action';
import {
  catchError,
  map,
  mergeMap,
  withLatestFrom,
  switchMap,
  concatMap,
} from 'rxjs/operators';
import { AppState } from '../../../store/reducers';
import { Store, select } from '@ngrx/store';
import { GrapevineSuggestionService } from '@app/api/services/grapevine-suggestion.service';
import { GrapevineSuggestionEventsService } from '@app/api/services';
import {
  ClientSuggestionExtended,
  PageOfClientSuggestionExtended,
  ClientSuggestionStatusChangeConfirmation,
  ClientSuggestionWorkerAddConfirmation,
  ClientSuggestionWorkerRemoveConfirmation,
  ClientSuggestionCategoryChangeConfirmation,
  ClientSuggestionCategory,
  ClientPostCreateConfirmation,
} from '@app/api/models';
import { Update } from '@ngrx/entity';
import { throwError, of, EMPTY } from 'rxjs';
import {
  selectCurrentSuggestions,
  selectArchivedSuggestions,
  selectSuggestionById,
  selectCurrentSuggestionStatusFilter,
  selectAssignmentFilter,
  selectFirstCurrentSuggestion,
  selectFirstArchivedSuggestion,
} from './suggestion.selector';
import { SuggestionStatus } from '@app/loesbar/businesslogic/models/suggestion-status';
import { HttpErrorResponse } from '@angular/common/http';
import { AssignmentFilterType } from '@app/loesbar/businesslogic/models/assignment-filter';
import {
  NotificationService,
  MessageType,
} from '@app/core/services/notificationService';

@Injectable()
export class SuggestionEffects {
  @Effect()
  loadAllCurrentSuggestions$ = this.actions$.pipe(
    ofType<AllCurrentSuggestionRequest>(
      SuggestionActionTypes.AllCurrentSuggestionRequest,
    ),
    withLatestFrom(
      this.store.pipe(select(selectCurrentSuggestions)),
      this.store.pipe(select(selectCurrentSuggestionStatusFilter)),
      this.store.pipe(select(selectAssignmentFilter)),
    ),
    mergeMap(payload => {
      let params = {
        excludedStatus: [SuggestionStatus.DONE],
        includedStatus: payload[2],
        count: 25,
        page: Math.floor(payload[1].length / 25),
      };
      if (payload[3].type === AssignmentFilterType.UNASSIGNED) {
        params['unassigned'] = true;
      } else if (payload[3].type === AssignmentFilterType.MINE) {
        params['containedWorkerId'] = payload[3].containedWorkerId;
      }
      return this.suggestionsServices
        .getExtendedSuggestionsUsingGET({ ...params, sortBy: 'LAST_ACTIVITY' })
        .pipe(
          map(
            (response: PageOfClientSuggestionExtended) =>
              new AllCurrentSuggestionsLoadedSuccess({
                suggestions: response.content,
                total: response.totalElements,
              }),
          ),
          catchError(err => {
            this.store.dispatch(new AllCurrentSuggestionsLoadedFail(err));
            return throwError(err);
          }),
        );
    }),
  );

  @Effect()
  loadAllArchiveSuggestions$ = this.actions$.pipe(
    ofType<AllArchiveSuggestionsRequested>(
      SuggestionActionTypes.AllArchiveSuggestionsRequested,
    ),
    withLatestFrom(
      this.store.pipe(select(selectArchivedSuggestions)),
      this.store.pipe(select(selectAssignmentFilter)),
    ),
    mergeMap(payload => {
      let params = {
        includedStatus: [SuggestionStatus.DONE],
        count: 15,
        page: Math.floor(payload[1].length / 15),
        sortBy: 'LAST_ACTIVITY',
      };
      if (payload[2].type === AssignmentFilterType.UNASSIGNED) {
        params['unassigned'] = true;
      } else if (payload[2].type === AssignmentFilterType.MINE) {
        params['containedWorkerId'] = payload[2].containedWorkerId;
      }
      return this.suggestionsServices
        .getExtendedSuggestionsUsingGET({ ...params, sortBy: 'LAST_ACTIVITY' })
        .pipe(
          map(
            (response: PageOfClientSuggestionExtended) =>
              new AllArchiveSuggestionsLoadedSuccess({
                suggestions: response.content,
                total: response.totalElements,
              }),
          ),
          catchError(err => {
            this.store.dispatch(new AllArchiveSuggestionsLoadedFail(err));
            return throwError(err);
          }),
        );
    }),
  );

  @Effect()
  loadLatestUpdatedSuggestions$ = this.actions$.pipe(
    ofType<LatestSuggestionsRequest>(
      SuggestionActionTypes.LatestSuggestionsRequest,
    ),
    mergeMap(() =>
      this.suggestionsServices
        .getExtendedSuggestionsUsingGET({
          count: 10,
          page: 0,
          sortBy: 'LAST_ACTIVITY',
        })
        .pipe(
          map(
            (response: PageOfClientSuggestionExtended) =>
              new LatestSuggestionsRequestSuccess({
                suggestions: response.content,
              }),
          ),
          catchError(err => {
            this.store.dispatch(new LatestSuggestionsRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  saveSuggestion$ = this.actions$.pipe(
    ofType<SuggestionStatusChangeRequest>(
      SuggestionActionTypes.SuggestionStatusChangeRequest,
    ),
    switchMap(({ payload }) =>
      this.suggestionsEventService
        .onSuggestionStatusChangeRequestUsingPOST({
          request: {
            suggestionId: payload.suggestion.id,
            newStatus: payload.suggestion.suggestionStatus,
          },
        })
        .pipe(
          map((response: ClientSuggestionStatusChangeConfirmation) => {
            const changes = response.changedSuggestion;
            const updatedSuggestion: Update<ClientSuggestionExtended> = {
              id: response.changedSuggestion.id,
              changes,
            };

            this.updateLastSelectedSuggestion(
              payload.lastSuggestionStatus,
              response.changedSuggestion.suggestionStatus,
              updatedSuggestion.id,
            );

            this.updateSuggestionCounters(response, payload);

            return new SuggestionStatusChangeRequestSuccess({
              suggestion: updatedSuggestion,
            });
          }),
          catchError(err => {
            this.store.dispatch(new SuggestionStatusChangeRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  addWorkerToSuggestion$ = this.actions$.pipe(
    ofType<SuggestionWorkerAddRequest>(
      SuggestionActionTypes.SuggestionWorkerAddRequest,
    ),
    concatMap(({ payload }) =>
      this.suggestionsEventService
        .onSuggestionWorkerAddRequestUsingPOST({
          request: {
            suggestionId: payload.suggestionId,
            workerPersonId: payload.workerPersonId,
          },
        })
        .pipe(
          map((response: ClientSuggestionWorkerAddConfirmation) => {
            const changes = response.changedSuggestion;
            const updatedSuggestion: Update<ClientSuggestionExtended> = {
              id: response.changedSuggestion.id,
              changes,
            };
            return new SuggestionWorkerAddRequestSuccess({
              suggestion: updatedSuggestion,
            });
          }),
          catchError(err => {
            this.store.dispatch(new SuggestionWorkerAddRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  removeWorkerFromSuggestion$ = this.actions$.pipe(
    ofType<SuggestionWorkerRemoveRequest>(
      SuggestionActionTypes.SuggestionWorkerRemoveRequest,
    ),
    concatMap(({ payload }) =>
      this.suggestionsEventService
        .onSuggestionWorkerRemoveRequestUsingPOST({
          request: { suggestionId: payload.suggestionId },
        })
        .pipe(
          map((response: ClientSuggestionWorkerRemoveConfirmation) => {
            const changes = response.changedSuggestion;
            const updatedSuggestion: Update<ClientSuggestionExtended> = {
              id: response.changedSuggestion.id,
              changes,
            };
            return new SuggestionWorkerRemoveRequestSuccess({
              suggestion: updatedSuggestion,
            });
          }),
          catchError(err => {
            this.store.dispatch(new SuggestionWorkerRemoveRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  getExtendedSuggestion$ = this.actions$.pipe(
    ofType<GetExtendedSuggestioRequest>(
      SuggestionActionTypes.GetExtendedSuggestioRequest,
    ),
    concatMap(({ payload }) =>
      this.suggestionsServices
        .getExtendedSuggestionUsingGET({
          suggestionId: payload.suggestionId,
        })
        .pipe(
          map((response: ClientSuggestionExtended) => {
            return new GetExtendedSuggestioRequestSuccess({
              suggestion: response,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status == 403) {
              return of(new ShowSuggestionInlineError(error));
            } else {
              this.store.dispatch(new GetExtendedSuggestioRequestFail(error));
              return throwError(error);
            }
          }),
        ),
    ),
  );

  // Effect triggered by GetExtendedSuggestionRequestSilent
  // used by unread-message-counter-service, fails silently
  @Effect()
  getExtendedSuggestionSilent$ = this.actions$.pipe(
    ofType<GetExtendedSuggestionRequestSilent>(
      SuggestionActionTypes.GetExtendedSuggestionRequestSilent,
    ),
    concatMap(({ payload }) =>
      this.suggestionsServices
        .getExtendedSuggestionUsingGET({
          suggestionId: payload.suggestionId,
        })
        .pipe(
          map((response: ClientSuggestionExtended) => {
            return new GetExtendedSuggestionSilentSuccess({
              suggestion: response,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new GetExtendedSuggestioSilentFail(error));
          }),
        ),
    ),
  );

  @Effect()
  updateSuggestion$ = this.actions$.pipe(
    ofType<UpdateSuggestionRequest>(
      SuggestionActionTypes.UpdateSuggestionRequest,
    ),
    switchMap(({ payload }) =>
      this.suggestionsServices
        .getExtendedSuggestionUsingGET({
          suggestionId: payload.suggestionId,
        })
        .pipe(
          map((response: ClientSuggestionExtended) => {
            const updatedSuggestion: Update<ClientSuggestionExtended> = {
              id: response.id,
              changes: response,
            };

            return new UpdateSuggestionRequestSuccess({
              suggestion: updatedSuggestion,
            });
          }),
          catchError(err => {
            this.store.dispatch(new UpdateSuggestionRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  changeSuggestionCategory$ = this.actions$.pipe(
    ofType<SuggestionCategoryChangeRequest>(
      SuggestionActionTypes.SuggestionCategoryChangeRequest,
    ),
    switchMap(({ payload }) =>
      this.suggestionsEventService
        .onSuggestionCategoryChangeRequestUsingPOST({
          request: payload.suggestion,
        })
        .pipe(
          map((response: ClientSuggestionCategoryChangeConfirmation) => {
            const updatedSuggestion: Update<ClientSuggestionExtended> = {
              id: response.changedSuggestion.id,
              changes: response.changedSuggestion,
            };
            return new SuggestionCategoryChangeRequestSuccess({
              suggestion: updatedSuggestion,
            });
          }),
          catchError(err => {
            this.store.dispatch(new SuggestionCategoryChangeRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  getSuggestionCategories$ = this.actions$.pipe(
    ofType<GetSuggestionCategoriesRequest>(
      SuggestionActionTypes.GetSuggestionCategoriesRequest,
    ),
    switchMap(() =>
      this.suggestionsServices.getSuggestionCategoriesUsingGET().pipe(
        map(
          (response: ClientSuggestionCategory[]) =>
            new GetSuggestionCategoriesRequestSuccess({ categories: response }),
        ),
        catchError(err => {
          this.store.dispatch(new GetSuggestionCategoriesRequestFail(err));
          return throwError(err);
        }),
      ),
    ),
  );

  @Effect()
  setActiveSuggestion$ = this.actions$.pipe(
    ofType<SetActiveSuggestionRequest>(
      SuggestionActionTypes.SetActiveSuggestionRequest,
    ),
    map(action => action.payload),
    mergeMap(
      payload =>
        of(payload).pipe(
          withLatestFrom(
            this.store.pipe(select(selectSuggestionById(payload.suggestionId))),
          ),
        ),
      (payload, latestStoreData) => latestStoreData,
    ),
    switchMap(([, foundSuggestion]) => {
      const activeSuggestionId = foundSuggestion ? foundSuggestion.id : '';
      return of(new SetActiveSuggestion({ suggestionId: activeSuggestionId }));
    }),
  );

  @Effect()
  setFirstCurrentSuggestionActive$ = this.actions$.pipe(
    ofType<SuggestionSetFirstCurrentActive>(
      SuggestionActionTypes.SuggestionSetFirstCurrentActive,
    ),
    withLatestFrom(this.store.pipe(select(selectFirstCurrentSuggestion))),
    switchMap(payload => {
      if (payload[1]) {
        return of(new SetActiveSuggestion({ suggestionId: payload[1].id }));
      } else {
        return EMPTY;
      }
    }),
  );

  @Effect()
  setFirstArchiveSuggestionActive$ = this.actions$.pipe(
    ofType<SuggestionSetFirstArchiveActive>(
      SuggestionActionTypes.SuggestionSetFirstArchiveActive,
    ),
    withLatestFrom(this.store.pipe(select(selectFirstArchivedSuggestion))),
    switchMap(payload => {
      if (payload[1]) {
        return of(new SetActiveSuggestion({ suggestionId: payload[1].id }));
      } else {
        return EMPTY;
      }
    }),
  );

  @Effect()
  createSuggestion$ = this.actions$.pipe(
    ofType<SuggestionCreateRequest>(
      SuggestionActionTypes.SuggestionCreateRequest,
    ),
    switchMap(createRequest =>
      this.suggestionsEventService
        .onSuggestionCreateRequestUsingPOST({
          request: createRequest.payload.clientSuggestionCreateRequest,
          appVariantIdentifier: '',
        })
        .pipe(
          map(
            (confirmation: ClientPostCreateConfirmation) =>
              new SuggestionCreateRequestSuccess({
                clientPostCreateConfirmation: confirmation,
              }),
          ),
          catchError(err => {
            this.notificationService.showToastMessage(
              err.error.message,
              'Fehler bei Übermittlung',
              MessageType.ERROR,
            );
            this.store.dispatch(new SuggestionCreateRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  createSuggestionSuccess$ = this.actions$.pipe(
    ofType<SuggestionCreateRequestSuccess>(
      SuggestionActionTypes.SuggestionCreateRequestSuccess,
    ),
    switchMap(confirmation => {
      return of(
        new GetExtendedSuggestioRequest({
          suggestionId:
            confirmation.payload.clientPostCreateConfirmation.post.id,
        }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private suggestionsServices: GrapevineSuggestionService,
    private suggestionsEventService: GrapevineSuggestionEventsService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
  ) {}

  private updateSuggestionCounters(
    response: ClientSuggestionStatusChangeConfirmation,
    payload: {
      suggestion: ClientSuggestionExtended;
      lastSuggestionStatus: string;
    },
  ) {
    if (response.changedSuggestion.suggestionStatus === SuggestionStatus.DONE) {
      // move from current --> archive
      this.store.dispatch(
        new SuggestionUpdateCounters({
          deltaArchive: 1,
          deltaCurrent: -1,
        }),
      );
    } else {
      // new status != DONE, what was it before?
      if (payload.lastSuggestionStatus === SuggestionStatus.DONE) {
        // move from archive --> current
        this.store.dispatch(
          new SuggestionUpdateCounters({
            deltaArchive: -1,
            deltaCurrent: 1,
          }),
        );
      }
    }
  }

  /**
   * Updates the lastCurrentSelectedSuggestionID and lastArchivedSelectedSuggestionID if needed.
   * 1. If the Updated suggestion changes the status from 'DONE' to 'IN_PROGRESS' or 'OPEN'  is needed to update
   *    lastArchivedSelectedSuggestionID to undefined and lastCurrentSelectedSuggestionID to the updated suggestion ID
   * 2. If the Updated suggestion changes the status from 'IN_PROGRESS' or 'OPEN' to 'DONE'  is needed to update
   *    lastCurrentSelectedSuggestionID to undefined and lastArchivedSelectedSuggestionID to the updated suggestion ID
   * 3. If the Updated suggestion changes the status from 'IN_PROGRESS' to 'OPEN' or vice-versa, no change is needed
   * @param lastSuggestionStatus
   * @param currentSuggestionStatus
   * @param suggestionId
   */
  private updateLastSelectedSuggestion(
    lastSuggestionStatus: string,
    currentSuggestionStatus: string,
    suggestionId: string,
  ): void {
    if (
      lastSuggestionStatus === SuggestionStatus.DONE &&
      currentSuggestionStatus !== SuggestionStatus.DONE
    ) {
      this.store.dispatch(
        new UpdateLastCurrentSelectedSuggestionRequest({ suggestionId }),
      );
      this.store.dispatch(
        new UpdateLastArchivedSelectedSuggestionRequest({
          suggestionId: undefined,
        }),
      );
    } else if (
      lastSuggestionStatus !== SuggestionStatus.DONE &&
      currentSuggestionStatus === SuggestionStatus.DONE
    ) {
      this.store.dispatch(
        new UpdateLastArchivedSelectedSuggestionRequest({ suggestionId }),
      );
      this.store.dispatch(
        new UpdateLastCurrentSelectedSuggestionRequest({
          suggestionId: undefined,
        }),
      );
    }
  }
}
