import * as tslib_1 from "tslib";
import { Actions, ofType, Effect } from '@ngrx/effects';
import { SharedDataprivacyService, ParticipantsPersonService, ParticipantsPersonEventsService, } from '@app/api/services';
import { GetSavedUserDataRequestSuccess, GetSavedUserDataRequestFail, BurgerMenuActionTypes, UploadProfilePictureRequestSuccess, UploadProfilePictureRequestFail, UpdateProfileNameRequestFail, UpdateProfileNameRequestSuccess, DeleteUserRequestSuccess, DeleteUserRequestFail } from './userdata.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
var BurgerMenuEffects = /** @class */ (function () {
    function BurgerMenuEffects(actions$, dataPrivacyService, personService, personEventService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.dataPrivacyService = dataPrivacyService;
        this.personService = personService;
        this.personEventService = personEventService;
        this.store = store;
        this.getSavedUserData$ = this.actions$.pipe(ofType(BurgerMenuActionTypes.GetSavedUserDataRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.dataPrivacyService
                .collectUserDataAndSendMailUsingPOST()
                .pipe(map(function (response) {
                return new GetSavedUserDataRequestSuccess(response);
            }), catchError(function (err) {
                _this.store.dispatch(new GetSavedUserDataRequestFail(err));
                return throwError(err);
            }));
        }));
        this.uploadProfilePicture$ = this.actions$.pipe(ofType(BurgerMenuActionTypes.UploadProfilePictureRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.personService
                .uploadProfilePictureUsingPOST(payload)
                .pipe(map(function (response) {
                return new UploadProfilePictureRequestSuccess(response);
            }), catchError(function (err) {
                _this.store.dispatch(new UploadProfilePictureRequestFail(err));
                return throwError(err);
            }));
        }));
        this.uploadProfileName$ = this.actions$.pipe(ofType(BurgerMenuActionTypes.UpdateProfileNameRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.personEventService
                .onPersonUpdateRequestUsingPOST(payload)
                .pipe(map(function (response) {
                return new UpdateProfileNameRequestSuccess(response);
            }), catchError(function (err) {
                _this.store.dispatch(new UpdateProfileNameRequestFail(err));
                return throwError(err);
            }));
        }));
        this.deleteUser$ = this.actions$.pipe(ofType(BurgerMenuActionTypes.DeleteUserRequest), switchMap(function () {
            return _this.dataPrivacyService
                .deletePersonUsingDELETE1()
                .pipe(map(function (response) {
                return new DeleteUserRequestSuccess(response);
            }), catchError(function (err) {
                _this.store.dispatch(new DeleteUserRequestFail(err));
                return throwError(err);
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BurgerMenuEffects.prototype, "getSavedUserData$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BurgerMenuEffects.prototype, "uploadProfilePicture$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BurgerMenuEffects.prototype, "uploadProfileName$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], BurgerMenuEffects.prototype, "deleteUser$", void 0);
    return BurgerMenuEffects;
}());
export { BurgerMenuEffects };
