import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Logger, I18nService } from '@app/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/reducers';
import { Title } from '@angular/platform-browser';
import { selectChatUnreadMessageCount } from './loesbar/chat-store/chats/chats.selector';

const log = new Logger('App');
const applicationTitle = 'Digitale Dörfer Lösbar';
const notificationPrefix = '(•) ';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * observe unread chat messages
   */
  private chatUnreadMessageCount$: Observable<number>;
  private unreadMessageCountSubscription: Subscription;

  constructor(
    private titleService: Title,
    private i18nService: I18nService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages,
    );

    // observe all unread messages
    this.chatUnreadMessageCount$ = this.store.pipe(
      select(selectChatUnreadMessageCount),
    );

    // ... and subscribe
    this.unreadMessageCountSubscription = this.chatUnreadMessageCount$.subscribe(
      count => {
        if (count) {
          this.titleService.setTitle(notificationPrefix + applicationTitle);
        } else {
          this.titleService.setTitle(applicationTitle);
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.unreadMessageCountSubscription.unsubscribe();
  }
}
