import * as tslib_1 from "tslib";
var _a;
import { ChatsActionTypes } from './chats.actions';
import { createEntityAdapter } from '@ngrx/entity';
var sortByLastMessage = function (a, b) {
    return a.lastMessage &&
        b.lastMessage &&
        a.lastMessage.sentTime > b.lastMessage.sentTime
        ? -1
        : 1;
};
var ɵ0 = sortByLastMessage;
export var adapter = createEntityAdapter({
    sortComparer: sortByLastMessage,
});
export var initialChatState = adapter.getInitialState({
    loading: true,
    lastSelectedChatID: null,
    lastReceivedMessageTimestamp: 0,
});
export function chatReducer(state, action) {
    if (state === void 0) { state = initialChatState; }
    switch (action.type) {
        case ChatsActionTypes.AllChatsRequest:
            return tslib_1.__assign({}, state, { loading: true });
        case ChatsActionTypes.AllChatsRequestSuccess:
            return adapter.upsertMany(action.payload.chats, tslib_1.__assign({}, state, { loading: false }));
        case ChatsActionTypes.AllChatsRequestFail:
            return tslib_1.__assign({}, state, { loading: false });
        case ChatsActionTypes.UpdateLastMessageRequest:
        case ChatsActionTypes.SetReadChatRequestSuccess:
            return adapter.updateOne(action.payload.chat, state);
        case ChatsActionTypes.ChatStartOnCommentRequestSuccess:
        case ChatsActionTypes.ChatStartOnPostRequestSuccess:
        case ChatsActionTypes.ChatStartWithSuggestionWorkerRequestSuccess:
            return adapter.upsertOne(action.payload.chat, state);
        case ChatsActionTypes.UpdateLastSelectedChatRequest:
            return tslib_1.__assign({}, state, { lastSelectedChatID: action.payload.chatId });
        case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequest:
        case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestMP:
        case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestME:
            return tslib_1.__assign({}, state, { lastReceivedMessageTimestamp: action.payload.lastReceivedMessageTimestamp });
        default:
            return state;
    }
}
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
export { ɵ0 };
