import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export enum MessageType {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export enum NotificationType {
  TOAST,
  BROWSER,
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showMessage(
    message: string,
    messageTitle: string,
    messageType: MessageType,
    notificationType: NotificationType,
  ) {
    if (notificationType === NotificationType.TOAST) {
      this.showToastMessage(message, messageTitle, messageType);
    } else {
      alert('Only toast messages are allowed at the moment');
    }
  }

  // TODO: should this be private or do we want to have it as part of the service inteface?
  showToastMessage(
    message: string,
    messageTitle: string,
    messageType: MessageType,
  ) {
    switch (messageType) {
      case MessageType.SUCCESS:
        this.toastr.success(message, messageTitle);
        break;
      case MessageType.WARNING:
        this.toastr.warning(message, messageTitle);
        break;
      case MessageType.ERROR:
        this.toastr.error(message, messageTitle);
        break;
      case MessageType.INFO:
      default:
        this.toastr.info(message, messageTitle, { enableHtml: true }); // Info messages can have links
    }
  }
}
