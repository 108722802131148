import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatPollingService } from './chat-polling.service';
import { MessagePollingService } from './message-polling.service';
import { StoreModule } from '@ngrx/store';
import { chatReducer } from './chats/chats.reducer';
import { messageReducer } from './messages/messages.reducer';
import { MessagesEffects } from './messages/messages.effects';
import { ChatsEffects } from './chats/chats.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('chats', chatReducer),
    StoreModule.forFeature('messages', messageReducer),
    EffectsModule.forFeature([MessagesEffects, ChatsEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [MessagePollingService, ChatPollingService],
})
export class ChatStoreModule {}
