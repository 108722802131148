/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientCommentChangeConfirmation } from '../models/client-comment-change-confirmation';
import { ClientCommentChangeRequest } from '../models/client-comment-change-request';
import { ClientCommentCreateConfirmation } from '../models/client-comment-create-confirmation';
import { ClientCommentCreateRequest } from '../models/client-comment-create-request';
import { ClientCommentDeleteAllForPostRequest } from '../models/client-comment-delete-all-for-post-request';
import { ClientCommentDeleteConfirmation } from '../models/client-comment-delete-confirmation';
import { ClientCommentDeleteRequest } from '../models/client-comment-delete-request';
import { ClientUserGeneratedContentFlagResponse } from '../models/client-user-generated-content-flag-response';
import { ClientCommentFlagRequest } from '../models/client-comment-flag-request';
import { ClientLikeCommentConfirmation } from '../models/client-like-comment-confirmation';
import { ClientLikeCommentRequest } from '../models/client-like-comment-request';
import { ClientUnlikeCommentConfirmation } from '../models/client-unlike-comment-confirmation';
import { ClientUnlikeCommentRequest } from '../models/client-unlike-comment-request';

/**
 * Comment Event Controller
 */
@Injectable({
  providedIn: 'root',
})
class GrapevineCommentEventsService extends __BaseService {
  static readonly onCommentChangeRequestUsingPOSTPath = '/grapevine/comment/event/commentChangeRequest';
  static readonly onCommentCreateRequestUsingPOSTPath = '/grapevine/comment/event/commentCreateRequest';
  static readonly onCommentDeleteAllForPostRequestUsingPOSTPath = '/grapevine/comment/event/commentDeleteAllForPostRequest';
  static readonly onCommentDeleteRequestUsingPOSTPath = '/grapevine/comment/event/commentDeleteRequest';
  static readonly commentFlagRequestUsingPOSTPath = '/grapevine/comment/event/commentFlagRequest';
  static readonly onLikeCommentRequestUsingPOSTPath = '/grapevine/comment/event/likeCommentRequest';
  static readonly onCommentCreateRequestBySuggestionWorkerUsingPOSTPath = '/grapevine/comment/event/suggestion/commentCreateRequest';
  static readonly onUnlikeCommentRequestUsingPOSTPath = '/grapevine/comment/event/unlikeCommentRequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Updates an existing Comment. Only text and images can be updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentChangeRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientCommentChangeConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/commentChangeRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCommentChangeConfirmation>;
      })
    );
  }
  /**
   * Updates an existing Comment. Only text and images can be updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentChangeRequestUsingPOST(params: GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams): __Observable<ClientCommentChangeConfirmation> {
    return this.onCommentChangeRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientCommentChangeConfirmation)
    );
  }

  /**
   * Creates a new Comment with or without pictures.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`NOT_MEMBER_OF_GROUP`|409 CONFLICT|The user is not a member of the group|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentCreateRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientCommentCreateConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/commentCreateRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCommentCreateConfirmation>;
      })
    );
  }
  /**
   * Creates a new Comment with or without pictures.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`NOT_MEMBER_OF_GROUP`|409 CONFLICT|The user is not a member of the group|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentCreateRequestUsingPOST(params: GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams): __Observable<ClientCommentCreateConfirmation> {
    return this.onCommentCreateRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientCommentCreateConfirmation)
    );
  }

  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   */
  onCommentDeleteAllForPostRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/commentDeleteAllForPostRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   */
  onCommentDeleteAllForPostRequestUsingPOST(params: GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams): __Observable<null> {
    return this.onCommentDeleteAllForPostRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Deletes an existing Comment. Only the creator can delete the comment.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentDeleteRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientCommentDeleteConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/commentDeleteRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCommentDeleteConfirmation>;
      })
    );
  }
  /**
   * Deletes an existing Comment. Only the creator can delete the comment.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentDeleteRequestUsingPOST(params: GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams): __Observable<ClientCommentDeleteConfirmation> {
    return this.onCommentDeleteRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientCommentDeleteConfirmation)
    );
  }

  /**
   * 'commentId' is the id of the comment, while optional 'comment' is the reason for flagging this entity
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  commentFlagRequestUsingPOSTResponse(params: GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientUserGeneratedContentFlagResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/commentFlagRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientUserGeneratedContentFlagResponse>;
      })
    );
  }
  /**
   * 'commentId' is the id of the comment, while optional 'comment' is the reason for flagging this entity
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  commentFlagRequestUsingPOST(params: GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams): __Observable<ClientUserGeneratedContentFlagResponse> {
    return this.commentFlagRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientUserGeneratedContentFlagResponse)
    );
  }

  /**
   * Likes an existing comment. It is possible to like your own comments. If a comment is already liked, it returns a CommentAlreadyLikedException.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ALREADY_LIKED`|400 BAD_REQUEST|The provided comment is already liked by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onLikeCommentRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientLikeCommentConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/likeCommentRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientLikeCommentConfirmation>;
      })
    );
  }
  /**
   * Likes an existing comment. It is possible to like your own comments. If a comment is already liked, it returns a CommentAlreadyLikedException.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ALREADY_LIKED`|400 BAD_REQUEST|The provided comment is already liked by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onLikeCommentRequestUsingPOST(params: GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams): __Observable<ClientLikeCommentConfirmation> {
    return this.onLikeCommentRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientLikeCommentConfirmation)
    );
  }

  /**
   * Creates a new Comment on a suggestion by a suggestion worker. If the suggestion is deleted or withdrawn comments can not be created.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentCreateRequestBySuggestionWorkerUsingPOSTResponse(params: GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams): __Observable<__StrictHttpResponse<ClientCommentCreateConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/suggestion/commentCreateRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCommentCreateConfirmation>;
      })
    );
  }
  /**
   * Creates a new Comment on a suggestion by a suggestion worker. If the suggestion is deleted or withdrawn comments can not be created.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onCommentCreateRequestBySuggestionWorkerUsingPOST(params: GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams): __Observable<ClientCommentCreateConfirmation> {
    return this.onCommentCreateRequestBySuggestionWorkerUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientCommentCreateConfirmation)
    );
  }

  /**
   * Unlikes an existing comment. If a comment is already unliked, it returns a CommentAlreadyUnlikedException.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ALREADY_UNLIKED`|400 BAD_REQUEST|The provided comment is already unliked by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * |`COMMENT_NOT_LIKED`|404 NOT_FOUND|The requested comment has not been liked before|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onUnlikeCommentRequestUsingPOSTResponse(params: GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientUnlikeCommentConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/comment/event/unlikeCommentRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientUnlikeCommentConfirmation>;
      })
    );
  }
  /**
   * Unlikes an existing comment. If a comment is already unliked, it returns a CommentAlreadyUnlikedException.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`COMMENT_ALREADY_UNLIKED`|400 BAD_REQUEST|The provided comment is already unliked by this user|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * |`COMMENT_NOT_LIKED`|404 NOT_FOUND|The requested comment has not been liked before|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onUnlikeCommentRequestUsingPOST(params: GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams): __Observable<ClientUnlikeCommentConfirmation> {
    return this.onUnlikeCommentRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientUnlikeCommentConfirmation)
    );
  }
}

module GrapevineCommentEventsService {

  /**
   * Parameters for onCommentChangeRequestUsingPOST
   */
  export interface OnCommentChangeRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientCommentChangeRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onCommentCreateRequestUsingPOST
   */
  export interface OnCommentCreateRequestUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientCommentCreateRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }

  /**
   * Parameters for onCommentDeleteAllForPostRequestUsingPOST
   */
  export interface OnCommentDeleteAllForPostRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientCommentDeleteAllForPostRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onCommentDeleteRequestUsingPOST
   */
  export interface OnCommentDeleteRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientCommentDeleteRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for commentFlagRequestUsingPOST
   */
  export interface CommentFlagRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientCommentFlagRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onLikeCommentRequestUsingPOST
   */
  export interface OnLikeCommentRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientLikeCommentRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }

  /**
   * Parameters for onCommentCreateRequestBySuggestionWorkerUsingPOST
   */
  export interface OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientCommentCreateRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onUnlikeCommentRequestUsingPOST
   */
  export interface OnUnlikeCommentRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientUnlikeCommentRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }
}

export { GrapevineCommentEventsService }
