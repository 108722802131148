/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientChatMessage } from '../models/client-chat-message';
import { ClientChat } from '../models/client-chat';
import { PageOfClientChatMessage } from '../models/page-of-client-chat-message';

/**
 * Chat Controller
 */
@Injectable({
  providedIn: 'root',
})
class CommunicationChatService extends __BaseService {
  static readonly getChatMessageUsingGETPath = '/communication/chat/message/{chatMessageId}';
  static readonly getChatUsingGETPath = '/communication/chat/{chatId}';
  static readonly getChatMessagesUsingGETPath = '/communication/chat/{chatId}/message';
  static readonly getChatMessagesPagedUsingGETPath = '/communication/chat/{chatId}/message/paged';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Checked if the current user is a participant
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessageUsingGETParams` containing the following parameters:
   *
   * - `chatMessageId`: chatMessageId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessageUsingGETResponse(params: CommunicationChatService.GetChatMessageUsingGETParams): __Observable<__StrictHttpResponse<ClientChatMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/communication/chat/message/${params.chatMessageId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChatMessage>;
      })
    );
  }
  /**
   * Checked if the current user is a participant
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessageUsingGETParams` containing the following parameters:
   *
   * - `chatMessageId`: chatMessageId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessageUsingGET(params: CommunicationChatService.GetChatMessageUsingGETParams): __Observable<ClientChatMessage> {
    return this.getChatMessageUsingGETResponse(params).pipe(
      __map(_r => _r.body as ClientChatMessage)
    );
  }

  /**
   * Checked if the current user is a participant
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatUsingGETParams` containing the following parameters:
   *
   * - `chatId`: chatId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatUsingGETResponse(params: CommunicationChatService.GetChatUsingGETParams): __Observable<__StrictHttpResponse<ClientChat>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/communication/chat/${params.chatId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChat>;
      })
    );
  }
  /**
   * Checked if the current user is a participant
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatUsingGETParams` containing the following parameters:
   *
   * - `chatId`: chatId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatUsingGET(params: CommunicationChatService.GetChatUsingGETParams): __Observable<ClientChat> {
    return this.getChatUsingGETResponse(params).pipe(
      __map(_r => _r.body as ClientChat)
    );
  }

  /**
   * The received timestamp for the requesting participant is updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessagesUsingGETParams` containing the following parameters:
   *
   * - `from`: from
   *
   * - `chatId`: chatId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessagesUsingGETResponse(params: CommunicationChatService.GetChatMessagesUsingGETParams): __Observable<__StrictHttpResponse<Array<ClientChatMessage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.from != null) __params = __params.set('from', params.from.toString());

    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/communication/chat/${params.chatId}/message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientChatMessage>>;
      })
    );
  }
  /**
   * The received timestamp for the requesting participant is updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessagesUsingGETParams` containing the following parameters:
   *
   * - `from`: from
   *
   * - `chatId`: chatId
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessagesUsingGET(params: CommunicationChatService.GetChatMessagesUsingGETParams): __Observable<Array<ClientChatMessage>> {
    return this.getChatMessagesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ClientChatMessage>)
    );
  }

  /**
   * The received timestamp for the requesting participant is updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessagesPagedUsingGETParams` containing the following parameters:
   *
   * - `chatId`: chatId
   *
   * - `page`: page number of the paged list of chat messages
   *
   * - `from`: time from which on the chat messages should be returned
   *
   * - `count`: maximum number of chat messages returned in a page, if 0 no pagination is applied
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessagesPagedUsingGETResponse(params: CommunicationChatService.GetChatMessagesPagedUsingGETParams): __Observable<__StrictHttpResponse<PageOfClientChatMessage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/communication/chat/${params.chatId}/message/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageOfClientChatMessage>;
      })
    );
  }
  /**
   * The received timestamp for the requesting participant is updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatService.GetChatMessagesPagedUsingGETParams` containing the following parameters:
   *
   * - `chatId`: chatId
   *
   * - `page`: page number of the paged list of chat messages
   *
   * - `from`: time from which on the chat messages should be returned
   *
   * - `count`: maximum number of chat messages returned in a page, if 0 no pagination is applied
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  getChatMessagesPagedUsingGET(params: CommunicationChatService.GetChatMessagesPagedUsingGETParams): __Observable<PageOfClientChatMessage> {
    return this.getChatMessagesPagedUsingGETResponse(params).pipe(
      __map(_r => _r.body as PageOfClientChatMessage)
    );
  }
}

module CommunicationChatService {

  /**
   * Parameters for getChatMessageUsingGET
   */
  export interface GetChatMessageUsingGETParams {

    /**
     * chatMessageId
     */
    chatMessageId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getChatUsingGET
   */
  export interface GetChatUsingGETParams {

    /**
     * chatId
     */
    chatId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getChatMessagesUsingGET
   */
  export interface GetChatMessagesUsingGETParams {

    /**
     * from
     */
    from: number;

    /**
     * chatId
     */
    chatId: string;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for getChatMessagesPagedUsingGET
   */
  export interface GetChatMessagesPagedUsingGETParams {

    /**
     * chatId
     */
    chatId: string;

    /**
     * page number of the paged list of chat messages
     */
    page?: number;

    /**
     * time from which on the chat messages should be returned
     */
    from?: number;

    /**
     * maximum number of chat messages returned in a page, if 0 no pagination is applied
     */
    count?: number;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { CommunicationChatService }
