import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers';
import { mergeMap } from 'rxjs/operators';
import { timer, EMPTY } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { LatestSuggestionsRequest } from './suggestions/suggestions.action';

@Injectable()
export class SuggestionPollingService implements OnDestroy {
  /**
   * Suggestion Polling Service subscription
   */
  suggestionsSubscription$: any;

  /**
   * Interval in milliseconds of the polling suggestions. One minute
   */
  POLLING_INTERVAL_SUGGESTIONS = 60000;

  constructor(
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnDestroy() {
    this.unsubscribePollingService();
  }

  init() {
    this.unsubscribePollingService();
    this.suggestionsSubscription$ = this.getLatestUpdatedSuggestions().subscribe();
  }

  /**
   * Suggestion Polling service function
   */
  getLatestUpdatedSuggestions() {
    return timer(0, this.POLLING_INTERVAL_SUGGESTIONS).pipe(
      mergeMap(() => {
        if (this.authenticationService.hasValidToken()) {
          this.store.dispatch(new LatestSuggestionsRequest());
        }
        return EMPTY;
      }),
    );
  }

  private unsubscribePollingService() {
    if (this.suggestionsSubscription$) {
      this.suggestionsSubscription$.unsubscribe();
    }
  }
}
