/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientPersonOwn } from '../models/client-person-own';
import { ClientMediaItem } from '../models/client-media-item';

/**
 * Person Controller
 */
@Injectable({
  providedIn: 'root',
})
class ParticipantsPersonService extends __BaseService {
  static readonly getPersonUsingGETPath = '/person';
  static readonly uploadProfilePictureUsingPOSTPath = '/person/picture';
  static readonly deleteProfilePictureUsingDELETEPath = '/person/picture';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getPersonUsingGETResponse(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<ClientPersonOwn>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientPersonOwn>;
      })
    );
  }
  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getPersonUsingGET(appVariantIdentifier?: string): __Observable<ClientPersonOwn> {
    return this.getPersonUsingGETResponse(appVariantIdentifier).pipe(
      __map(_r => _r.body as ClientPersonOwn)
    );
  }

  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`MEDIA_ITEM_UPLOAD_FAILED`|400 BAD_REQUEST|The upload of a media item failed|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param params The `ParticipantsPersonService.UploadProfilePictureUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return Created
   */
  uploadProfilePictureUsingPOSTResponse(params: ParticipantsPersonService.UploadProfilePictureUsingPOSTParams): __Observable<__StrictHttpResponse<ClientMediaItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/person/picture`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientMediaItem>;
      })
    );
  }
  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`MEDIA_ITEM_UPLOAD_FAILED`|400 BAD_REQUEST|The upload of a media item failed|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param params The `ParticipantsPersonService.UploadProfilePictureUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return Created
   */
  uploadProfilePictureUsingPOST(params: ParticipantsPersonService.UploadProfilePictureUsingPOSTParams): __Observable<ClientMediaItem> {
    return this.uploadProfilePictureUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientMediaItem)
    );
  }

  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   */
  deleteProfilePictureUsingDELETEResponse(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/person/picture`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * | ― | ― | ― |
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   */
  deleteProfilePictureUsingDELETE(appVariantIdentifier?: string): __Observable<null> {
    return this.deleteProfilePictureUsingDELETEResponse(appVariantIdentifier).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ParticipantsPersonService {

  /**
   * Parameters for uploadProfilePictureUsingPOST
   */
  export interface UploadProfilePictureUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { ParticipantsPersonService }
