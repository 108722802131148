import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers';
import { switchMap } from 'rxjs/operators';
import { timer, EMPTY } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { PollingAllChatsRequest } from './chats/chats.actions';

const POLLING_INTERVAL = 60000; // in milliseconds

@Injectable()
export class ChatPollingService implements OnDestroy {
  /**
   * Chat Polling Service subscription
   */
  ownChatssubscription$: any;

  constructor(
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
  ) {}

  subscribeToChatPollingService() {
    this.unsubscribePollingService();
    this.ownChatssubscription$ = this.getAllChats().subscribe(
      res => {
        // do nothing for now :)
      },
      error => {
        this.ownChatssubscription$.unsubscribe();
      },
    );
  }

  getAllChats() {
    // start first request instantly, and every POLLING_INTERVAL seconds after
    return timer(0, POLLING_INTERVAL).pipe(
      switchMap(() => {
        if (this.authenticationService.hasValidToken()) {
          this.store.dispatch(new PollingAllChatsRequest());
        }
        return EMPTY;
      }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribePollingService();
  }

  private unsubscribePollingService() {
    if (this.ownChatssubscription$) {
      // Stop polling old subcription
      this.ownChatssubscription$.unsubscribe();
    }
  }
}
