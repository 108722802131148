import { Component, OnInit } from '@angular/core';
import { ChatPollingService } from '@app/loesbar/chat-store/chat-polling.service';
import { SuggestionPollingService } from '@app/loesbar/suggestion-store/suggestion-polling.service';
import { Logger } from '@app/core';

const log = new Logger('Shell');
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  constructor(
    private chatPollingService: ChatPollingService,
    private suggestionPollingService: SuggestionPollingService,
  ) {}

  ngOnInit() {
    log.debug('init');
    this.chatPollingService.subscribeToChatPollingService();
    this.suggestionPollingService.init();
  }
}
