/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/loader2/loader2.component.ngfactory";
import * as i3 from "../../shared/loader2/loader2.component";
import * as i4 from "./callback.component";
import * as i5 from "../../core/authentication/authentication.service";
import * as i6 from "@angular/router";
var styles_CallbackComponent = [i0.styles];
var RenderType_CallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallbackComponent, data: {} });
export { RenderType_CallbackComponent as RenderType_CallbackComponent };
export function View_CallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader2", [["isLoading", "true"]], null, null, null, i2.View_Loader2Component_0, i2.RenderType_Loader2Component)), i1.ɵdid(1, 114688, null, 0, i3.Loader2Component, [], { isLoading: [0, "isLoading"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-callback", [], null, null, null, View_CallbackComponent_0, RenderType_CallbackComponent)), i1.ɵdid(1, 114688, null, 0, i4.CallbackComponent, [i5.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallbackComponentNgFactory = i1.ɵccf("app-callback", i4.CallbackComponent, View_CallbackComponent_Host_0, {}, {}, []);
export { CallbackComponentNgFactory as CallbackComponentNgFactory };
