import * as tslib_1 from "tslib";
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { SuggestionActionTypes } from './suggestions.action';
import { AssignmentFilterType, } from '@app/loesbar/businesslogic/models/assignment-filter';
var sortByLastActivity = function (a, b) {
    return a.lastActivity > b.lastActivity ? -1 : 1;
};
var ɵ0 = sortByLastActivity;
export var adapter = createEntityAdapter({
    sortComparer: sortByLastActivity,
});
export var initialSuggestionState = adapter.getInitialState({
    loadingCurrentSuggestion: false,
    loadingArchiveSuggestion: false,
    allCurrentSuggestionsLoaded: false,
    totalCurrentSuggestions: 0,
    allArchiveSuggestionsLoaded: false,
    totalArchiveSuggestions: 0,
    selectedId: null,
    categories: [],
    lastCurrentSelectedSuggestionID: null,
    lastArchivedSelectedSuggestionID: null,
    loadingSingleSuggestion: true,
    inlineError: null,
    currentSuggestionStatusFilter: [],
    assignmentFilter: { type: AssignmentFilterType.ALL, containedWorkerId: null },
    activeSuggestionId: '',
});
export function suggestionReducer(state, action) {
    if (state === void 0) { state = initialSuggestionState; }
    switch (action.type) {
        case SuggestionActionTypes.AllCurrentSuggestionRequest:
            return tslib_1.__assign({}, state, { loadingCurrentSuggestion: true });
        case SuggestionActionTypes.AllCurrentSuggestionsLoadedFail:
            return tslib_1.__assign({}, state, { loadingCurrentSuggestion: false });
        case SuggestionActionTypes.AllCurrentSuggestionsLoadedSuccess:
            return adapter.addMany(action.payload.suggestions, tslib_1.__assign({}, state, { allCurrentSuggestionsLoaded: true, loadingCurrentSuggestion: false, totalCurrentSuggestions: action.payload.total }));
        case SuggestionActionTypes.AllArchiveSuggestionsRequested:
            return tslib_1.__assign({}, state, { loadingArchiveSuggestion: true });
        case SuggestionActionTypes.AllArchiveSuggestionsLoadedFail:
            return tslib_1.__assign({}, state, { loadingArchiveSuggestion: false });
        case SuggestionActionTypes.AllArchiveSuggestionsLoadedSuccess:
            return adapter.addMany(action.payload.suggestions, tslib_1.__assign({}, state, { allArchiveSuggestionsLoaded: true, loadingArchiveSuggestion: false, totalArchiveSuggestions: action.payload.total }));
        case SuggestionActionTypes.LatestSuggestionsRequest:
            return tslib_1.__assign({}, state);
        case SuggestionActionTypes.LatestSuggestionsRequestFail:
            return tslib_1.__assign({}, state);
        case SuggestionActionTypes.LatestSuggestionsRequestSuccess:
            return adapter.upsertMany(action.payload.suggestions, tslib_1.__assign({}, state));
        case SuggestionActionTypes.SuggestionStatusChangeRequestSuccess:
        case SuggestionActionTypes.SuggestionWorkerAddRequestSuccess:
        case SuggestionActionTypes.SuggestionWorkerRemoveRequestSuccess:
            return adapter.updateOne(action.payload.suggestion, tslib_1.__assign({}, state, { activeSuggestion: action.payload.suggestion.changes, assignmentFilter: {
                    type: AssignmentFilterType.ALL,
                    containedWorkerId: null,
                }, currentSuggestionStatusFilter: [] }));
        case SuggestionActionTypes.SuggestionCategoryChangeRequestSuccess:
        case SuggestionActionTypes.UpdateSuggestionRequestSuccess:
            return adapter.updateOne(action.payload.suggestion, tslib_1.__assign({}, state));
        case SuggestionActionTypes.GetExtendedSuggestioRequest:
            return tslib_1.__assign({}, state, { loadingSingleSuggestion: true });
        case SuggestionActionTypes.GetExtendedSuggestioRequestSuccess:
            return adapter.addOne(action.payload.suggestion, tslib_1.__assign({}, state, { activeSuggestionId: action.payload.suggestion.id, loadingSingleSuggestion: false }));
        case SuggestionActionTypes.GetExtendedSuggestionSilentSuccess:
            return adapter.addOne(action.payload.suggestion, tslib_1.__assign({}, state, { loadingSingleSuggestion: false }));
        case SuggestionActionTypes.SetActiveSuggestion:
            return tslib_1.__assign({}, state, { activeSuggestionId: action.payload.suggestionId });
        case SuggestionActionTypes.GetExtendedSuggestioRequestFail:
            return tslib_1.__assign({}, state, { loadingSingleSuggestion: false });
        case SuggestionActionTypes.GetSuggestionCategoriesRequestSuccess:
            return tslib_1.__assign({}, state, { categories: action.payload.categories });
        case SuggestionActionTypes.UpdateLastCurrentSelectedSuggestionRequest:
            return tslib_1.__assign({}, state, { lastCurrentSelectedSuggestionID: action.payload.suggestionId, inlineError: null });
        case SuggestionActionTypes.UpdateLastArchivedSelectedSuggestionRequest:
            return tslib_1.__assign({}, state, { lastArchivedSelectedSuggestionID: action.payload.suggestionId, inlineError: null });
        case SuggestionActionTypes.ShowSuggestionInlineError:
            return tslib_1.__assign({}, state, { loadingSingleSuggestion: false, inlineError: action.payload.error });
        case SuggestionActionTypes.SuggestionUpdateCounters:
            return tslib_1.__assign({}, state, { totalArchiveSuggestions: state.totalArchiveSuggestions + action.payload.deltaArchive, totalCurrentSuggestions: state.totalCurrentSuggestions + action.payload.deltaCurrent });
        case SuggestionActionTypes.SuggestionSetStatusFilter:
            return tslib_1.__assign({}, state, { currentSuggestionStatusFilter: action.payload.suggestionStatus
                    ? [action.payload.suggestionStatus]
                    : [] });
        case SuggestionActionTypes.SuggestionSetAssignmentFilter:
            return tslib_1.__assign({}, state, { assignmentFilter: action.payload.assignmentFilter });
        default: {
            return state;
        }
    }
}
export var loadingCurrentSuggestion = function (state) {
    return state.loadingCurrentSuggestion;
};
export var loadingArchiveSuggestion = function (state) {
    return state.loadingArchiveSuggestion;
};
export var loadingSingleSuggestion = function (state) {
    return state.loadingSingleSuggestion;
};
export var inlineError = function (state) { return state.inlineError; };
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
export { ɵ0 };
