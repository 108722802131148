import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ApiConfiguration } from './api/api-configuration';

/**
 * Reset rootUrl in ApiConfiguration
 *
 * @param config ApiConfiguration generated by ng-swagger-gen
 */
export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = '';
  };
}

@NgModule({
  imports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApiConfiguration,
      deps: [ApiConfiguration],
      multi: true,
    },
  ],
})
export class AppLoadModule {}
