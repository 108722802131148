import { ClientComment } from '@app/api/models';
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CommentsActions, CommentsActionTypes } from './comments.action';

export interface CommentState extends EntityState<ClientComment> {
  loading: boolean;
}

export const adapter: EntityAdapter<ClientComment> = createEntityAdapter<
  ClientComment
>();

export const initialCommentsState = adapter.getInitialState({
  loading: false,
});

export function commentReducer(
  state: CommentState = initialCommentsState,
  action: CommentsActions,
): CommentState {
  switch (action.type) {
    case CommentsActionTypes.AllCommentsRequestSuccess:
      return adapter.upsertMany(action.payload.comments, {
        ...state,
        loading: false,
      });
    case CommentsActionTypes.CommentChangeRequestSuccess:
      return adapter.updateOne(action.payload.comment, state);
    case CommentsActionTypes.CommentCreateRequestSuccess:
      return adapter.addOne(action.payload.comment, { ...state });
    case CommentsActionTypes.CommentDeleteRequestSuccess:
      return adapter.updateOne(action.payload.comment, state);
    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
