import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(public auth: AuthenticationService, public router: Router) {
    auth.handleAuthentication().subscribe({
      complete: () => {
        console.log('handleAuthentication completed');
        this.navigate();
      },
      error: err => {
        console.log('Could not handle authentication.');
        console.log(err);
        this.router.navigate(['error'], {
          queryParams: {
            error: err.error,
            errorDescription: err.errorDescription,
          },
          replaceUrl: true,
        });
      },
    });
  }

  ngOnInit() {}

  /**
   * Navigates to:
   * 1. To the main '/' view if there is not redirectUri saved in localstorage
   * 2. To the saved url in localstorage
   */
  private navigate(): void {
    const redirectUrl = localStorage.getItem('redirectUri');
    redirectUrl
      ? this.router.navigate([redirectUrl], { replaceUrl: true })
      : this.router.navigate([''], { replaceUrl: true });
  }
}
