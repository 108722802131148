/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/loader2/loader2.component.ngfactory";
import * as i3 from "../shared/loader2/loader2.component";
import * as i4 from "@angular/common";
import * as i5 from "./login.component";
import * as i6 from "@angular/router";
import * as i7 from "../core/authentication/authentication.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader2", [["isLoading", "true"]], null, null, null, i2.View_Loader2Component_0, i2.RenderType_Loader2Component)), i1.ɵdid(1, 114688, null, 0, i3.Loader2Component, [], { isLoading: [0, "isLoading"] }, null)], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  You are already logged in\n"]))], null, null); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoggedIn; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoggedIn; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoginComponent, [i6.Router, i7.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i5.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
