import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Person Controller
 */
var ParticipantsPersonService = /** @class */ (function (_super) {
    tslib_1.__extends(ParticipantsPersonService, _super);
    function ParticipantsPersonService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    ParticipantsPersonService.prototype.getPersonUsingGETResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/person", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    ParticipantsPersonService.prototype.getPersonUsingGET = function (appVariantIdentifier) {
        return this.getPersonUsingGETResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`MEDIA_ITEM_UPLOAD_FAILED`|400 BAD_REQUEST|The upload of a media item failed|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param params The `ParticipantsPersonService.UploadProfilePictureUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return Created
     */
    ParticipantsPersonService.prototype.uploadProfilePictureUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __headers.append('Content-Type', 'multipart/form-data');
        var __formData = new FormData();
        __body = __formData;
        if (params.file !== null && typeof params.file !== "undefined") {
            __formData.append('file', params.file);
        }
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/picture", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`MEDIA_ITEM_UPLOAD_FAILED`|400 BAD_REQUEST|The upload of a media item failed|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param params The `ParticipantsPersonService.UploadProfilePictureUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return Created
     */
    ParticipantsPersonService.prototype.uploadProfilePictureUsingPOST = function (params) {
        return this.uploadProfilePictureUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     */
    ParticipantsPersonService.prototype.deleteProfilePictureUsingDELETEResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/person/picture", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * | ― | ― | ― |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     */
    ParticipantsPersonService.prototype.deleteProfilePictureUsingDELETE = function (appVariantIdentifier) {
        return this.deleteProfilePictureUsingDELETEResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    ParticipantsPersonService.getPersonUsingGETPath = '/person';
    ParticipantsPersonService.uploadProfilePictureUsingPOSTPath = '/person/picture';
    ParticipantsPersonService.deleteProfilePictureUsingDELETEPath = '/person/picture';
    ParticipantsPersonService.ngInjectableDef = i0.defineInjectable({ factory: function ParticipantsPersonService_Factory() { return new ParticipantsPersonService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ParticipantsPersonService, providedIn: "root" });
    return ParticipantsPersonService;
}(__BaseService));
export { ParticipantsPersonService };
