import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellComponent } from './shell.component';
import { SuggestionStoreModule } from '@app/loesbar/suggestion-store/suggestion-store.module';
import { ChatStoreModule } from '@app/loesbar/chat-store/chat-store.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    SuggestionStoreModule,
    ChatStoreModule,
  ],
  declarations: [ShellComponent],
})
export class ShellModule {}
