/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./error-bar.component";
import * as i4 from "@ngrx/store";
var styles_ErrorBarComponent = [i0.styles];
var RenderType_ErrorBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorBarComponent, data: {} });
export { RenderType_ErrorBarComponent as RenderType_ErrorBarComponent };
function View_ErrorBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bg-secondary topErrorMessage"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n  ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkStatus; _ck(_v, 1, 0, currVal_0); }); }
export function View_ErrorBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.networkStatus != _co.NetworkStatus.NO_ERROR); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-bar", [], null, null, null, View_ErrorBarComponent_0, RenderType_ErrorBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.ErrorBarComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorBarComponentNgFactory = i1.ɵccf("app-error-bar", i3.ErrorBarComponent, View_ErrorBarComponent_Host_0, {}, {}, []);
export { ErrorBarComponentNgFactory as ErrorBarComponentNgFactory };
