import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["SUCCESS"] = 0] = "SUCCESS";
    MessageType[MessageType["INFO"] = 1] = "INFO";
    MessageType[MessageType["WARNING"] = 2] = "WARNING";
    MessageType[MessageType["ERROR"] = 3] = "ERROR";
})(MessageType || (MessageType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["TOAST"] = 0] = "TOAST";
    NotificationType[NotificationType["BROWSER"] = 1] = "BROWSER";
})(NotificationType || (NotificationType = {}));
var NotificationService = /** @class */ (function () {
    function NotificationService(toastr) {
        this.toastr = toastr;
    }
    NotificationService.prototype.showMessage = function (message, messageTitle, messageType, notificationType) {
        if (notificationType === NotificationType.TOAST) {
            this.showToastMessage(message, messageTitle, messageType);
        }
        else {
            alert('Only toast messages are allowed at the moment');
        }
    };
    // TODO: should this be private or do we want to have it as part of the service inteface?
    NotificationService.prototype.showToastMessage = function (message, messageTitle, messageType) {
        switch (messageType) {
            case MessageType.SUCCESS:
                this.toastr.success(message, messageTitle);
                break;
            case MessageType.WARNING:
                this.toastr.warning(message, messageTitle);
                break;
            case MessageType.ERROR:
                this.toastr.error(message, messageTitle);
                break;
            case MessageType.INFO:
            default:
                this.toastr.info(message, messageTitle, { enableHtml: true }); // Info messages can have links
        }
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ToastrService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
