import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChats from './chats.reducer';
import * as fromRoot from '@app/store/reducers';
import { ClientChatParticipant } from '@app/api/models';
import { ChatTopic } from '@app/loesbar/businesslogic/models/chat-topic';
import { ClientChatExtended } from '@app/api/models/client-chat-extended';

export const selectChatsState = createFeatureSelector<fromChats.ChatState>(
  'chats',
);

export const selectAllChats = createSelector(
  selectChatsState,
  fromChats.selectAll,
);

export const isLoadingChats = createSelector(
  selectChatsState,
  chatState => chatState.loading,
);

export const selectChatById = (chatId: string) =>
  createSelector(
    selectChatsState,
    chatState => chatState.entities[chatId],
  );

export const selectChatByRouteParam = createSelector(
  selectChatsState,
  fromRoot.getRouterState,
  (chatState, router): ClientChatExtended => {
    return router.state && chatState.entities[router.state.params.chatId];
  },
);

export const selectChatsByTopic = (topic: string) =>
  createSelector(
    selectAllChats,
    chats => chats.filter(chat => chat.chatTopic === topic),
  );

export const selectChatByParticipantId = (participanId: string) =>
  createSelector(
    selectChatsByTopic(ChatTopic.DORFFUNK_CHAT),
    (chatsState): ClientChatExtended => {
      const chats = chatsState.filter(chat => {
        const clientChatParticipan: ClientChatParticipant = chat.chatParticipants.find(
          participant => participant.person.id === participanId,
        );
        return clientChatParticipan ? chat : undefined;
      });

      // This is needed to be able to start a new chat in chat-main.component
      const fakeSelectedChat: ClientChatExtended = { id: '', chatParticipants: [] };

      // [0]: we asume that in a 1 to 1 chat never can be more than one chat with the same person
      return chats && chats.length > 0 ? chats[0] : fakeSelectedChat;
    },
  );

export const selectUnreadMessageCountByChatId = (chatId: string) =>
  createSelector(
    selectChatById(chatId),
    (chat): any => {
      return chat ? chat.unreadMessageCount : 0;
    },
  );

export const selectLastChatId = createSelector(
  selectChatsState,
  chatState => {
    return chatState.lastSelectedChatID ? chatState.lastSelectedChatID : '';
  },
);

export const selectFirstChat = createSelector(
  selectChatsByTopic(ChatTopic.DORFFUNK_CHAT),
  chats => {
    if (chats && chats.length > 0) {
      return chats[0];
    } else {
      return undefined;
    }
  },
);

export const selectLastReceivedMessageTimestamp = createSelector(
  selectChatsState,
  chatState => chatState.lastReceivedMessageTimestamp,
);

export const selectPrivateChatUnreadMessageCount = createSelector(
  selectChatsByTopic(ChatTopic.DORFFUNK_CHAT),
  chats => {
    if (chats && chats.length > 0) {
      return chats.reduce((sum, chat) => sum + chat.unreadMessageCount, 0);
    } else {
      return 0;
    }
  },
);

export const selectChatUnreadMessageCount = createSelector(
  selectAllChats,
  chats => {
    return chats && chats.length > 0
      ? chats.reduce((sum, chat) => sum + chat.unreadMessageCount, 0)
      : 0;
  },
);

export const selectInternalChatswithUnreadMessages = createSelector(
  selectAllChats,
  internalChatsWithUnreadMessages =>
    internalChatsWithUnreadMessages.filter(
      chat =>
        chat.chatTopic === ChatTopic.INTERNAL_CHAT &&
        chat.unreadMessageCount > 0,
    ),
);
