import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Suggestion Chat Controller
 */
var GrapevineSuggestionChatService = /** @class */ (function (_super) {
    tslib_1.__extends(GrapevineSuggestionChatService, _super);
    function GrapevineSuggestionChatService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * returned chats include extended information (list of chat subjects)
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    GrapevineSuggestionChatService.prototype.getOwnChatsAndSuggestionInternalWorkerChatsUsingGETResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/grapevine/suggestion/chat/own", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * returned chats include extended information (list of chat subjects)
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    GrapevineSuggestionChatService.prototype.getOwnChatsAndSuggestionInternalWorkerChatsUsingGET = function (appVariantIdentifier) {
        return this.getOwnChatsAndSuggestionInternalWorkerChatsUsingGETResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    GrapevineSuggestionChatService.getOwnChatsAndSuggestionInternalWorkerChatsUsingGETPath = '/grapevine/suggestion/chat/own';
    GrapevineSuggestionChatService.ngInjectableDef = i0.defineInjectable({ factory: function GrapevineSuggestionChatService_Factory() { return new GrapevineSuggestionChatService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: GrapevineSuggestionChatService, providedIn: "root" });
    return GrapevineSuggestionChatService;
}(__BaseService));
export { GrapevineSuggestionChatService };
