/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientSuggestionCategoryChangeConfirmation } from '../models/client-suggestion-category-change-confirmation';
import { ClientSuggestionCategoryChangeRequest } from '../models/client-suggestion-category-change-request';
import { ClientPostChangeConfirmation } from '../models/client-post-change-confirmation';
import { ClientSuggestionChangeRequest } from '../models/client-suggestion-change-request';
import { ClientPostCreateConfirmation } from '../models/client-post-create-confirmation';
import { ClientSuggestionCreateRequest } from '../models/client-suggestion-create-request';
import { ClientSuggestionStatusChangeConfirmation } from '../models/client-suggestion-status-change-confirmation';
import { ClientSuggestionStatusChangeRequest } from '../models/client-suggestion-status-change-request';
import { ClientSuggestionWithdrawConfirmation } from '../models/client-suggestion-withdraw-confirmation';
import { ClientSuggestionWithdrawRequest } from '../models/client-suggestion-withdraw-request';
import { ClientSuggestionWorkerAddConfirmation } from '../models/client-suggestion-worker-add-confirmation';
import { ClientSuggestionWorkerAddRequest } from '../models/client-suggestion-worker-add-request';
import { ClientSuggestionWorkerRemoveConfirmation } from '../models/client-suggestion-worker-remove-confirmation';
import { ClientSuggestionWorkerRemoveRequest } from '../models/client-suggestion-worker-remove-request';

/**
 * Suggestion Event Controller
 */
@Injectable({
  providedIn: 'root',
})
class GrapevineSuggestionEventsService extends __BaseService {
  static readonly onSuggestionCategoryChangeRequestUsingPOSTPath = '/grapevine/event/suggestionCategoryChangeRequest';
  static readonly onSuggestionChangeRequestUsingPOSTPath = '/grapevine/event/suggestionChangeRequest';
  static readonly onSuggestionCreateRequestUsingPOSTPath = '/grapevine/event/suggestionCreateRequest';
  static readonly onSuggestionStatusChangeRequestUsingPOSTPath = '/grapevine/event/suggestionStatusChangeRequest';
  static readonly onSuggestionWithdrawRequestUsingPOSTPath = '/grapevine/event/suggestionWithdrawRequest';
  static readonly onSuggestionWorkerAddRequestUsingPOSTPath = '/grapevine/event/suggestionWorkerAddRequest';
  static readonly onSuggestionWorkerRemoveRequestUsingPOSTPath = '/grapevine/event/suggestionWorkerRemoveRequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Changes the category of a suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionCategoryChangeRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientSuggestionCategoryChangeConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionCategoryChangeRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionCategoryChangeConfirmation>;
      })
    );
  }
  /**
   * Changes the category of a suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionCategoryChangeRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionCategoryChangeRequestUsingPOSTParams): __Observable<ClientSuggestionCategoryChangeConfirmation> {
    return this.onSuggestionCategoryChangeRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionCategoryChangeConfirmation)
    );
  }

  /**
   * Updates an existing Suggestion. Text, images and category can be updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Only the creator can update the suggestion.
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionChangeRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientPostChangeConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionChangeRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientPostChangeConfirmation>;
      })
    );
  }
  /**
   * Updates an existing Suggestion. Text, images and category can be updated.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Only the creator can update the suggestion.
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionChangeRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionChangeRequestUsingPOSTParams): __Observable<ClientPostChangeConfirmation> {
    return this.onSuggestionChangeRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientPostChangeConfirmation)
    );
  }

  /**
   * Creates a new Suggestion. Only enabled if the feature SuggestionCreationFeature is enabled for the requesting app variant and user
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|The feature is not enabled for this user|
   * |`POST_COULD_NOT_BE_CREATED`|400 BAD_REQUEST|The post could not be created because the creator has no home area set|
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionCreateRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientPostCreateConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionCreateRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientPostCreateConfirmation>;
      })
    );
  }
  /**
   * Creates a new Suggestion. Only enabled if the feature SuggestionCreationFeature is enabled for the requesting app variant and user
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|The feature is not enabled for this user|
   * |`POST_COULD_NOT_BE_CREATED`|400 BAD_REQUEST|The post could not be created because the creator has no home area set|
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionCreateRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionCreateRequestUsingPOSTParams): __Observable<ClientPostCreateConfirmation> {
    return this.onSuggestionCreateRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientPostCreateConfirmation)
    );
  }

  /**
   * Changes the status of a suggestion. Currently these transitions are possible:
   * OPEN -> IN_PROGRESS, ON_HOLD, DONE
   * IN_PROGRESS -> ON_HOLD, DONE
   * ON_HOLD -> IN_PROGRESS, DONE
   * DONE -> IN_PROGRESS
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_STATUS_CHANGE_INVALID`|400 BAD_REQUEST|The suggestion status change is invalid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionStatusChangeRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientSuggestionStatusChangeConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionStatusChangeRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionStatusChangeConfirmation>;
      })
    );
  }
  /**
   * Changes the status of a suggestion. Currently these transitions are possible:
   * OPEN -> IN_PROGRESS, ON_HOLD, DONE
   * IN_PROGRESS -> ON_HOLD, DONE
   * ON_HOLD -> IN_PROGRESS, DONE
   * DONE -> IN_PROGRESS
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_STATUS_CHANGE_INVALID`|400 BAD_REQUEST|The suggestion status change is invalid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionStatusChangeRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionStatusChangeRequestUsingPOSTParams): __Observable<ClientSuggestionStatusChangeConfirmation> {
    return this.onSuggestionStatusChangeRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionStatusChangeConfirmation)
    );
  }

  /**
   * Withdraws an existing Suggestion. It can still be read by SuggestionFirstResponder and SuggestionWorker afterwards.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Only the creator can withdraw the suggestion.
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWithdrawRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientSuggestionWithdrawConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionWithdrawRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionWithdrawConfirmation>;
      })
    );
  }
  /**
   * Withdraws an existing Suggestion. It can still be read by SuggestionFirstResponder and SuggestionWorker afterwards.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Only the creator can withdraw the suggestion.
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`POST_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the post can not be done by this user|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWithdrawRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionWithdrawRequestUsingPOSTParams): __Observable<ClientSuggestionWithdrawConfirmation> {
    return this.onSuggestionWithdrawRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionWithdrawConfirmation)
    );
  }

  /**
   * Adds a worker to a suggestion. The worker has to have the role SuggestionWorker for the tenant of the suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_WORKER_ALREADY_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is already working on the suggestion|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWorkerAddRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientSuggestionWorkerAddConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionWorkerAddRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionWorkerAddConfirmation>;
      })
    );
  }
  /**
   * Adds a worker to a suggestion. The worker has to have the role SuggestionWorker for the tenant of the suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_WORKER_ALREADY_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is already working on the suggestion|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWorkerAddRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionWorkerAddRequestUsingPOSTParams): __Observable<ClientSuggestionWorkerAddConfirmation> {
    return this.onSuggestionWorkerAddRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionWorkerAddConfirmation)
    );
  }

  /**
   * Removes the current user as worker from a suggestion. The worker has to be an active worker of the suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_WORKER_NOT_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is not on the suggestion|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWorkerRemoveRequestUsingPOSTResponse(params: GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientSuggestionWorkerRemoveConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/event/suggestionWorkerRemoveRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientSuggestionWorkerRemoveConfirmation>;
      })
    );
  }
  /**
   * Removes the current user as worker from a suggestion. The worker has to be an active worker of the suggestion.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`POST_INVALID`|400 BAD_REQUEST|The provided post is not valid|
   * |`SUGGESTION_WORKER_NOT_WORKING_ON_SUGGESTION`|400 BAD_REQUEST|The worker is not on the suggestion|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onSuggestionWorkerRemoveRequestUsingPOST(params: GrapevineSuggestionEventsService.OnSuggestionWorkerRemoveRequestUsingPOSTParams): __Observable<ClientSuggestionWorkerRemoveConfirmation> {
    return this.onSuggestionWorkerRemoveRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientSuggestionWorkerRemoveConfirmation)
    );
  }
}

module GrapevineSuggestionEventsService {

  /**
   * Parameters for onSuggestionCategoryChangeRequestUsingPOST
   */
  export interface OnSuggestionCategoryChangeRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionCategoryChangeRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onSuggestionChangeRequestUsingPOST
   */
  export interface OnSuggestionChangeRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionChangeRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onSuggestionCreateRequestUsingPOST
   */
  export interface OnSuggestionCreateRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionCreateRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }

  /**
   * Parameters for onSuggestionStatusChangeRequestUsingPOST
   */
  export interface OnSuggestionStatusChangeRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionStatusChangeRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onSuggestionWithdrawRequestUsingPOST
   */
  export interface OnSuggestionWithdrawRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionWithdrawRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onSuggestionWorkerAddRequestUsingPOST
   */
  export interface OnSuggestionWorkerAddRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionWorkerAddRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for onSuggestionWorkerRemoveRequestUsingPOST
   */
  export interface OnSuggestionWorkerRemoveRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientSuggestionWorkerRemoveRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { GrapevineSuggestionEventsService }
