import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ClientSuggestionExtended,
  ClientSuggestionCategory,
} from '@app/api/models';
import { SuggestionActionTypes, SuggestionActions } from './suggestions.action';
import { HttpErrorResponse } from '@angular/common/http';
import { SuggestionStatus } from '@app/loesbar/businesslogic/models/suggestion-status';
import {
  AssignmentFilter,
  AssignmentFilterType,
} from '@app/loesbar/businesslogic/models/assignment-filter';

export interface SuggestionState extends EntityState<ClientSuggestionExtended> {
  loadingCurrentSuggestion: boolean;
  loadingArchiveSuggestion: boolean;
  allCurrentSuggestionsLoaded: boolean;
  totalCurrentSuggestions: number;
  allArchiveSuggestionsLoaded: boolean;
  totalArchiveSuggestions: number;
  selectedId: string | null;
  categories: ClientSuggestionCategory[];
  lastCurrentSelectedSuggestionID: string;
  lastArchivedSelectedSuggestionID: string;
  loadingSingleSuggestion: boolean;
  inlineError: HttpErrorResponse;
  currentSuggestionStatusFilter: SuggestionStatus[];
  assignmentFilter: AssignmentFilter;
  activeSuggestionId: string;
}

const sortByLastActivity = (
  a: ClientSuggestionExtended,
  b: ClientSuggestionExtended,
): number => {
  return a.lastActivity > b.lastActivity ? -1 : 1;
};

export const adapter: EntityAdapter<
  ClientSuggestionExtended
> = createEntityAdapter<ClientSuggestionExtended>({
  sortComparer: sortByLastActivity,
});

export const initialSuggestionState: SuggestionState = adapter.getInitialState({
  loadingCurrentSuggestion: false,
  loadingArchiveSuggestion: false,
  allCurrentSuggestionsLoaded: false,
  totalCurrentSuggestions: 0,
  allArchiveSuggestionsLoaded: false,
  totalArchiveSuggestions: 0,
  selectedId: null,
  categories: [],
  lastCurrentSelectedSuggestionID: null,
  lastArchivedSelectedSuggestionID: null,
  loadingSingleSuggestion: true,
  inlineError: null,
  currentSuggestionStatusFilter: [],
  assignmentFilter: { type: AssignmentFilterType.ALL, containedWorkerId: null },
  activeSuggestionId: '', // Every time we update this
});

export function suggestionReducer(
  state: SuggestionState = initialSuggestionState,
  action: SuggestionActions,
): SuggestionState {
  switch (action.type) {
    case SuggestionActionTypes.AllCurrentSuggestionRequest:
      return { ...state, loadingCurrentSuggestion: true };
    case SuggestionActionTypes.AllCurrentSuggestionsLoadedFail:
      return { ...state, loadingCurrentSuggestion: false };
    case SuggestionActionTypes.AllCurrentSuggestionsLoadedSuccess:
      return adapter.addMany(action.payload.suggestions, {
        ...state,
        allCurrentSuggestionsLoaded: true,
        loadingCurrentSuggestion: false,
        totalCurrentSuggestions: action.payload.total,
      });
    case SuggestionActionTypes.AllArchiveSuggestionsRequested:
      return { ...state, loadingArchiveSuggestion: true };
    case SuggestionActionTypes.AllArchiveSuggestionsLoadedFail:
      return { ...state, loadingArchiveSuggestion: false };
    case SuggestionActionTypes.AllArchiveSuggestionsLoadedSuccess:
      return adapter.addMany(action.payload.suggestions, {
        ...state,
        allArchiveSuggestionsLoaded: true,
        loadingArchiveSuggestion: false,
        totalArchiveSuggestions: action.payload.total,
      });
    case SuggestionActionTypes.LatestSuggestionsRequest:
      return { ...state };
    case SuggestionActionTypes.LatestSuggestionsRequestFail:
      return { ...state };
    case SuggestionActionTypes.LatestSuggestionsRequestSuccess:
      return adapter.upsertMany(action.payload.suggestions, {
        ...state,
      });
    case SuggestionActionTypes.SuggestionStatusChangeRequestSuccess:
    case SuggestionActionTypes.SuggestionWorkerAddRequestSuccess:
    case SuggestionActionTypes.SuggestionWorkerRemoveRequestSuccess:
      return adapter.updateOne(action.payload.suggestion, {
        ...state,
        activeSuggestion: action.payload.suggestion.changes,
        assignmentFilter: {
          type: AssignmentFilterType.ALL,
          containedWorkerId: null,
        },
        currentSuggestionStatusFilter: [],
      });
    case SuggestionActionTypes.SuggestionCategoryChangeRequestSuccess:
    case SuggestionActionTypes.UpdateSuggestionRequestSuccess:
      return adapter.updateOne(action.payload.suggestion, {
        ...state,
      });
    case SuggestionActionTypes.GetExtendedSuggestioRequest:
      return { ...state, loadingSingleSuggestion: true };
    case SuggestionActionTypes.GetExtendedSuggestioRequestSuccess:
      return adapter.addOne(action.payload.suggestion, {
        ...state,
        activeSuggestionId: action.payload.suggestion.id,
        loadingSingleSuggestion: false,
      });
    case SuggestionActionTypes.GetExtendedSuggestionSilentSuccess:
      return adapter.addOne(action.payload.suggestion, {
        ...state,
        loadingSingleSuggestion: false,
      });
    case SuggestionActionTypes.SetActiveSuggestion:
      return { ...state, activeSuggestionId: action.payload.suggestionId };
    case SuggestionActionTypes.GetExtendedSuggestioRequestFail:
      return { ...state, loadingSingleSuggestion: false };
    case SuggestionActionTypes.GetSuggestionCategoriesRequestSuccess:
      return { ...state, categories: action.payload.categories };
    case SuggestionActionTypes.UpdateLastCurrentSelectedSuggestionRequest:
      return {
        ...state,
        lastCurrentSelectedSuggestionID: action.payload.suggestionId,
        inlineError: null,
      };
    case SuggestionActionTypes.UpdateLastArchivedSelectedSuggestionRequest:
      return {
        ...state,
        lastArchivedSelectedSuggestionID: action.payload.suggestionId,
        inlineError: null,
      };
    case SuggestionActionTypes.ShowSuggestionInlineError:
      return {
        ...state,
        loadingSingleSuggestion: false,
        inlineError: action.payload.error,
      };
    case SuggestionActionTypes.SuggestionUpdateCounters:
      return {
        ...state,
        totalArchiveSuggestions:
          state.totalArchiveSuggestions + action.payload.deltaArchive,
        totalCurrentSuggestions:
          state.totalCurrentSuggestions + action.payload.deltaCurrent,
      };
    case SuggestionActionTypes.SuggestionSetStatusFilter:
      return {
        ...state,
        currentSuggestionStatusFilter: action.payload.suggestionStatus
          ? [action.payload.suggestionStatus]
          : [],
      };
    case SuggestionActionTypes.SuggestionSetAssignmentFilter:
      return {
        ...state,
        assignmentFilter: action.payload.assignmentFilter,
      };

    default: {
      return state;
    }
  }
}

export const loadingCurrentSuggestion = (state: SuggestionState) =>
  state.loadingCurrentSuggestion;
export const loadingArchiveSuggestion = (state: SuggestionState) =>
  state.loadingArchiveSuggestion;
export const loadingSingleSuggestion = (state: SuggestionState) =>
  state.loadingSingleSuggestion;
export const inlineError = (state: SuggestionState) => state.inlineError;
export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
