import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LoesbarRoutingModule } from './loesbar-routing.module';
import { MomentModule } from 'ngx-moment';
import { SuggestionStoreModule } from './suggestion-store/suggestion-store.module';
import { ChatStoreModule } from './chat-store/chat-store.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    NgbModule,
    MomentModule,
    FormsModule,
    // ChatModule, // this is lazy loaded by router
    // SuggestionModule, // this is lazy loaded by router
    LoesbarRoutingModule,
    SuggestionStoreModule,
    ChatStoreModule,
  ],
  declarations: [],
  exports: [],
  providers: [],
})
export class LoesbarModule {}
