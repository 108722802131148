/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Data Privacy Controller
 */
@Injectable({
  providedIn: 'root',
})
class SharedDataprivacyService extends __BaseService {
  static readonly deletePersonUsingDELETE1Path = '/dataprivacy/myPersonAndAllMyData';
  static readonly collectUserDataAndSendMailUsingPOSTPath = '/dataprivacy/report/mail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  deletePersonUsingDELETE1Response(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dataprivacy/myPersonAndAllMyData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  deletePersonUsingDELETE1(appVariantIdentifier?: string): __Observable<string> {
    return this.deletePersonUsingDELETE1Response(appVariantIdentifier).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * The report is sent to the email the given person registered.
   *
   * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  collectUserDataAndSendMailUsingPOSTResponse(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dataprivacy/report/mail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * The report is sent to the email the given person registered.
   *
   * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  collectUserDataAndSendMailUsingPOST(appVariantIdentifier?: string): __Observable<string> {
    return this.collectUserDataAndSendMailUsingPOSTResponse(appVariantIdentifier).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module SharedDataprivacyService {
}

export { SharedDataprivacyService }
