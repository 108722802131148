import { BurgerMenuActionTypes, BurgerMenuActions } from './userdata.actions';

export interface State {
  userName?: string;
}

export const initialState: State = {
};

export function burgerMenuReducer(
  state: State = initialState,
  action: BurgerMenuActions,
): State {
  switch (action.type) {
    default:
      return state;
  }
}
