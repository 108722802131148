import { of, timer, bindNodeCallback, throwError } from 'rxjs';
import * as auth0 from 'auth0-js';
import { environment } from '@env/environment';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { ParticipantsPersonService } from '@app/api/services';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../../api/services/participants-person.service";
import * as i2 from "@angular/common";
var AuthResultLocalStore = /** @class */ (function () {
    function AuthResultLocalStore() {
        this.isLoggedInKey = 'isLoggedIn';
        this.authResult = null;
    }
    Object.defineProperty(AuthResultLocalStore.prototype, "authToken", {
        get: function () {
            if (!this.isLoggedIn) {
                return null;
            }
            return this.authResult;
        },
        set: function (authResult) {
            if (authResult) {
                this.authResult = authResult;
                localStorage.setItem(this.isLoggedInKey, 'true');
            }
            else {
                this.authResult = null;
                localStorage.setItem(this.isLoggedInKey, 'false');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthResultLocalStore.prototype, "isLoggedIn", {
        get: function () {
            return JSON.parse(localStorage.getItem(this.isLoggedInKey));
        },
        enumerable: true,
        configurable: true
    });
    return AuthResultLocalStore;
}());
export { AuthResultLocalStore };
var log = new Logger('AuthenticationService');
/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(participantsPersonService, authResultStore, location) {
        this.participantsPersonService = participantsPersonService;
        this.authResultStore = authResultStore;
        this.location = location;
        this.auth0 = new auth0.WebAuth(environment.auth0Config);
        this.checkSession$ = bindNodeCallback(this.auth0.checkSession.bind(this.auth0));
        this.parseHash$ = bindNodeCallback(this.auth0.parseHash.bind(this.auth0));
    }
    AuthenticationService.prototype.login = function () {
        this.logout();
        this.saveRedirectUrl(this.location.path());
        this.auth0.authorize({ mode: 'login' });
    };
    AuthenticationService.prototype.handleAuthentication = function () {
        var _this = this;
        log.debug('handleAuthentication() called');
        return this.parseHash$().pipe(mergeMap(function (decodedHash) {
            window.location.hash = '';
            return _this.localLogin(decodedHash);
        }), mergeMap(function () {
            return _this.loadPersonFromBackend();
        }));
    };
    AuthenticationService.prototype.loadPersonFromBackend = function () {
        // FIXME: this is a bad workaround until we have a proper person store!
        log.debug('Loading person from backend');
        return this.participantsPersonService.getPersonUsingGET().pipe(map(function (person) {
            log.debug('Got person from backend');
            var oldPerson = JSON.parse(sessionStorage.getItem('currentUser'));
            if (oldPerson && oldPerson.id !== person.id) {
                throw new Error('Person ID missmatch! Abort login');
            }
            sessionStorage.setItem('currentUser', JSON.stringify(person));
            return true;
        }));
    };
    AuthenticationService.prototype.updatePerson = function (person) {
        sessionStorage.setItem('currentUser', JSON.stringify(person));
    };
    /**
     * Logs out the user and clear credentials.
     * @return {Observable<boolean>} True if the user was logged out successfully.
     */
    AuthenticationService.prototype.logout = function () {
        // Customize credentials invalidation here
        this.setAuthToken();
        this.unscheduleRenewal();
        localStorage.clear();
        sessionStorage.clear();
        this.auth0.logout({
            returnTo: environment.loggedOutUrl,
            clientID: environment.auth0Config.clientID,
        });
        return of(true);
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        return this.authResultStore.isLoggedIn;
    };
    AuthenticationService.prototype.hasValidToken = function () {
        return (this.authResultStore.isLoggedIn &&
            this.authResultStore.authToken &&
            this.authResultStore.authToken.expiresAt > Date.now());
    };
    AuthenticationService.prototype.renewTokens = function () {
        var _this = this;
        if (!this.isLoggedIn()) {
            return throwError('No token available. Do not allow renew.');
        }
        log.debug('Renew tokens...');
        return this.checkSession$({}).pipe(mergeMap(function (result) {
            return _this.localLogin(result);
        }), mergeMap(function () {
            return _this.loadPersonFromBackend();
        }), catchError(function (err) {
            // FIXME: is this error handling needed here?
            // Normally, everyone who calls renewTokens should implement error handling
            log.error(err);
            _this.login(); // Try normal login on error
            return of(false);
        }));
    };
    AuthenticationService.prototype.scheduleRenewal = function () {
        var _this = this;
        log.debug('scheduleRenewal');
        if (!this.hasValidToken()) {
            return;
        }
        this.unscheduleRenewal();
        var expiresAt = this.authResultStore.authToken.expiresAt;
        var expiresIn$ = of(expiresAt).pipe(mergeMap(function (expiresAt) {
            var now = Date.now();
            var refreshAt = expiresAt - 1000 * 30; // Refresh 30 seconds before expiry
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, refreshAt - now));
        }));
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(function () {
            log.debug('Refreshing auth token before expiry');
            _this.renewTokens().subscribe();
        });
    };
    AuthenticationService.prototype.unscheduleRenewal = function () {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    };
    AuthenticationService.prototype.localLogin = function (tokenPayload) {
        // Workaround DD-4935: avoid local login id tokenPayload is undefined
        if (!tokenPayload) {
            return of(false);
        }
        // Set the time that the access token will expire at
        var expiresAt = tokenPayload.expiresIn * 1000 + Date.now();
        var authResult = {
            accessToken: tokenPayload.accessToken,
            idToken: tokenPayload.idToken,
            expiresAt: expiresAt,
        };
        this.setAuthToken(authResult);
        this.scheduleRenewal();
        log.debug('Processed token payload from auth0.');
        return of(true);
    };
    /**
     * Save the given post url to redirect after login.
     */
    AuthenticationService.prototype.saveRedirectUrl = function (postUrl) {
        var redirectUrl = localStorage.getItem('redirectUri');
        if (redirectUrl == null &&
            postUrl != null &&
            (postUrl.startsWith('/vorschlag') || postUrl.startsWith('/chat'))) {
            localStorage.setItem('redirectUri', postUrl);
        }
    };
    Object.defineProperty(AuthenticationService.prototype, "authToken", {
        /**
         * Gets the user credentials.
         * @return {Credentials} The user credentials or null if the user is not authenticated.
         */
        get: function () {
            return this.authResultStore.authToken;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.setAuthToken = function (authResult) {
        this.authResultStore.authToken = authResult;
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.ParticipantsPersonService), i0.inject(AuthResultLocalStore), i0.inject(i2.Location)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
