import { Action } from '@ngrx/store';


export enum BurgerMenuActionTypes {
  GetSavedUserDataRequest = '[Burger Menu Component] Request Saved User Data',
  GetSavedUserDataRequestSuccess = '[DataPrivacy API] Request Saved User Data Failed Success',
  GetSavedUserDataRequestFail = '[DataPrivacy API] Request Saved User Data Fail',
  UploadProfilePictureRequest = '[Burger Menu Component] Upload Profile Picture',
  UploadProfilePictureRequestSuccess = '[ParticipantPersonService API] Upload Profile Picture Success',
  UploadProfilePictureRequestFail = '[ParticipantPersonService API] Upload Profile Picture Fail',
  UpdateProfileNameRequest = '[ParticipantsPersonEventsService] UpdateProfileNameRequest',
  UpdateProfileNameRequestSuccess = '[ParticipantsPersonEventsService] UpdateProfileNameRequestSuccess',
  UpdateProfileNameRequestFail = '[ParticipantsPersonEventsService] UpdateProfileNameRequestFail',
  DeleteUserRequest = '[DataPrivacy API] ',
  DeleteUserRequestSuccess = '[DataPrivacy API] DeleteUserRequestSuccess',
  DeleteUserRequestFail = '[DataPrivacy API] DeleteUserRequestFail',
}

export class GetSavedUserDataRequest implements Action {
  readonly type = BurgerMenuActionTypes.GetSavedUserDataRequest;
  constructor(public payload: any) {}
}

export class GetSavedUserDataRequestSuccess implements Action {
  readonly type = BurgerMenuActionTypes.GetSavedUserDataRequestSuccess;
  constructor(public payload: any) {}
}

export class GetSavedUserDataRequestFail implements Action {
  readonly type = BurgerMenuActionTypes.GetSavedUserDataRequestFail;
  constructor(public payload: any) {}
}

export class UploadProfilePictureRequest implements Action {
  readonly type = BurgerMenuActionTypes.UploadProfilePictureRequest;
  constructor(public payload: {file: any}) {}
}

export class UploadProfilePictureRequestSuccess implements Action {
  readonly type = BurgerMenuActionTypes.UploadProfilePictureRequestSuccess;
  constructor(public payload: any) {}
}

export class UploadProfilePictureRequestFail implements Action {
  readonly type = BurgerMenuActionTypes.UploadProfilePictureRequestFail;
  constructor(public payload: any) {}
}

export class UpdateProfileNameRequest implements Action {
  readonly type = BurgerMenuActionTypes.UpdateProfileNameRequest;
  constructor(public payload: any) {}
}

export class UpdateProfileNameRequestFail implements Action {
  readonly type = BurgerMenuActionTypes.UpdateProfileNameRequestFail;
  constructor(public payload: any) {}
}

export class UpdateProfileNameRequestSuccess implements Action {
  readonly type = BurgerMenuActionTypes.UpdateProfileNameRequestSuccess;
  constructor(public payload: any) {}
}

export class DeleteUserRequest implements Action {
  readonly type = BurgerMenuActionTypes.DeleteUserRequest;
  constructor() {}
}

export class DeleteUserRequestSuccess implements Action {
  readonly type = BurgerMenuActionTypes.DeleteUserRequestSuccess;
  constructor(public payload: any) {}
}

export class DeleteUserRequestFail implements Action {
  readonly type = BurgerMenuActionTypes.DeleteUserRequestFail;
  constructor(public payload: any) {}
}


export type BurgerMenuActions =
  | GetSavedUserDataRequest
  | GetSavedUserDataRequestSuccess
  | GetSavedUserDataRequestFail
  | UploadProfilePictureRequest
  | UploadProfilePictureRequestFail
  | UploadProfilePictureRequestSuccess
  | UpdateProfileNameRequest
  | UpdateProfileNameRequestFail
  | UpdateProfileNameRequestSuccess
  | DeleteUserRequest
  | DeleteUserRequestSuccess
  | DeleteUserRequestFail;
