import { Action } from '@ngrx/store';
import { ClientChatStartWithSuggestionWorkerRequest } from '@app/api/models';
import { Update } from '@ngrx/entity';
import { ClientChatExtended } from '@app/api/models/client-chat-extended';
import { GrapevineChatEventsService } from '@app/api/services';

export enum ChatsActionTypes {
  AllChatsRequest = '[Internal Chat] Chats Loaded Request',
  AllChatsRequestSuccess = '[DVP API] Chats Loaded Success',
  AllChatsRequestFail = '[DVP API] Chats Loaded Fail',
  PollingAllChatsRequest = '[Chat Polling] Chats Loaded Request',
  UpdateLastMessageRequest = '[Message Polling Service] Update Message Request',
  SetReadChatRequest = '[Message Polling Service] Set Read Chat Request',
  SetReadChatRequestSuccess = '[DVP API]  Set Read Chat Success',
  SetReadChatRequestFail = '[DVP API]  Set Read Chat Fail',
  ChatStartOnCommentRequest = '[Chat View Component]  Start Chat On Comment Rqeuest',
  ChatStartOnCommentRequestSuccess = '[Chat View Component]  Start Chat On Comment Request Success',
  ChatStartOnCommentRequestFail = '[Chat View Component]  Start Chat On Comment Request Fail',
  ChatStartOnPostRequest = '[Suggestion Summary Component]  Start Chat On Post Request',
  ChatStartOnPostRequestSuccess = '[Suggestion Summary  Component]  Start Chat On Post Request Success',
  ChatStartOnPostRequestFail = '[Suggestion Summary Component]  Start Chat On Post Request Fail',
  ChatStartWithSuggestionWorkerRequest = '[Problem Solver Component]  Start Chat On S.W. Request',
  ChatStartWithSuggestionWorkerRequestSuccess = '[Problem Solver Component]  Start Chat On S.W. Request Success',
  ChatStartWithSuggestionWorkerRequestFail = '[Problem Solver Component]  Start Chat On S.W. Request Fail',
  UpdateLastSelectedChatRequest = '[Chat List] Update Last Selected Chat Request',
  UpdateLastReceivedMessageTimestampRequest = '[Chats Effects] Update Last Received Message Timestamp Request',
  UpdateLastReceivedMessageTimestampRequestMP = '[Message Polling] Update Last Received Message Timestamp Request',
  UpdateLastReceivedMessageTimestampRequestME = '[Message Effects] Update Last Received Message Timestamp Request',
}
export class AllChatsRequest implements Action {
  readonly type = ChatsActionTypes.AllChatsRequest;
}

export class AllChatsRequestSuccess implements Action {
  readonly type = ChatsActionTypes.AllChatsRequestSuccess;
  constructor(public payload: { chats: ClientChatExtended[] }) {}
}

export class AllChatsRequestFail implements Action {
  readonly type = ChatsActionTypes.AllChatsRequestFail;
  constructor(public payload: any) {}
}

export class PollingAllChatsRequest implements Action {
  readonly type = ChatsActionTypes.PollingAllChatsRequest;
}

export class UpdateLastMessageRequest implements Action {
  readonly type = ChatsActionTypes.UpdateLastMessageRequest;
  constructor(public payload: { chat: Update<ClientChatExtended> }) {}
}

export class ChatReadRequest implements Action {
  readonly type = ChatsActionTypes.SetReadChatRequest;
  constructor(public payload: { chatID: string; readTime: number }) {}
}

export class ChatReadRequestSuccess implements Action {
  readonly type = ChatsActionTypes.SetReadChatRequestSuccess;
  constructor(public payload: { chat: Update<ClientChatExtended> }) {}
}

export class ChatReadRequestFail implements Action {
  readonly type = ChatsActionTypes.SetReadChatRequestFail;
  constructor(public payload: any) {}
}

export class ChatStartOnCommentRequest implements Action {
  readonly type = ChatsActionTypes.ChatStartOnCommentRequest;
  constructor(
    public payload: {
      clientChatStartOnCommentRequest: GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams;
    },
  ) {}
}

export class ChatStartOnCommentRequestSuccess implements Action {
  readonly type = ChatsActionTypes.ChatStartOnCommentRequestSuccess;
  constructor(public payload: { chat: ClientChatExtended }) {}
}

export class ChatStartOnCommentRequestFail implements Action {
  readonly type = ChatsActionTypes.ChatStartOnCommentRequestFail;
  constructor(public payload: any) {}
}

export class ChatStartOnPostRequest implements Action {
  readonly type = ChatsActionTypes.ChatStartOnPostRequest;
  constructor(
    public payload: {
      clientChatStartOnPostRequest: GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams;
    },
  ) {}
}

export class ChatStartOnPostRequestSuccess implements Action {
  readonly type = ChatsActionTypes.ChatStartOnPostRequestSuccess;
  constructor(public payload: { chat: ClientChatExtended }) {}
}

export class ChatStartOnPostRequestFail implements Action {
  readonly type = ChatsActionTypes.ChatStartOnPostRequestFail;
  constructor(public payload: any) {}
}

export class ChatStartWithSuggestionWorkerRequest implements Action {
  readonly type = ChatsActionTypes.ChatStartWithSuggestionWorkerRequest;
  constructor(
    public payload: {
      clientChatStartWithSuggestionWorkerRequest: ClientChatStartWithSuggestionWorkerRequest;
    },
  ) {}
}

export class ChatStartWithSuggestionWorkerRequestSuccess implements Action {
  readonly type = ChatsActionTypes.ChatStartWithSuggestionWorkerRequestSuccess;
  constructor(public payload: { chat: ClientChatExtended }) {}
}

export class ChatStartWithSuggestionWorkerRequestFail implements Action {
  readonly type = ChatsActionTypes.ChatStartWithSuggestionWorkerRequestFail;
  constructor(public payload: any) {}
}

export class UpdateLastSelectedChatRequest implements Action {
  readonly type = ChatsActionTypes.UpdateLastSelectedChatRequest;
  constructor(public payload: { chatId: string }) {}
}
export class UpdateLastReceivedMessageTimestampRequest implements Action {
  readonly type = ChatsActionTypes.UpdateLastReceivedMessageTimestampRequest;
  constructor(public payload: { lastReceivedMessageTimestamp: number }) {}
}

export class UpdateLastReceivedMessageTimestampRequestMP implements Action {
  readonly type = ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestMP;
  constructor(public payload: { lastReceivedMessageTimestamp: number }) {}
}

export class UpdateLastReceivedMessageTimestampRequestME implements Action {
  readonly type = ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestME;
  constructor(public payload: { lastReceivedMessageTimestamp: number }) {}
}

export type ChatsActions =
  | AllChatsRequest
  | AllChatsRequestSuccess
  | AllChatsRequestFail
  | PollingAllChatsRequest
  | UpdateLastMessageRequest
  | ChatReadRequest
  | ChatReadRequestSuccess
  | ChatReadRequestFail
  | ChatStartOnCommentRequest
  | ChatStartOnCommentRequestSuccess
  | ChatStartOnCommentRequestFail
  | ChatStartOnPostRequest
  | ChatStartOnPostRequestSuccess
  | ChatStartOnPostRequestFail
  | ChatStartWithSuggestionWorkerRequest
  | ChatStartWithSuggestionWorkerRequestSuccess
  | ChatStartWithSuggestionWorkerRequestFail
  | UpdateLastSelectedChatRequest
  | UpdateLastReceivedMessageTimestampRequest
  | UpdateLastReceivedMessageTimestampRequestMP
  | UpdateLastReceivedMessageTimestampRequestME;
