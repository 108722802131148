import { Action } from '@ngrx/store';
import {
  ClientComment,
  ClientCommentChangeRequest,
  ClientCommentCreateRequest,
} from '@app/api/models';
import { Update } from '@ngrx/entity';

export enum CommentsActionTypes {
  AllCommentsRequest = '[Suggestion List Component] Comments Requested',
  AllCommentsRequestSuccess = '[DVP API] Comments Loaded Success',
  AllCommentsRequestFail = '[DVP API] Comments Loaded Fail',
  CommentChangeRequest = '[Public Comments Component] Comment Change Requested',
  CommentChangeRequestSuccess = '[DVP API] Comment Change Success',
  CommentChangeRequestFail = '[DVP API] Comment Change Fail',
  CommentCreateRequest = '[Public Comments Component] Comment Create Requested',
  CommentCreateRequestSuccess = '[DVP API] Comment Create Success',
  CommentCreateRequestFail = '[DVP API] Comment Create Fail',
  CommentDeleteRequest = '[Public Comments Component] Comment Delete Requested',
  CommentDeleteRequestSuccess = '[DVP API] Comment Delete Success',
  CommentDeleteRequestFail = '[DVP API] Comment Delete Fail',
}

export class AllCommentsRequest implements Action {
  readonly type = CommentsActionTypes.AllCommentsRequest;
  constructor(public payload: { postId: string }) {}
}

export class AllCommentsRequestSuccess implements Action {
  readonly type = CommentsActionTypes.AllCommentsRequestSuccess;

  constructor(public payload: { comments: ClientComment[] }) {}
}

export class AllCommentsRequestFail implements Action {
  readonly type = CommentsActionTypes.AllCommentsRequestFail;
  constructor(public payload: any) {}
}

export class CommentChangeRequest implements Action {
  readonly type = CommentsActionTypes.CommentChangeRequest;
  constructor(public payload: { comment: ClientCommentChangeRequest }) {}
}

export class CommentChangeRequestSuccess implements Action {
  readonly type = CommentsActionTypes.CommentChangeRequestSuccess;
  constructor(public payload: { comment: Update<ClientComment> }) {}
}

export class CommentChangeRequestFail implements Action {
  readonly type = CommentsActionTypes.CommentChangeRequestFail;
  constructor(public payload: any) {}
}

export class CommentCreateRequest implements Action {
  readonly type = CommentsActionTypes.CommentCreateRequest;
  constructor(public payload: { comment: ClientCommentCreateRequest }) {}
}

export class CommentCreateRequestSuccess implements Action {
  readonly type = CommentsActionTypes.CommentCreateRequestSuccess;
  constructor(public payload: { comment: ClientComment }) {}
}

export class CommentCreateRequestFail implements Action {
  readonly type = CommentsActionTypes.CommentCreateRequestFail;
  constructor(public payload: any) {}
}

export class CommentDeleteRequest implements Action {
  readonly type = CommentsActionTypes.CommentDeleteRequest;
  constructor(public payload: { commentId: string }) {}
}

export class CommentDeleteRequestSuccess implements Action {
  readonly type = CommentsActionTypes.CommentDeleteRequestSuccess;
  constructor(public payload: { comment: Update<ClientComment> }) {}
}

export class CommentDeleteRequestFail implements Action {
  readonly type = CommentsActionTypes.CommentDeleteRequestFail;
  constructor(public payload: any) {}
}
export type CommentsActions =
  | AllCommentsRequest
  | AllCommentsRequestSuccess
  | AllCommentsRequestFail
  | CommentChangeRequest
  | CommentChangeRequestSuccess
  | CommentChangeRequestFail
  | CommentCreateRequest
  | CommentCreateRequestSuccess
  | CommentCreateRequestFail
  | CommentDeleteRequest
  | CommentDeleteRequestSuccess
  | CommentDeleteRequestFail;
