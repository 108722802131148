import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

// FIXME: how to aviod cyclic dependency when using @app/core ?
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Add auth token to all requests if available.
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // FIXME Need to test, if environment.serverUrl is used or a strange other url!
    // if (!/^(http|https):/i.test(request.url)) {
    //   return next.handle(request);
    // }

    if (this.authenticationService.hasValidToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.authToken.accessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
