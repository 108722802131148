/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientChatExtended } from '../models/client-chat-extended';

/**
 * Suggestion Chat Controller
 */
@Injectable({
  providedIn: 'root',
})
class GrapevineSuggestionChatService extends __BaseService {
  static readonly getOwnChatsAndSuggestionInternalWorkerChatsUsingGETPath = '/grapevine/suggestion/chat/own';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * returned chats include extended information (list of chat subjects)
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getOwnChatsAndSuggestionInternalWorkerChatsUsingGETResponse(appVariantIdentifier?: string): __Observable<__StrictHttpResponse<Array<ClientChatExtended>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grapevine/suggestion/chat/own`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientChatExtended>>;
      })
    );
  }
  /**
   * returned chats include extended information (list of chat subjects)
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param appVariantIdentifier optional header to provide identifier of the app variant
   * @return OK
   */
  getOwnChatsAndSuggestionInternalWorkerChatsUsingGET(appVariantIdentifier?: string): __Observable<Array<ClientChatExtended>> {
    return this.getOwnChatsAndSuggestionInternalWorkerChatsUsingGETResponse(appVariantIdentifier).pipe(
      __map(_r => _r.body as Array<ClientChatExtended>)
    );
  }
}

module GrapevineSuggestionChatService {
}

export { GrapevineSuggestionChatService }
