import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../services/logger.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthenticationService) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.authService.hasValidToken()) {
      return true;
    }

    log.debug('No valid token!');
    let value: any;
    value = this.authService.renewTokens().pipe(
      catchError(err => {
        log.debug('Redirecting to login...');
        this.authService.login();
        return of(false);
      }),
    );
    return value;
  }
}
