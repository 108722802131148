import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      redirectTo: 'vorschlag/aktuell',
      pathMatch: 'full',
    },
    {
      path: 'vorschlag',
      loadChildren: './suggestion-ui/suggestion-ui.module#SuggestionUIModule',
      data: { preload: true },
    },
    {
      path: 'chat',
      loadChildren: './chat-ui/chat-ui.module#ChatUIModule',
      data: { preload: true },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoesbarRoutingModule {}
