import { ChatsActions, ChatsActionTypes } from './chats.actions';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { ClientChatExtended } from '@app/api/models/client-chat-extended';

export interface ChatState extends EntityState<ClientChatExtended> {
  loading: boolean;
  lastSelectedChatID: string;
  lastReceivedMessageTimestamp: number;
}

const sortByLastMessage = (
  a: ClientChatExtended,
  b: ClientChatExtended,
): number => {
  return a.lastMessage &&
    b.lastMessage &&
    a.lastMessage.sentTime > b.lastMessage.sentTime
    ? -1
    : 1;
};

export const adapter: EntityAdapter<ClientChatExtended> = createEntityAdapter<
  ClientChatExtended
>({
  sortComparer: sortByLastMessage,
});

export const initialChatState: ChatState = adapter.getInitialState({
  loading: true, // we always load the chats immediately
  lastSelectedChatID: null,
  lastReceivedMessageTimestamp: 0,
});

export function chatReducer(
  state: ChatState = initialChatState,
  action: ChatsActions,
): ChatState {
  switch (action.type) {
    case ChatsActionTypes.AllChatsRequest:
      return { ...state, loading: true };
    case ChatsActionTypes.AllChatsRequestSuccess:
      return adapter.upsertMany(action.payload.chats, {
        ...state,
        loading: false,
      });
    case ChatsActionTypes.AllChatsRequestFail:
      return { ...state, loading: false };
    case ChatsActionTypes.UpdateLastMessageRequest:
    case ChatsActionTypes.SetReadChatRequestSuccess:
      return adapter.updateOne(action.payload.chat, state);
    case ChatsActionTypes.ChatStartOnCommentRequestSuccess:
    case ChatsActionTypes.ChatStartOnPostRequestSuccess:
    case ChatsActionTypes.ChatStartWithSuggestionWorkerRequestSuccess:
      return adapter.upsertOne(action.payload.chat, state);
    case ChatsActionTypes.UpdateLastSelectedChatRequest:
      return { ...state, lastSelectedChatID: action.payload.chatId };
    case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequest:
    case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestMP:
    case ChatsActionTypes.UpdateLastReceivedMessageTimestampRequestME:
      return {
        ...state,
        lastReceivedMessageTimestamp:
          action.payload.lastReceivedMessageTimestamp,
      };
    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
