import * as tslib_1 from "tslib";
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { CommentsActionTypes } from './comments.action';
export var adapter = createEntityAdapter();
export var initialCommentsState = adapter.getInitialState({
    loading: false,
});
export function commentReducer(state, action) {
    if (state === void 0) { state = initialCommentsState; }
    switch (action.type) {
        case CommentsActionTypes.AllCommentsRequestSuccess:
            return adapter.upsertMany(action.payload.comments, tslib_1.__assign({}, state, { loading: false }));
        case CommentsActionTypes.CommentChangeRequestSuccess:
            return adapter.updateOne(action.payload.comment, state);
        case CommentsActionTypes.CommentCreateRequestSuccess:
            return adapter.addOne(action.payload.comment, tslib_1.__assign({}, state));
        case CommentsActionTypes.CommentDeleteRequestSuccess:
            return adapter.updateOne(action.payload.comment, state);
        default:
            return state;
    }
}
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
