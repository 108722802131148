import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Logger } from './services/logger.service';
import { environment } from '@env/environment';
import { ErrorService } from './services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      //  Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      errorService.manageActionServerError(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    // Always log errors
    if (!environment.production) {
      const logger = new Logger('GlobalErrorHandlerInterceptor');
      logger.error('Request error', stackTrace + ': ' + error);
    }
  }
}
