import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Suggestion Controller
 */
var GrapevineSuggestionService = /** @class */ (function (_super) {
    tslib_1.__extends(GrapevineSuggestionService, _super);
    function GrapevineSuggestionService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Returns all extended suggestions, containing more details than the standard posts. The response is paged.
     *
     * | sorting criteria | attribute used for filtering and sorting | returned posts are subtype of | direction | default start if null | default end if null |
     * | ---- | ---- | ---- | ---- | ---- | ---- |
     * | CREATED | created | Post | DESC | MIN | MAX |
     * | LAST_SUGGESTION_ACTIVITY | lastActivity | Suggestion | DESC | MIN | MAX |
     * | LAST_ACTIVITY | lastComment | Post | DESC | MIN | MAX |
     *
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`INVALID_FILTER_CRITERIA`|400 BAD_REQUEST|The provided filter criteria are not valid|
     * |`INVALID_SORTING_CRITERION`|400 BAD_REQUEST|The provided sorting criterion is not valid|
     * |`PAGE_PARAMETER_INVALID`|400 BAD_REQUEST|The page parameters are invalid|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams` containing the following parameters:
     *
     * - `unassigned`: Filter criterion. When supplied, only suggestions without assigned suggestion workers will be listed.
     *   Can not be applied with containsWorker! This will throw an InvalidFilterCriteriaException.
     *
     * - `startTime`: Start time from which the suggestions should be returned, if it is 0 then all since 01.01.1970 will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     *
     * - `sortBy`: Sorting criterion. When CREATED, newest suggestions are listed first. When LAST_SUGGESTION_ACTIVITY, suggestions with the most recent suggestion activity are listed first. Considered as an suggestion activity is the creation, update or deletion of a comment or a new chat message. When LAST_ACTIVITY, suggestions with the most recent change in terms of comments are listed first. Difference between this two options is the consideration of new chat messages.
     *
     * - `page`: page number of the paged list of suggestions
     *
     * - `includedStatus`: Only suggestions with `suggestionStatus` *in* `includedStatus` are returned. If not set, this filter is not applied.
     *
     * - `excludedStatus`: Only suggestions with `suggestionStatus` *not in* `excludedStatus` are returned. If not set, this filter is not applied.
     *
     * - `endTime`: End time from which the suggestions should be returned, if 0 all suggestions between startTime and now will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     *
     * - `count`: maximum number of suggestions returned in a page, if it is 0 no pagination is applied
     *
     * - `containedWorkerId`: Filter criterion. When a personId is supplied, only suggestions with the given person as an assigned suggestion worker will be listed.
     *   Can not be applied with 'unassigned' = true! This will throw an InvalidFilterCriteriaException.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getExtendedSuggestionsUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.unassigned != null)
            __params = __params.set('unassigned', params.unassigned.toString());
        if (params.startTime != null)
            __params = __params.set('startTime', params.startTime.toString());
        if (params.sortBy != null)
            __params = __params.set('sortBy', params.sortBy.toString());
        if (params.page != null)
            __params = __params.set('page', params.page.toString());
        (params.includedStatus || []).forEach(function (val) { if (val != null)
            __params = __params.append('includedStatus', val.toString()); });
        (params.excludedStatus || []).forEach(function (val) { if (val != null)
            __params = __params.append('excludedStatus', val.toString()); });
        if (params.endTime != null)
            __params = __params.set('endTime', params.endTime.toString());
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        if (params.containedWorkerId != null)
            __params = __params.set('containedWorkerId', params.containedWorkerId.toString());
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/grapevine/suggestion/", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Returns all extended suggestions, containing more details than the standard posts. The response is paged.
     *
     * | sorting criteria | attribute used for filtering and sorting | returned posts are subtype of | direction | default start if null | default end if null |
     * | ---- | ---- | ---- | ---- | ---- | ---- |
     * | CREATED | created | Post | DESC | MIN | MAX |
     * | LAST_SUGGESTION_ACTIVITY | lastActivity | Suggestion | DESC | MIN | MAX |
     * | LAST_ACTIVITY | lastComment | Post | DESC | MIN | MAX |
     *
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`INVALID_FILTER_CRITERIA`|400 BAD_REQUEST|The provided filter criteria are not valid|
     * |`INVALID_SORTING_CRITERION`|400 BAD_REQUEST|The provided sorting criterion is not valid|
     * |`PAGE_PARAMETER_INVALID`|400 BAD_REQUEST|The page parameters are invalid|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetExtendedSuggestionsUsingGETParams` containing the following parameters:
     *
     * - `unassigned`: Filter criterion. When supplied, only suggestions without assigned suggestion workers will be listed.
     *   Can not be applied with containsWorker! This will throw an InvalidFilterCriteriaException.
     *
     * - `startTime`: Start time from which the suggestions should be returned, if it is 0 then all since 01.01.1970 will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     *
     * - `sortBy`: Sorting criterion. When CREATED, newest suggestions are listed first. When LAST_SUGGESTION_ACTIVITY, suggestions with the most recent suggestion activity are listed first. Considered as an suggestion activity is the creation, update or deletion of a comment or a new chat message. When LAST_ACTIVITY, suggestions with the most recent change in terms of comments are listed first. Difference between this two options is the consideration of new chat messages.
     *
     * - `page`: page number of the paged list of suggestions
     *
     * - `includedStatus`: Only suggestions with `suggestionStatus` *in* `includedStatus` are returned. If not set, this filter is not applied.
     *
     * - `excludedStatus`: Only suggestions with `suggestionStatus` *not in* `excludedStatus` are returned. If not set, this filter is not applied.
     *
     * - `endTime`: End time from which the suggestions should be returned, if 0 all suggestions between startTime and now will be returned. If the suggestions are sorted by the last activity, the parameter refers to the time of the last comment instead of the time when the suggestion was created.
     *
     * - `count`: maximum number of suggestions returned in a page, if it is 0 no pagination is applied
     *
     * - `containedWorkerId`: Filter criterion. When a personId is supplied, only suggestions with the given person as an assigned suggestion worker will be listed.
     *   Can not be applied with 'unassigned' = true! This will throw an InvalidFilterCriteriaException.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getExtendedSuggestionsUsingGET = function (params) {
        return this.getExtendedSuggestionsUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * ---
     *
     * ## No authentication required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCategoriesUsingGETResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/grapevine/suggestion/suggestionCategory", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## No authentication required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCategoriesUsingGET = function (appVariantIdentifier) {
        return this.getSuggestionCategoriesUsingGETResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * ---
     *
     * ## No authentication required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * | ― | ― | ― |
     * @param params The `GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams` containing the following parameters:
     *
     * - `suggestionCategoryId`: suggestionCategoryId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCategoryByIdUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/grapevine/suggestion/suggestionCategory/" + params.suggestionCategoryId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## No authentication required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`SUGGESTION_CATEGORY_NOT_FOUND`|404 NOT_FOUND|The requested suggestion category does not exist|
     * | ― | ― | ― |
     * @param params The `GrapevineSuggestionService.GetSuggestionCategoryByIdUsingGETParams` containing the following parameters:
     *
     * - `suggestionCategoryId`: suggestionCategoryId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCategoryByIdUsingGET = function (params) {
        return this.getSuggestionCategoryByIdUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Returns one extended suggestion, containing more details than the standard posts
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getExtendedSuggestionUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/grapevine/suggestion/" + params.suggestionId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Returns one extended suggestion, containing more details than the standard posts
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetExtendedSuggestionUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getExtendedSuggestionUsingGET = function (params) {
        return this.getExtendedSuggestionUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * in contrast to /grapevine/comment this method only works for comments, but also returns comments of suggestions that are withdrawn (and, thus as being deleted, not returned by the other endpoint)
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCommentsUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/grapevine/suggestion/" + params.suggestionId + "/comments"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * in contrast to /grapevine/comment this method only works for comments, but also returns comments of suggestions that are withdrawn (and, thus as being deleted, not returned by the other endpoint)
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetSuggestionCommentsUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getSuggestionCommentsUsingGET = function (params) {
        return this.getSuggestionCommentsUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Returns all potential suggestion workers for the given suggestion. These are all persons with roles SuggestionFirstResponder or SuggestionWorker for the tenant of the suggestion
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getPotentialSuggestionWorkersUsingGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/grapevine/suggestion/" + params.suggestionId + "/potentialWorker"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Returns all potential suggestion workers for the given suggestion. These are all persons with roles SuggestionFirstResponder or SuggestionWorker for the tenant of the suggestion
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineSuggestionService.GetPotentialSuggestionWorkersUsingGETParams` containing the following parameters:
     *
     * - `suggestionId`: suggestionId
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineSuggestionService.prototype.getPotentialSuggestionWorkersUsingGET = function (params) {
        return this.getPotentialSuggestionWorkersUsingGETResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    GrapevineSuggestionService.getExtendedSuggestionsUsingGETPath = '/grapevine/suggestion/';
    GrapevineSuggestionService.getSuggestionCategoriesUsingGETPath = '/grapevine/suggestion/suggestionCategory';
    GrapevineSuggestionService.getSuggestionCategoryByIdUsingGETPath = '/grapevine/suggestion/suggestionCategory/{suggestionCategoryId}';
    GrapevineSuggestionService.getExtendedSuggestionUsingGETPath = '/grapevine/suggestion/{suggestionId}';
    GrapevineSuggestionService.getSuggestionCommentsUsingGETPath = '/grapevine/suggestion/{suggestionId}/comments';
    GrapevineSuggestionService.getPotentialSuggestionWorkersUsingGETPath = '/grapevine/suggestion/{suggestionId}/potentialWorker';
    GrapevineSuggestionService.ngInjectableDef = i0.defineInjectable({ factory: function GrapevineSuggestionService_Factory() { return new GrapevineSuggestionService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: GrapevineSuggestionService, providedIn: "root" });
    return GrapevineSuggestionService;
}(__BaseService));
export { GrapevineSuggestionService };
