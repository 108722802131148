import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestionPollingService } from './suggestion-polling.service';
import { StoreModule } from '@ngrx/store';
import { suggestionReducer } from './suggestions/suggestion.reducer';
import { commentReducer } from './comments/comment.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SuggestionEffects } from './suggestions/suggestion.effects';
import { CommentEffects } from './comments/comment.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('suggestions', suggestionReducer),
    StoreModule.forFeature('comments', commentReducer),
    EffectsModule.forFeature([SuggestionEffects, CommentEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [SuggestionPollingService],
})
export class SuggestionStoreModule {}
