import * as tslib_1 from "tslib";
import { InjectionToken, Injector, } from '@angular/core';
import { HttpClient, HttpHandler, } from '@angular/common/http';
import { CacheInterceptor } from './cache.interceptor';
import { ApiPrefixInterceptor } from './api-prefix.interceptor';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { SilentAuthInterceptor } from './silent-auth.interceptor';
import { AppVariantIdentifierInterceptor } from './app-variant-identifier.interceptor';
// From @angular/common/http/src/interceptor: allows to chain interceptors
var HttpInterceptorHandler = /** @class */ (function () {
    function HttpInterceptorHandler(next, interceptor) {
        this.next = next;
        this.interceptor = interceptor;
    }
    HttpInterceptorHandler.prototype.handle = function (request) {
        return this.interceptor.intercept(request, this.next);
    };
    return HttpInterceptorHandler;
}());
/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export var HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken('HTTP_DYNAMIC_INTERCEPTORS');
/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
var HttpService = /** @class */ (function (_super) {
    tslib_1.__extends(HttpService, _super);
    function HttpService(httpHandler, injector, interceptors) {
        if (interceptors === void 0) { interceptors = []; }
        var _this = _super.call(this, httpHandler) || this;
        _this.httpHandler = httpHandler;
        _this.injector = injector;
        _this.interceptors = interceptors;
        if (!_this.interceptors) {
            // Configure default interceptors that can be disabled here
            _this.interceptors = [
                _this.injector.get(ApiPrefixInterceptor),
                _this.injector.get(AuthTokenInterceptor),
                _this.injector.get(SilentAuthInterceptor),
                _this.injector.get(AppVariantIdentifierInterceptor),
            ];
        }
        return _this;
    }
    HttpService.prototype.cache = function (forceUpdate) {
        var cacheInterceptor = this.injector
            .get(CacheInterceptor)
            .configure({ update: forceUpdate });
        return this.addInterceptor(cacheInterceptor);
    };
    HttpService.prototype.disableApiPrefix = function () {
        return this.removeInterceptor(ApiPrefixInterceptor);
    };
    HttpService.prototype.noAuthToken = function () {
        return this.removeInterceptor(AuthTokenInterceptor);
    };
    HttpService.prototype.noReauth = function () {
        return this.removeInterceptor(SilentAuthInterceptor);
    };
    HttpService.prototype.disableAppVariantIdentifier = function () {
        return this.removeInterceptor(AppVariantIdentifierInterceptor);
    };
    // Override the original method to wire interceptors when triggering the request.
    HttpService.prototype.request = function (method, url, options) {
        var handler = this.interceptors.reduceRight(function (next, interceptor) { return new HttpInterceptorHandler(next, interceptor); }, this.httpHandler);
        return new HttpClient(handler).request(method, url, options);
    };
    HttpService.prototype.removeInterceptor = function (interceptorType) {
        return new HttpService(this.httpHandler, this.injector, this.interceptors.filter(function (i) { return !(i instanceof interceptorType); }));
    };
    HttpService.prototype.addInterceptor = function (interceptor) {
        return new HttpService(this.httpHandler, this.injector, this.interceptors.concat([interceptor]));
    };
    return HttpService;
}(HttpClient));
export { HttpService };
