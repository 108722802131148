export enum AssignmentFilterType {
  ALL = 'Alle',
  MINE = 'Meine',
  UNASSIGNED = 'Ohne',
}

export interface AssignmentFilter {
  type: AssignmentFilterType;
  containedWorkerId: string;
}
