import { NetworkStatus } from '@app/loesbar/businesslogic/models/network-status';
import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Define State
 */
export interface CoreState {
  networkStatus?: NetworkStatus;
}

/**
 * Define Actions
 */
export enum NetworkStatusActionTypes {
  NetworkStatus = '[DVA] Network Status Action',
}

export class NetworkStatusAction implements Action {
  readonly type = NetworkStatusActionTypes.NetworkStatus;
  constructor(public payload: { networkStatus: NetworkStatus }) {}
}

export type NetworkErrorActions = NetworkStatusAction;

/**
 * Define Reducer
 */
const initialCoreState = { networkStatus: NetworkStatus.NO_ERROR };
export function coreStateReducer(
  state: CoreState = initialCoreState,
  action: NetworkStatusAction,
): CoreState {
  switch (action.type) {
    case NetworkStatusActionTypes.NetworkStatus:
      return { ...state, networkStatus: action.payload.networkStatus };
    default:
      return state;
  }
}

/**
 * Define selectors
 */
export const selectCoreState = createFeatureSelector<CoreState>('coreState');

export const selectNetworkStatus = createSelector(
  selectCoreState,
  state => state.networkStatus,
);
