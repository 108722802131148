/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientChatStartOnCommentConfirmation } from '../models/client-chat-start-on-comment-confirmation';
import { ClientChatStartOnCommentRequest } from '../models/client-chat-start-on-comment-request';
import { ClientChatStartOnPostConfirmation } from '../models/client-chat-start-on-post-confirmation';
import { ClientChatStartOnPostRequest } from '../models/client-chat-start-on-post-request';
import { ClientChatStartWithSuggestionWorkerConfirmation } from '../models/client-chat-start-with-suggestion-worker-confirmation';
import { ClientChatStartWithSuggestionWorkerRequest } from '../models/client-chat-start-with-suggestion-worker-request';

/**
 * Grapevine Chat Event Controller
 */
@Injectable({
  providedIn: 'root',
})
class GrapevineChatEventsService extends __BaseService {
  static readonly onClientChatStartOnCommentRequestUsingPOSTPath = '/grapevine/chat/event/chatStartOnCommentRequest';
  static readonly onClientChatStartOnPostRequestUsingPOSTPath = '/grapevine/chat/event/chatStartOnPostRequest';
  static readonly onChatStartWithSuggestionWorkerRequestUsingPOSTPath = '/grapevine/chat/event/chatStartWithSuggestionWorkerRequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that comment and a text message.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onClientChatStartOnCommentRequestUsingPOSTResponse(params: GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientChatStartOnCommentConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/chat/event/chatStartOnCommentRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChatStartOnCommentConfirmation>;
      })
    );
  }
  /**
   * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that comment and a text message.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onClientChatStartOnCommentRequestUsingPOST(params: GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams): __Observable<ClientChatStartOnCommentConfirmation> {
    return this.onClientChatStartOnCommentRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientChatStartOnCommentConfirmation)
    );
  }

  /**
   * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that post and a text message.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onClientChatStartOnPostRequestUsingPOSTResponse(params: GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientChatStartOnPostConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/chat/event/chatStartOnPostRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChatStartOnPostConfirmation>;
      })
    );
  }
  /**
   * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that post and a text message.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * Parameter *`appVariantIdentifier`* is required
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: required header to provide identifier of the app variant
   *
   * @return OK
   */
  onClientChatStartOnPostRequestUsingPOST(params: GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams): __Observable<ClientChatStartOnPostConfirmation> {
    return this.onClientChatStartOnPostRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientChatStartOnPostConfirmation)
    );
  }

  /**
   * Starts a private chat with a suggestion worker. The worker has to have the role SuggestionWorker for any tenant.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onChatStartWithSuggestionWorkerRequestUsingPOSTResponse(params: GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientChatStartWithSuggestionWorkerConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/grapevine/chat/event/chatStartWithSuggestionWorkerRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChatStartWithSuggestionWorkerConfirmation>;
      })
    );
  }
  /**
   * Starts a private chat with a suggestion worker. The worker has to have the role SuggestionWorker for any tenant.
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   *
   *
   * ### Required Roles
   * |Key|Related Entity Class|Name|Description|
   * | ---- | ---- | ---- | ---- |
   * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
   * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
   * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onChatStartWithSuggestionWorkerRequestUsingPOST(params: GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams): __Observable<ClientChatStartWithSuggestionWorkerConfirmation> {
    return this.onChatStartWithSuggestionWorkerRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientChatStartWithSuggestionWorkerConfirmation)
    );
  }
}

module GrapevineChatEventsService {

  /**
   * Parameters for onClientChatStartOnCommentRequestUsingPOST
   */
  export interface OnClientChatStartOnCommentRequestUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientChatStartOnCommentRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }

  /**
   * Parameters for onClientChatStartOnPostRequestUsingPOST
   */
  export interface OnClientChatStartOnPostRequestUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientChatStartOnPostRequest;

    /**
     * required header to provide identifier of the app variant
     */
    appVariantIdentifier: string;
  }

  /**
   * Parameters for onChatStartWithSuggestionWorkerRequestUsingPOST
   */
  export interface OnChatStartWithSuggestionWorkerRequestUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientChatStartWithSuggestionWorkerRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { GrapevineChatEventsService }
