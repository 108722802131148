import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  CommunicationChatEventsService,
  GrapevineChatEventsService,
  GrapevineSuggestionChatService,
} from '@app/api/services';
import {
  ChatsActionTypes,
  ChatReadRequest,
  ChatReadRequestSuccess,
  ChatReadRequestFail,
  ChatStartOnCommentRequest,
  ChatStartOnCommentRequestSuccess,
  ChatStartOnCommentRequestFail,
  ChatStartOnPostRequest,
  ChatStartOnPostRequestSuccess,
  ChatStartOnPostRequestFail,
  ChatStartWithSuggestionWorkerRequestSuccess,
  ChatStartWithSuggestionWorkerRequestFail,
  ChatStartWithSuggestionWorkerRequest,
  AllChatsRequestSuccess,
  AllChatsRequestFail,
  UpdateLastReceivedMessageTimestampRequest,
} from './chats.actions';
import {
  switchMap,
  map,
  catchError,
  tap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ClientChatMessage } from '@app/api/models';
import { Update } from '@ngrx/entity';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers';
import { selectLastReceivedMessageTimestamp } from './chats.selector';
import {
  NotificationService,
  MessageType,
  NotificationType,
} from '@app/core/services/notificationService';
import * as moment from 'moment';
import { ClientChatExtended } from '@app/api/models/client-chat-extended';
import {
  PersonService,
  NameStringType,
} from '@app/loesbar/businesslogic/services/person.service';

@Injectable()
export class ChatsEffects {
  @Effect()
  loadAllChats$ = this.actions$.pipe(
    ofType(
      ChatsActionTypes.AllChatsRequest,
      ChatsActionTypes.PollingAllChatsRequest,
    ),
    mergeMap(() =>
      this.grapevineSuggestionChatServices.getOwnChatsAndSuggestionInternalWorkerChatsUsingGET(),
    ),
    withLatestFrom(this.store.pipe(select(selectLastReceivedMessageTimestamp))),
    map(response => {
      const lastReceivedMessageTimestamp = response[1];
      const chats = response[0];

      // Notify user if there are new messages
      this.notifyNewMessages(lastReceivedMessageTimestamp, chats);

      return new AllChatsRequestSuccess({
        chats,
      });
    }),
    catchError(err => {
      this.store.dispatch(new AllChatsRequestFail(err));
      return throwError(err);
    }),
  );

  @Effect()
  setReadChat$ = this.actions$.pipe(
    ofType<ChatReadRequest>(ChatsActionTypes.SetReadChatRequest),
    switchMap(({ payload }) =>
      this.chatServices
        .setReadUsingPOST({
          clientRequest: { chatId: payload.chatID, readTime: payload.readTime },
        })
        .pipe(
          map(() => {
            const updatedClientChat: ClientChatExtended = {
              id: payload.chatID,
              unreadMessageCount: 0,
            };
            const updateClientChat: Update<ClientChatExtended> = {
              id: updatedClientChat.id,
              changes: updatedClientChat,
            };
            return new ChatReadRequestSuccess({ chat: updateClientChat });
          }),
          catchError(err => {
            this.store.dispatch(new ChatReadRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  chatStartOnCommentRequest$ = this.actions$.pipe(
    ofType<ChatStartOnCommentRequest>(
      ChatsActionTypes.ChatStartOnCommentRequest,
    ),
    switchMap(({ payload }) =>
      this.grapevineChatServices
        .onClientChatStartOnCommentRequestUsingPOST(
          payload.clientChatStartOnCommentRequest,
        )
        .pipe(
          map(response => {
            return new ChatStartOnCommentRequestSuccess({
              chat: response.chat,
            });
          }),
          catchError(err => {
            this.store.dispatch(new ChatStartOnCommentRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  chatStartOnPostRequest$ = this.actions$.pipe(
    ofType<ChatStartOnPostRequest>(ChatsActionTypes.ChatStartOnPostRequest),
    switchMap(({ payload }) =>
      this.grapevineChatServices
        .onClientChatStartOnPostRequestUsingPOST(
          payload.clientChatStartOnPostRequest,
        )
        .pipe(
          map(response => {
            return new ChatStartOnPostRequestSuccess({ chat: response.chat });
          }),
          catchError(err => {
            this.store.dispatch(new ChatStartOnPostRequestFail(err));
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect()
  chatStartWithSuggestionWorkerRequest$ = this.actions$.pipe(
    ofType<ChatStartWithSuggestionWorkerRequest>(
      ChatsActionTypes.ChatStartWithSuggestionWorkerRequest,
    ),
    switchMap(({ payload }) =>
      this.grapevineChatServices
        .onChatStartWithSuggestionWorkerRequestUsingPOST({
          clientRequest: payload.clientChatStartWithSuggestionWorkerRequest,
        })
        .pipe(
          map(response => {
            return new ChatStartWithSuggestionWorkerRequestSuccess({
              chat: response.chat,
            });
          }),
          catchError(err => {
            this.store.dispatch(
              new ChatStartWithSuggestionWorkerRequestFail(err),
            );
            return throwError(err);
          }),
        ),
    ),
  );

  @Effect({ dispatch: false })
  navigateChatOnChatStartOnCommentRequestSuccess$ = this.actions$.pipe(
    ofType<ChatStartOnCommentRequestSuccess>(
      ChatsActionTypes.ChatStartOnCommentRequestSuccess,
    ),
    tap(response => {
      this.router.navigate(['/chat/' + response.payload.chat.id]);
    }),
  );

  @Effect({ dispatch: false })
  navigateChatChatStartOnPostRequestSuccess$ = this.actions$.pipe(
    ofType<ChatStartOnPostRequestSuccess>(
      ChatsActionTypes.ChatStartOnPostRequestSuccess,
    ),
    tap(response => {
      this.router.navigate(['/chat/' + response.payload.chat.id]);
    }),
  );

  @Effect({ dispatch: false })
  navigateChatChatStartWithSuggestionWorkerRequestSuccess$ = this.actions$.pipe(
    ofType<ChatStartWithSuggestionWorkerRequestSuccess>(
      ChatsActionTypes.ChatStartWithSuggestionWorkerRequestSuccess,
    ),
    tap(response => {
      this.router.navigate(['/chat/' + response.payload.chat.id]);
    }),
  );

  constructor(
    private actions$: Actions,
    private chatServices: CommunicationChatEventsService,
    private grapevineChatServices: GrapevineChatEventsService,
    private grapevineSuggestionChatServices: GrapevineSuggestionChatService,
    private router: Router,
    private store: Store<AppState>,
    private notificationService: NotificationService,
  ) {}

  /**
   * Notify the user new incoming messages.
   * Compare the lastReceivedMessageTimestamp with each lastMessage of chats.
   * 1. If creation time of a lastMessage of a chat if newer than lastReceivedMessageTimestamp then,
   * is notified to the user.
   * 2. Newest message creation timestamp is saved in lastReceivedMessageTimestamp.
   * @param lastReceivedMessageTimestamp
   * @param chats
   */
  private notifyNewMessages(
    lastReceivedMessageTimestamp: number,
    chats: ClientChatExtended[],
  ): void {
    // convert lastReceivedMessageTimestamp in moment object
    const lastReceivedTimestamp = moment(lastReceivedMessageTimestamp);

    // new array of chats that has newer lastMessage cretion timestamp than lastReceivedTimestamp
    const chatsWithNewMessages = chats.filter(
      chat =>
        chat.lastMessage &&
        lastReceivedTimestamp.isBefore(chat.lastMessage.created),
    );

    const newMessages = chatsWithNewMessages.map(chat => chat.lastMessage);

    // notify user if there are new messages
    if (lastReceivedMessageTimestamp !== 0) {
      newMessages.forEach(message => {
        let title = 'Neue Nachricht - ';
        const nameStringType = NameStringType.FirstNameAndFirstCharOfLastName;
        title += PersonService.getNameStringFromClientChatMessage(
          message,
          nameStringType,
        );
        this.notificationService.showMessage(
          message.message,
          title,
          MessageType.INFO,
          NotificationType.TOAST,
        );
      });
    }

    if (newMessages.length > 0) {
      // Dispatch action to update lastReceivedMessageTimestamp
      this.store.dispatch(
        new UpdateLastReceivedMessageTimestampRequest({
          lastReceivedMessageTimestamp: this.getNewestMessageTimestamp(
            newMessages,
          ),
        }),
      );
    }
  }

  /**
   * Gets the newest message creation timestamp among given messages.
   * If there are no messages, the default timestamp is 0.
   * @param messages
   */
  private getNewestMessageTimestamp(messages: ClientChatMessage[]): number {
    let newestMessageTimestamp = 0;
    messages.map(message => {
      if (moment(message.created).isAfter(newestMessageTimestamp)) {
        newestMessageTimestamp = message.created;
      }
    });
    return newestMessageTimestamp;
  }
}
