import * as tslib_1 from "tslib";
var _a;
import { MessagesActionTypes } from './messages.actions';
import { createEntityAdapter } from '@ngrx/entity';
var sortMessages = function (a, b) {
    return a.sentTime < b.sentTime ? -1 : 1;
};
var ɵ0 = sortMessages;
export var adapter = createEntityAdapter({
    sortComparer: sortMessages,
});
export var initialMessageState = adapter.getInitialState({
    loading: false,
    timestamps: {},
});
export function messageReducer(state, action) {
    if (state === void 0) { state = initialMessageState; }
    switch (action.type) {
        case MessagesActionTypes.AllMessagesRequestSuccess:
            // clone timestamps
            var newTimestampList = Object.assign({}, state.timestamps);
            // add or update timestamps object
            newTimestampList[action.payload.chatID] = {
                timestamp: action.payload.timestamp,
            };
            return adapter.upsertMany(action.payload.messages.reverse(), tslib_1.__assign({}, state, { loading: false, timestamps: newTimestampList }));
        case MessagesActionTypes.PrevPageReadRequestSuccess:
            return adapter.upsertMany(action.payload.messages, state);
        case MessagesActionTypes.SendMessageRequestSuccess:
            return adapter.addOne(action.payload.message, tslib_1.__assign({}, state));
        default:
            return state;
    }
}
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
export { ɵ0 };
