import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Grapevine Chat Event Controller
 */
var GrapevineChatEventsService = /** @class */ (function (_super) {
    tslib_1.__extends(GrapevineChatEventsService, _super);
    function GrapevineChatEventsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that comment and a text message.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onClientChatStartOnCommentRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/chat/event/chatStartOnCommentRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that comment and a text message.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnClientChatStartOnCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onClientChatStartOnCommentRequestUsingPOST = function (params) {
        return this.onClientChatStartOnCommentRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that post and a text message.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onClientChatStartOnPostRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/chat/event/chatStartOnPostRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * If there is no chat it is created, if there is one already existing, the message is added to it. It creates a message that references that post and a text message.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_VERIFICATION_STATUS_INSUFFICIENT`|403 FORBIDDEN|If the feature 'de.fhg.iese.dd.dorffunk.content.creation-restriction' is enabled for the app variant, users have to have at least one of the allowed verification status, as configured in the feature. <br/> (The users verification status is not sufficient to do this action or view the data)|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnClientChatStartOnPostRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onClientChatStartOnPostRequestUsingPOST = function (params) {
        return this.onClientChatStartOnPostRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Starts a private chat with a suggestion worker. The worker has to have the role SuggestionWorker for any tenant.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onChatStartWithSuggestionWorkerRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/chat/event/chatStartWithSuggestionWorkerRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Starts a private chat with a suggestion worker. The worker has to have the role SuggestionWorker for any tenant.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineChatEventsService.OnChatStartWithSuggestionWorkerRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineChatEventsService.prototype.onChatStartWithSuggestionWorkerRequestUsingPOST = function (params) {
        return this.onChatStartWithSuggestionWorkerRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    GrapevineChatEventsService.onClientChatStartOnCommentRequestUsingPOSTPath = '/grapevine/chat/event/chatStartOnCommentRequest';
    GrapevineChatEventsService.onClientChatStartOnPostRequestUsingPOSTPath = '/grapevine/chat/event/chatStartOnPostRequest';
    GrapevineChatEventsService.onChatStartWithSuggestionWorkerRequestUsingPOSTPath = '/grapevine/chat/event/chatStartWithSuggestionWorkerRequest';
    GrapevineChatEventsService.ngInjectableDef = i0.defineInjectable({ factory: function GrapevineChatEventsService_Factory() { return new GrapevineChatEventsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: GrapevineChatEventsService, providedIn: "root" });
    return GrapevineChatEventsService;
}(__BaseService));
export { GrapevineChatEventsService };
