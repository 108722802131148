import { Injectable, OnDestroy } from '@angular/core';
import { CommunicationChatService } from '@app/api/services';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/store/reducers';
import { withLatestFrom, mergeMap } from 'rxjs/operators';
import { timer, EMPTY, Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { ClientChatMessage } from '@app/api/models';
import { Update } from '@ngrx/entity';
import * as moment from 'moment';
import { ClientChatExtended } from '@app/api/models/client-chat-extended';
import {
  AllMessagesRequestSuccess,
  AllMessagesRequestFail,
} from './messages/messages.actions';
import {
  ChatReadRequest,
  UpdateLastMessageRequest,
  UpdateLastReceivedMessageTimestampRequestMP,
} from './chats/chats.actions';
import { selectLastTimestampByChatId } from './messages/messages.selector';
import { selectLastReceivedMessageTimestamp } from './chats/chats.selector';

@Injectable()
export class MessagePollingService implements OnDestroy {
  /**
   * Message Polling Service subscription
   */
  messagesSubscription$: any;

  /**
   * Interval in milliseconds of the polling messages
   */
  POLLING_INTERVAL_MESSAGES = 10000;

  waitingBackendResponse = false;

  constructor(
    private communicationChatService: CommunicationChatService,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnDestroy() {
    // Stop polling
    if (this.messagesSubscription$) {
      this.messagesSubscription$.unsubscribe();
    }
  }

  subscribeToMessagePollingService(chatId: string) {
    if (!chatId) {
      return;
    }

    if (this.messagesSubscription$) {
      // Stop polling old subcription
      this.messagesSubscription$.unsubscribe();
    }

    this.messagesSubscription$ = this.getAllMessages(chatId).subscribe(
      pageOfMessages => {
        this.waitingBackendResponse = false;
        // if there is some message to add/update
        if (pageOfMessages !== undefined && pageOfMessages.content.length > 0) {
          const lastMessage = pageOfMessages.content[0];
          // check if message created time is not null due to DD-4411 Bug
          const lastMessageCreatedTime = lastMessage.created
            ? lastMessage.created
            : moment().valueOf();

          // dispatch action to update or add all received messages
          const messages = pageOfMessages.content;
          this.store.dispatch(
            new AllMessagesRequestSuccess({
              messages,
              chatID: chatId,
              timestamp: lastMessageCreatedTime,
            }),
          );

          // dispatch action to update lastReceivedMessageTimestamp
          this.updateLastReceivedMessageTimestamp(lastMessageCreatedTime);

          this.updateLastMessageInChatList(lastMessage, chatId);

          // dispatch action to to set the unreadMessageCount to 0 in the chat list
          this.store.dispatch(
            new ChatReadRequest({
              chatID: chatId,
              readTime: lastMessageCreatedTime,
            }),
          );
        }
      },
      err => {
        this.waitingBackendResponse = false;
        this.store.dispatch(new AllMessagesRequestFail(err));
        this.messagesSubscription$
          ? this.messagesSubscription$.unsubscribe()
          : console.log(err);
      },
    );
  }

  /**
   * Message Polling service function
   * @param chatId Chat id of the received messages
   */
  getAllMessages(chatId: string) {
    // start first request instantly, and every POLLING_INTERVAL_MESSAGES seconds after
    return timer(0, this.POLLING_INTERVAL_MESSAGES).pipe(
      withLatestFrom(this.store.select(selectLastTimestampByChatId(chatId))),
      mergeMap(selectorPayload => {
        if (
          this.authenticationService.hasValidToken() &&
          !this.waitingBackendResponse
        ) {
          this.waitingBackendResponse = true;
          const chatTimestamp = selectorPayload[1];
          return this.requestAllMessages(chatId, chatTimestamp);
        } else {
          this.waitingBackendResponse = false;
          return EMPTY;
        }
      }),
    );
  }

  private requestAllMessages(chatId: string, chatTimestamp: number) {
    return this.communicationChatService.getChatMessagesPagedUsingGET({
      chatId: chatId,
      from: chatTimestamp,
      page: 0,
      count: 20,
    });
  }

  /**
   * Update the last message in the chat which is stored in the chat store
   * @param lastMessage Last received message
   * @param chatId Id of the related chat of the last received message
   */
  private updateLastMessageInChatList(
    lastMessage: ClientChatMessage,
    chatId: string,
  ) {
    const updatedClientChat: Update<ClientChatExtended> = {
      id: chatId,
      changes: { lastMessage },
    };

    // dispatch action to update the last received message in the chat list
    this.store.dispatch(
      new UpdateLastMessageRequest({ chat: updatedClientChat }),
    );
  }

  /**
   * Check if the last given created message timestamp is newest than the lastReceivedMessageTimestamp saved in the store
   * If true, dispatch the action UpdateLastReceivedMessageTimestampRequestMP to update it
   * @param lastCreatedMessageTimestamp
   */
  private updateLastReceivedMessageTimestamp(
    lastCreatedMessageTimestamp: number,
  ) {
    const selectorSubscription: Subscription = this.store
      .pipe(select(selectLastReceivedMessageTimestamp))
      .subscribe(lastReceivedMessageTimestamp => {
        if (
          moment(lastCreatedMessageTimestamp).isAfter(
            lastReceivedMessageTimestamp,
          )
        ) {
          this.store.dispatch(
            new UpdateLastReceivedMessageTimestampRequestMP({
              lastReceivedMessageTimestamp: lastCreatedMessageTimestamp,
            }),
          );
        }
      });
    selectorSubscription.unsubscribe();
  }
}
