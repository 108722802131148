import { Component, OnInit } from '@angular/core';
import { NetworkStatus } from '@app/loesbar/businesslogic/models/network-status';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { CoreState, selectNetworkStatus } from '@app/core/store/core-store';
import { AppState } from '@app/store/reducers';

@Component({
  selector: 'app-error-bar',
  templateUrl: './error-bar.component.html',
  styleUrls: ['./error-bar.component.scss'],
})
export class ErrorBarComponent implements OnInit {
  NetworkStatus = NetworkStatus;
  public networkStatus: NetworkStatus;
  private networkStatus$: Observable<NetworkStatus> = this.store.pipe(
    select(selectNetworkStatus),
  );
  private networkStatus_: Subscription;

  constructor(private store: Store<AppState>) {
    this.networkStatus_ = this.networkStatus$.subscribe(status => {
      this.networkStatus = status;
    });
  }

  ngOnInit() {}
}
