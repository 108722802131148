import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AllCommentsRequestSuccess, AllCommentsRequestFail, CommentsActionTypes, CommentChangeRequestSuccess, CommentChangeRequestFail, CommentCreateRequestSuccess, CommentCreateRequestFail, CommentDeleteRequestSuccess, CommentDeleteRequestFail, } from './comments.action';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GrapevineCommentEventsService, GrapevineSuggestionService, } from '@app/api/services';
import { of, throwError } from 'rxjs';
import { UpdateSuggestionRequest } from '../suggestions/suggestions.action';
var CommentEffects = /** @class */ (function () {
    function CommentEffects(actions$, suggestionService, commentEventService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.suggestionService = suggestionService;
        this.commentEventService = commentEventService;
        this.store = store;
        this.loadComments$ = this.actions$.pipe(ofType(CommentsActionTypes.AllCommentsRequest), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.suggestionService
                .getSuggestionCommentsUsingGET({ suggestionId: payload.postId })
                .pipe(catchError(function (err) {
                console.log('error loading a comments', err);
                _this.store.dispatch(new AllCommentsRequestFail(err));
                return throwError(err);
            }));
        }), map(function (comments) { return new AllCommentsRequestSuccess({ comments: comments }); }));
        this.changeComment$ = this.actions$.pipe(ofType(CommentsActionTypes.CommentChangeRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.commentEventService
                .onCommentChangeRequestUsingPOST({
                request: payload.comment,
            })
                .pipe(map(function (response) {
                var updatedClientComment = response.comment;
                var changes = updatedClientComment;
                var updateComment = {
                    id: updatedClientComment.id,
                    changes: changes,
                };
                return new CommentChangeRequestSuccess({ comment: updateComment });
            }), catchError(function (err) {
                _this.store.dispatch(new CommentChangeRequestFail(err));
                return throwError(err);
            }));
        }));
        this.createComment$ = this.actions$.pipe(ofType(CommentsActionTypes.CommentCreateRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.commentEventService
                .onCommentCreateRequestBySuggestionWorkerUsingPOST({
                clientRequest: payload.comment,
            })
                .pipe(map(function (response) {
                return new CommentCreateRequestSuccess({
                    comment: response.comment,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new CommentCreateRequestFail(err));
                return throwError(err);
            }));
        }));
        this.withdrawnComment$ = this.actions$.pipe(ofType(CommentsActionTypes.CommentDeleteRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.commentEventService
                .onCommentDeleteRequestUsingPOST({
                request: payload,
            })
                .pipe(map(function (response) {
                var updatedClientComment = { id: response.commentId, text: null };
                var changes = updatedClientComment;
                var updateComment = {
                    id: response.commentId,
                    changes: changes,
                };
                return new CommentDeleteRequestSuccess({ comment: updateComment });
            }), catchError(function (err) {
                _this.store.dispatch(new CommentDeleteRequestFail(err));
                return throwError(err);
            }));
        }));
        this.updateSuggestionOnNewComment$ = this.actions$.pipe(ofType(CommentsActionTypes.CommentCreateRequestSuccess), switchMap(function (_a) {
            var payload = _a.payload;
            return of(new UpdateSuggestionRequest({ suggestionId: payload.comment.postId }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommentEffects.prototype, "loadComments$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommentEffects.prototype, "changeComment$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommentEffects.prototype, "createComment$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommentEffects.prototype, "withdrawnComment$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommentEffects.prototype, "updateSuggestionOnNewComment$", void 0);
    return CommentEffects;
}());
export { CommentEffects };
