import * as tslib_1 from "tslib";
import { Actions, ofType, Effect } from '@ngrx/effects';
import { CommunicationChatEventsService, CommunicationChatService, } from '@app/api/services';
import { MessagesActionTypes, SendMessageRequestSuccess, SendMessageRequestFail, PrevPageReadRequestSuccess, PrevPageReadRequestFail, } from './messages.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { UpdateLastReceivedMessageTimestampRequestME } from '../chats/chats.actions';
var MessagesEffects = /** @class */ (function () {
    function MessagesEffects(actions$, communicationChatEventsService, communicationChatService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.communicationChatEventsService = communicationChatEventsService;
        this.communicationChatService = communicationChatService;
        this.store = store;
        this.sendMessage$ = this.actions$.pipe(ofType(MessagesActionTypes.SendMessageRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.communicationChatEventsService
                .sendMessageUsingPOST({ clientRequest: payload.message })
                .pipe(map(function (response) {
                _this.store.dispatch(new UpdateLastReceivedMessageTimestampRequestME({
                    lastReceivedMessageTimestamp: response.chatMessage.created,
                }));
                return new SendMessageRequestSuccess({
                    message: response.chatMessage,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new SendMessageRequestFail(err));
                return throwError(err);
            }));
        }));
        this.prevPageRead$ = this.actions$.pipe(ofType(MessagesActionTypes.PrevPageReadRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.communicationChatService
                .getChatMessagesPagedUsingGET({
                chatId: payload.chatID,
                page: Math.floor(payload.nbMessagesLoaded / 10),
                count: 10,
            })
                .pipe(map(function (response) {
                return new PrevPageReadRequestSuccess({
                    messages: response.content,
                });
            }), catchError(function (err) {
                return of(new PrevPageReadRequestFail(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesEffects.prototype, "sendMessage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesEffects.prototype, "prevPageRead$", void 0);
    return MessagesEffects;
}());
export { MessagesEffects };
