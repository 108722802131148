import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Comment Event Controller
 */
var GrapevineCommentEventsService = /** @class */ (function (_super) {
    tslib_1.__extends(GrapevineCommentEventsService, _super);
    function GrapevineCommentEventsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Updates an existing Comment. Only text and images can be updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentChangeRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/commentChangeRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Updates an existing Comment. Only text and images can be updated.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentChangeRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentChangeRequestUsingPOST = function (params) {
        return this.onCommentChangeRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Comment with or without pictures.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`NOT_MEMBER_OF_GROUP`|409 CONFLICT|The user is not a member of the group|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentCreateRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/commentCreateRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Comment with or without pictures.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * |`NOT_MEMBER_OF_GROUP`|409 CONFLICT|The user is not a member of the group|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentCreateRequestUsingPOST = function (params) {
        return this.onCommentCreateRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     */
    GrapevineCommentEventsService.prototype.onCommentDeleteAllForPostRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/commentDeleteAllForPostRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentDeleteAllForPostRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     */
    GrapevineCommentEventsService.prototype.onCommentDeleteAllForPostRequestUsingPOST = function (params) {
        return this.onCommentDeleteAllForPostRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Deletes an existing Comment. Only the creator can delete the comment.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentDeleteRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/commentDeleteRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Deletes an existing Comment. Only the creator can delete the comment.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ACTION_NOT_ALLOWED`|403 FORBIDDEN|The action on the comment can not be done by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentDeleteRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentDeleteRequestUsingPOST = function (params) {
        return this.onCommentDeleteRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * 'commentId' is the id of the comment, while optional 'comment' is the reason for flagging this entity
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.commentFlagRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/commentFlagRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * 'commentId' is the id of the comment, while optional 'comment' is the reason for flagging this entity
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.CommentFlagRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.commentFlagRequestUsingPOST = function (params) {
        return this.commentFlagRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Likes an existing comment. It is possible to like your own comments. If a comment is already liked, it returns a CommentAlreadyLikedException.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ALREADY_LIKED`|400 BAD_REQUEST|The provided comment is already liked by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onLikeCommentRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/likeCommentRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Likes an existing comment. It is possible to like your own comments. If a comment is already liked, it returns a CommentAlreadyLikedException.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ALREADY_LIKED`|400 BAD_REQUEST|The provided comment is already liked by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnLikeCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onLikeCommentRequestUsingPOST = function (params) {
        return this.onLikeCommentRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Comment on a suggestion by a suggestion worker. If the suggestion is deleted or withdrawn comments can not be created.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentCreateRequestBySuggestionWorkerUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/suggestion/commentCreateRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Comment on a suggestion by a suggestion worker. If the suggestion is deleted or withdrawn comments can not be created.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`SUGGESTION_FIRST_RESPONDER`|`Community`|LösBar-Erstkontakt|Eine Ansprechperson zur Erstbearbeitung von Vorschlägen einer Gemeinde.|
     * |`SUGGESTION_WORKER`|`Community`|LösBar-Sachbearbeiter|Eine Person zur Bearbeitung von Vorschlägen einer Gemeinde.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`POST_NOT_FOUND`|404 NOT_FOUND|The requested post does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnCommentCreateRequestBySuggestionWorkerUsingPOSTParams` containing the following parameters:
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onCommentCreateRequestBySuggestionWorkerUsingPOST = function (params) {
        return this.onCommentCreateRequestBySuggestionWorkerUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Unlikes an existing comment. If a comment is already unliked, it returns a CommentAlreadyUnlikedException.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ALREADY_UNLIKED`|400 BAD_REQUEST|The provided comment is already unliked by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * |`COMMENT_NOT_LIKED`|404 NOT_FOUND|The requested comment has not been liked before|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onUnlikeCommentRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/grapevine/comment/event/unlikeCommentRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Unlikes an existing comment. If a comment is already unliked, it returns a CommentAlreadyUnlikedException.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`COMMENT_ALREADY_UNLIKED`|400 BAD_REQUEST|The provided comment is already unliked by this user|
     * |`COMMENT_NOT_FOUND`|404 NOT_FOUND|The requested comment does not exist|
     * |`COMMENT_NOT_LIKED`|404 NOT_FOUND|The requested comment has not been liked before|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `GrapevineCommentEventsService.OnUnlikeCommentRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: required header to provide identifier of the app variant
     *
     * @return OK
     */
    GrapevineCommentEventsService.prototype.onUnlikeCommentRequestUsingPOST = function (params) {
        return this.onUnlikeCommentRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    GrapevineCommentEventsService.onCommentChangeRequestUsingPOSTPath = '/grapevine/comment/event/commentChangeRequest';
    GrapevineCommentEventsService.onCommentCreateRequestUsingPOSTPath = '/grapevine/comment/event/commentCreateRequest';
    GrapevineCommentEventsService.onCommentDeleteAllForPostRequestUsingPOSTPath = '/grapevine/comment/event/commentDeleteAllForPostRequest';
    GrapevineCommentEventsService.onCommentDeleteRequestUsingPOSTPath = '/grapevine/comment/event/commentDeleteRequest';
    GrapevineCommentEventsService.commentFlagRequestUsingPOSTPath = '/grapevine/comment/event/commentFlagRequest';
    GrapevineCommentEventsService.onLikeCommentRequestUsingPOSTPath = '/grapevine/comment/event/likeCommentRequest';
    GrapevineCommentEventsService.onCommentCreateRequestBySuggestionWorkerUsingPOSTPath = '/grapevine/comment/event/suggestion/commentCreateRequest';
    GrapevineCommentEventsService.onUnlikeCommentRequestUsingPOSTPath = '/grapevine/comment/event/unlikeCommentRequest';
    GrapevineCommentEventsService.ngInjectableDef = i0.defineInjectable({ factory: function GrapevineCommentEventsService_Factory() { return new GrapevineCommentEventsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: GrapevineCommentEventsService, providedIn: "root" });
    return GrapevineCommentEventsService;
}(__BaseService));
export { GrapevineCommentEventsService };
