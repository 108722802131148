import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Person Event Controller
 */
var ParticipantsPersonEventsService = /** @class */ (function (_super) {
    tslib_1.__extends(ParticipantsPersonEventsService, _super);
    function ParticipantsPersonEventsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Change the home tenant a person belongs to by specifying the new tenant. This should not be used if the home area is known!.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonPersonChangeCommunityRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The new tenant the person should be part of. This is the tenant the person lives in.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonPersonChangeCommunityRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personChangeCommunityRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Change the home tenant a person belongs to by specifying the new tenant. This should not be used if the home area is known!.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonPersonChangeCommunityRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The new tenant the person should be part of. This is the tenant the person lives in.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonPersonChangeCommunityRequestUsingPOST = function (params) {
        return this.onPersonPersonChangeCommunityRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Changes the own email address and resets the verification status. Currently only possible for username-password accounts, not for social media accounts.
     * There is a time restriction on how often the email address can be changed. It is currently set to 4h.
     * For technical reasons this time restriction is coupled to the sending of email verification emails. This means that if no email verification is enabled (for the tenant of the person) there is no time restriction for email changes. Additionally it means that a re-send of the email verification email resets the timer for email changes, too.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`EMAIL_ADDRESS_INVALID`|400 BAD_REQUEST|The provided email address is invalid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|The account was no username-password account <br/> (The provided email could not be changed)|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|The time limit for changing the email is not elapsed <br/> (The provided email could not be changed)|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|There was an unexpected error at the authentication provider <br/> (The provided email could not be changed)|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonChangeEmailAddressRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonChangeEmailAddressRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personChangeEmailAddressRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Changes the own email address and resets the verification status. Currently only possible for username-password accounts, not for social media accounts.
     * There is a time restriction on how often the email address can be changed. It is currently set to 4h.
     * For technical reasons this time restriction is coupled to the sending of email verification emails. This means that if no email verification is enabled (for the tenant of the person) there is no time restriction for email changes. Additionally it means that a re-send of the email verification email resets the timer for email changes, too.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`EMAIL_ADDRESS_INVALID`|400 BAD_REQUEST|The provided email address is invalid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|The account was no username-password account <br/> (The provided email could not be changed)|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|The time limit for changing the email is not elapsed <br/> (The provided email could not be changed)|
     * |`EMAIL_CHANGE_NOT_POSSIBLE`|403 FORBIDDEN|There was an unexpected error at the authentication provider <br/> (The provided email could not be changed)|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonChangeEmailAddressRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: request
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonChangeEmailAddressRequestUsingPOST = function (params) {
        return this.onPersonChangeEmailAddressRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Change the home area of a person by specifying the new home area.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`GEO_AREA_NOT_FOUND`|404 NOT_FOUND|The requested geo area does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonPersonChangeHomeAreaRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The new home area of the person. This is the area the person lives in.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonPersonChangeHomeAreaRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personChangeHomeAreaRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Change the home area of a person by specifying the new home area.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`GEO_AREA_NOT_FOUND`|404 NOT_FOUND|The requested geo area does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonPersonChangeHomeAreaRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The new home area of the person. This is the area the person lives in.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonPersonChangeHomeAreaRequestUsingPOST = function (params) {
        return this.onPersonPersonChangeHomeAreaRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     * Also registers an oauth account with the persons email and triggers a password reset mail.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Rest admins and global user admins can create persons in every tenant and for every home area. Regular user admins may only create persons in tenants they are admins for (or with home areas of these tenants).
     *
     *
     *
     * ### Actions
     * |Action|Required|Description|Permission Description|
     * | ---- | :--: | ---- | ---- |
     * |`CreatePersonAction`|yes|Create persons for tenant t: `person.tenant == t` or `person.homeArea.tenant == t`.<br>The returned created person is a ClientPersonExtended.|Callers with role assignments "REST_ADMIN, GLOBAL_USER_ADMIN" are allowed to do this action for all tenants.<br>Callers with role assignments "USER_ADMIN" are allowed to do this action for the related tenants of the role assignment.|
     *
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`GLOBAL_USER_ADMIN`|`-`|Benutzer-Admin (Global)|Hat Zugriff auf alle Benutzer-Daten und -Aktionen aller Mandanten|
     * |`REST_ADMIN`|`-`|REST-Admin|Hat Zugriff auf die Admin-REST-Schnittstelle. Nur er kann auch die REST_ADMIN-Rolle vergeben.|
     * |`USER_ADMIN`|`Community`|Benutzer-Admin (Mandant)|Hat Zugriff auf Benutzer-Daten und -Aktionen bezüglich des verknüpften Mandanten.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateByAdminRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateByAdminRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personCreateByAdminRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     * Also registers an oauth account with the persons email and triggers a password reset mail.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Rest admins and global user admins can create persons in every tenant and for every home area. Regular user admins may only create persons in tenants they are admins for (or with home areas of these tenants).
     *
     *
     *
     * ### Actions
     * |Action|Required|Description|Permission Description|
     * | ---- | :--: | ---- | ---- |
     * |`CreatePersonAction`|yes|Create persons for tenant t: `person.tenant == t` or `person.homeArea.tenant == t`.<br>The returned created person is a ClientPersonExtended.|Callers with role assignments "REST_ADMIN, GLOBAL_USER_ADMIN" are allowed to do this action for all tenants.<br>Callers with role assignments "USER_ADMIN" are allowed to do this action for the related tenants of the role assignment.|
     *
     *
     *
     *
     * ### Required Roles
     * |Key|Related Entity Class|Name|Description|
     * | ---- | ---- | ---- | ---- |
     * |`GLOBAL_USER_ADMIN`|`-`|Benutzer-Admin (Global)|Hat Zugriff auf alle Benutzer-Daten und -Aktionen aller Mandanten|
     * |`REST_ADMIN`|`-`|REST-Admin|Hat Zugriff auf die Admin-REST-Schnittstelle. Nur er kann auch die REST_ADMIN-Rolle vergeben.|
     * |`USER_ADMIN`|`Community`|Benutzer-Admin (Mandant)|Hat Zugriff auf Benutzer-Daten und -Aktionen bezüglich des verknüpften Mandanten.|
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateByAdminRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateByAdminRequestUsingPOST = function (params) {
        return this.onPersonCreateByAdminRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personCreateRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateRequestUsingPOST = function (params) {
        return this.onPersonCreateRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * valid registration token is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateWithRegistrationTokenRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `registrationToken`: Alternative way of authentication without oauth, used by auth0 rules
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateWithRegistrationTokenRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.registrationToken != null)
            __params = __params.set('registrationToken', params.registrationToken.toString());
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personCreateWithRegistrationTokenRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Creates a new Person. Refer to documentation of request for required or optional fields.
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * valid registration token is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`ADDRESS_INVALID`|400 BAD_REQUEST|The provided address is not valid|
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * |`EMAIL_ALREADY_REGISTERED`|403 FORBIDDEN|The provided email is already registered|
     * |`PERSON_ALREADY_EXISTS`|403 FORBIDDEN|The person does already exist|
     * |`TENANT_NOT_FOUND`|404 NOT_FOUND|The requested tenant does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonCreateWithRegistrationTokenRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The details of the new person to be created.
     *
     *   Refer to documentation of request for required or optional fields.
     *
     * - `registrationToken`: Alternative way of authentication without oauth, used by auth0 rules
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonCreateWithRegistrationTokenRequestUsingPOST = function (params) {
        return this.onPersonCreateWithRegistrationTokenRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * The registration token can be used to register a new person in the backend
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * API key is configured in oauth.api-key-for-registration-token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`UNSPECIFIED_BAD_REQUEST`|400 BAD_REQUEST|The sent request is not valid|
     * |`UNSPECIFIED_NOT_FOUND`|404 NOT_FOUND|Whatever you searched for, it does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonRegistrationTokenRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientPersonRegistrationTokenRequest`: a request with the person's oauthId and email
     *
     * - `apiKey`: apiKey
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonRegistrationTokenRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.clientPersonRegistrationTokenRequest;
        if (params.apiKey != null)
            __headers = __headers.set('apiKey', params.apiKey.toString());
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personRegistrationTokenRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * The registration token can be used to register a new person in the backend
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * API key is configured in oauth.api-key-for-registration-token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`UNSPECIFIED_BAD_REQUEST`|400 BAD_REQUEST|The sent request is not valid|
     * |`UNSPECIFIED_NOT_FOUND`|404 NOT_FOUND|Whatever you searched for, it does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonRegistrationTokenRequestUsingPOSTParams` containing the following parameters:
     *
     * - `clientPersonRegistrationTokenRequest`: a request with the person's oauthId and email
     *
     * - `apiKey`: apiKey
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonRegistrationTokenRequestUsingPOST = function (params) {
        return this.onPersonRegistrationTokenRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Resends the verification mail in order that a user can verify its email address
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|Only enabled with PersonEmailVerificationFeature <br/> (The feature is not enabled for this user)|
     * |`RESEND_VERIFICATION_EMAIL_NOT_POSSIBLE`|400 BAD_REQUEST|Time period for resending verification mail is not elapsed|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier required header to provide identifier of the app variant
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonResendVerificationEmailRequestUsingPOSTResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personResendVerificationEmailRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Resends the verification mail in order that a user can verify its email address
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * Parameter *`appVariantIdentifier`* is required
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`FEATURE_NOT_ENABLED`|400 BAD_REQUEST|Only enabled with PersonEmailVerificationFeature <br/> (The feature is not enabled for this user)|
     * |`RESEND_VERIFICATION_EMAIL_NOT_POSSIBLE`|400 BAD_REQUEST|Time period for resending verification mail is not elapsed|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`APP_VARIANT_NOT_FOUND`|404 NOT_FOUND|The requested app variant does not exist|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier required header to provide identifier of the app variant
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonResendVerificationEmailRequestUsingPOST = function (appVariantIdentifier) {
        return this.onPersonResendVerificationEmailRequestUsingPOSTResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Sets the value of 'lastLoggedIn' to now and changes removes the status PENDING_DELETION so that the user does not get deleted. This is called from the webapp that is linked in the inactivity warning email.
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * Authorization is done via mail token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.ResetLastLoggedInRequestMailUsingPOSTParams` containing the following parameters:
     *
     * - `mailToken`: mailToken
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     */
    ParticipantsPersonEventsService.prototype.resetLastLoggedInRequestMailUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.mailToken != null)
            __params = __params.set('mailToken', params.mailToken.toString());
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personResetLastLoggedInRequest/mail", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Sets the value of 'lastLoggedIn' to now and changes removes the status PENDING_DELETION so that the user does not get deleted. This is called from the webapp that is linked in the inactivity warning email.
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * Authorization is done via mail token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.ResetLastLoggedInRequestMailUsingPOSTParams` containing the following parameters:
     *
     * - `mailToken`: mailToken
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     */
    ParticipantsPersonEventsService.prototype.resetLastLoggedInRequestMailUsingPOST = function (params) {
        return this.resetLastLoggedInRequestMailUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Updates the own person. To manipulate an address use the address list endpoints. To change the email address use the separate change email request.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonUpdateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The updated details of the own person.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonUpdateRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.request;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personUpdateRequest", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Updates the own person. To manipulate an address use the address list endpoints. To change the email address use the separate change email request.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_INFORMATION_INVALID`|400 BAD_REQUEST|The provided person information is not valid|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param params The `ParticipantsPersonEventsService.OnPersonUpdateRequestUsingPOSTParams` containing the following parameters:
     *
     * - `request`: The updated details of the own person.
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonUpdateRequestUsingPOST = function (params) {
        return this.onPersonUpdateRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * Sets the verification status EMAIL_VERIFIED of the user
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * Authorization is done via mail token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonVerifyEmailRequestUsingPOSTParams` containing the following parameters:
     *
     * - `mailToken`: mailToken
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonVerifyEmailRequestUsingPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.mailToken != null)
            __params = __params.set('mailToken', params.mailToken.toString());
        __body = params.clientRequest;
        if (params.appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/person/event/personVerifyEmailRequest/mail", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Sets the verification status EMAIL_VERIFIED of the user
     *
     * ---
     *
     * ## Authenticated via API key
     *
     * Authorization is done via mail token
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`PERSON_NOT_FOUND`|404 NOT_FOUND|The requested person does not exist|
     * | ― | ― | ― |
     * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
     * |`NOT_AUTHENTICATED`|401 UNAUTHORIZED|No API key was provided <br/> (Mandatory authentication not provided)|
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The provided API key is invalid <br/> (The user is not authorized to do this action or view the data)|
     * @param params The `ParticipantsPersonEventsService.OnPersonVerifyEmailRequestUsingPOSTParams` containing the following parameters:
     *
     * - `mailToken`: mailToken
     *
     * - `clientRequest`: clientRequest
     *
     * - `appVariantIdentifier`: optional header to provide identifier of the app variant
     *
     * @return OK
     */
    ParticipantsPersonEventsService.prototype.onPersonVerifyEmailRequestUsingPOST = function (params) {
        return this.onPersonVerifyEmailRequestUsingPOSTResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ParticipantsPersonEventsService.onPersonPersonChangeCommunityRequestUsingPOSTPath = '/person/event/personChangeCommunityRequest';
    ParticipantsPersonEventsService.onPersonChangeEmailAddressRequestUsingPOSTPath = '/person/event/personChangeEmailAddressRequest';
    ParticipantsPersonEventsService.onPersonPersonChangeHomeAreaRequestUsingPOSTPath = '/person/event/personChangeHomeAreaRequest';
    ParticipantsPersonEventsService.onPersonCreateByAdminRequestUsingPOSTPath = '/person/event/personCreateByAdminRequest';
    ParticipantsPersonEventsService.onPersonCreateRequestUsingPOSTPath = '/person/event/personCreateRequest';
    ParticipantsPersonEventsService.onPersonCreateWithRegistrationTokenRequestUsingPOSTPath = '/person/event/personCreateWithRegistrationTokenRequest';
    ParticipantsPersonEventsService.onPersonRegistrationTokenRequestUsingPOSTPath = '/person/event/personRegistrationTokenRequest';
    ParticipantsPersonEventsService.onPersonResendVerificationEmailRequestUsingPOSTPath = '/person/event/personResendVerificationEmailRequest';
    ParticipantsPersonEventsService.resetLastLoggedInRequestMailUsingPOSTPath = '/person/event/personResetLastLoggedInRequest/mail';
    ParticipantsPersonEventsService.onPersonUpdateRequestUsingPOSTPath = '/person/event/personUpdateRequest';
    ParticipantsPersonEventsService.onPersonVerifyEmailRequestUsingPOSTPath = '/person/event/personVerifyEmailRequest/mail';
    ParticipantsPersonEventsService.ngInjectableDef = i0.defineInjectable({ factory: function ParticipantsPersonEventsService_Factory() { return new ParticipantsPersonEventsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ParticipantsPersonEventsService, providedIn: "root" });
    return ParticipantsPersonEventsService;
}(__BaseService));
export { ParticipantsPersonEventsService };
