import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CommunicationChatEventsService, GrapevineChatEventsService, GrapevineSuggestionChatService, } from '@app/api/services';
import { ChatsActionTypes, ChatReadRequestSuccess, ChatReadRequestFail, ChatStartOnCommentRequestSuccess, ChatStartOnCommentRequestFail, ChatStartOnPostRequestSuccess, ChatStartOnPostRequestFail, ChatStartWithSuggestionWorkerRequestSuccess, ChatStartWithSuggestionWorkerRequestFail, AllChatsRequestSuccess, AllChatsRequestFail, UpdateLastReceivedMessageTimestampRequest, } from './chats.actions';
import { switchMap, map, catchError, tap, mergeMap, withLatestFrom, } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectLastReceivedMessageTimestamp } from './chats.selector';
import { NotificationService, MessageType, NotificationType, } from '@app/core/services/notificationService';
import * as moment from 'moment';
import { PersonService, NameStringType, } from '@app/loesbar/businesslogic/services/person.service';
var ChatsEffects = /** @class */ (function () {
    function ChatsEffects(actions$, chatServices, grapevineChatServices, grapevineSuggestionChatServices, router, store, notificationService) {
        var _this = this;
        this.actions$ = actions$;
        this.chatServices = chatServices;
        this.grapevineChatServices = grapevineChatServices;
        this.grapevineSuggestionChatServices = grapevineSuggestionChatServices;
        this.router = router;
        this.store = store;
        this.notificationService = notificationService;
        this.loadAllChats$ = this.actions$.pipe(ofType(ChatsActionTypes.AllChatsRequest, ChatsActionTypes.PollingAllChatsRequest), mergeMap(function () {
            return _this.grapevineSuggestionChatServices.getOwnChatsAndSuggestionInternalWorkerChatsUsingGET();
        }), withLatestFrom(this.store.pipe(select(selectLastReceivedMessageTimestamp))), map(function (response) {
            var lastReceivedMessageTimestamp = response[1];
            var chats = response[0];
            // Notify user if there are new messages
            _this.notifyNewMessages(lastReceivedMessageTimestamp, chats);
            return new AllChatsRequestSuccess({
                chats: chats,
            });
        }), catchError(function (err) {
            _this.store.dispatch(new AllChatsRequestFail(err));
            return throwError(err);
        }));
        this.setReadChat$ = this.actions$.pipe(ofType(ChatsActionTypes.SetReadChatRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.chatServices
                .setReadUsingPOST({
                clientRequest: { chatId: payload.chatID, readTime: payload.readTime },
            })
                .pipe(map(function () {
                var updatedClientChat = {
                    id: payload.chatID,
                    unreadMessageCount: 0,
                };
                var updateClientChat = {
                    id: updatedClientChat.id,
                    changes: updatedClientChat,
                };
                return new ChatReadRequestSuccess({ chat: updateClientChat });
            }), catchError(function (err) {
                _this.store.dispatch(new ChatReadRequestFail(err));
                return throwError(err);
            }));
        }));
        this.chatStartOnCommentRequest$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartOnCommentRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.grapevineChatServices
                .onClientChatStartOnCommentRequestUsingPOST(payload.clientChatStartOnCommentRequest)
                .pipe(map(function (response) {
                return new ChatStartOnCommentRequestSuccess({
                    chat: response.chat,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new ChatStartOnCommentRequestFail(err));
                return throwError(err);
            }));
        }));
        this.chatStartOnPostRequest$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartOnPostRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.grapevineChatServices
                .onClientChatStartOnPostRequestUsingPOST(payload.clientChatStartOnPostRequest)
                .pipe(map(function (response) {
                return new ChatStartOnPostRequestSuccess({ chat: response.chat });
            }), catchError(function (err) {
                _this.store.dispatch(new ChatStartOnPostRequestFail(err));
                return throwError(err);
            }));
        }));
        this.chatStartWithSuggestionWorkerRequest$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartWithSuggestionWorkerRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.grapevineChatServices
                .onChatStartWithSuggestionWorkerRequestUsingPOST({
                clientRequest: payload.clientChatStartWithSuggestionWorkerRequest,
            })
                .pipe(map(function (response) {
                return new ChatStartWithSuggestionWorkerRequestSuccess({
                    chat: response.chat,
                });
            }), catchError(function (err) {
                _this.store.dispatch(new ChatStartWithSuggestionWorkerRequestFail(err));
                return throwError(err);
            }));
        }));
        this.navigateChatOnChatStartOnCommentRequestSuccess$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartOnCommentRequestSuccess), tap(function (response) {
            _this.router.navigate(['/chat/' + response.payload.chat.id]);
        }));
        this.navigateChatChatStartOnPostRequestSuccess$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartOnPostRequestSuccess), tap(function (response) {
            _this.router.navigate(['/chat/' + response.payload.chat.id]);
        }));
        this.navigateChatChatStartWithSuggestionWorkerRequestSuccess$ = this.actions$.pipe(ofType(ChatsActionTypes.ChatStartWithSuggestionWorkerRequestSuccess), tap(function (response) {
            _this.router.navigate(['/chat/' + response.payload.chat.id]);
        }));
    }
    /**
     * Notify the user new incoming messages.
     * Compare the lastReceivedMessageTimestamp with each lastMessage of chats.
     * 1. If creation time of a lastMessage of a chat if newer than lastReceivedMessageTimestamp then,
     * is notified to the user.
     * 2. Newest message creation timestamp is saved in lastReceivedMessageTimestamp.
     * @param lastReceivedMessageTimestamp
     * @param chats
     */
    ChatsEffects.prototype.notifyNewMessages = function (lastReceivedMessageTimestamp, chats) {
        var _this = this;
        // convert lastReceivedMessageTimestamp in moment object
        var lastReceivedTimestamp = moment(lastReceivedMessageTimestamp);
        // new array of chats that has newer lastMessage cretion timestamp than lastReceivedTimestamp
        var chatsWithNewMessages = chats.filter(function (chat) {
            return chat.lastMessage &&
                lastReceivedTimestamp.isBefore(chat.lastMessage.created);
        });
        var newMessages = chatsWithNewMessages.map(function (chat) { return chat.lastMessage; });
        // notify user if there are new messages
        if (lastReceivedMessageTimestamp !== 0) {
            newMessages.forEach(function (message) {
                var title = 'Neue Nachricht - ';
                var nameStringType = NameStringType.FirstNameAndFirstCharOfLastName;
                title += PersonService.getNameStringFromClientChatMessage(message, nameStringType);
                _this.notificationService.showMessage(message.message, title, MessageType.INFO, NotificationType.TOAST);
            });
        }
        if (newMessages.length > 0) {
            // Dispatch action to update lastReceivedMessageTimestamp
            this.store.dispatch(new UpdateLastReceivedMessageTimestampRequest({
                lastReceivedMessageTimestamp: this.getNewestMessageTimestamp(newMessages),
            }));
        }
    };
    /**
     * Gets the newest message creation timestamp among given messages.
     * If there are no messages, the default timestamp is 0.
     * @param messages
     */
    ChatsEffects.prototype.getNewestMessageTimestamp = function (messages) {
        var newestMessageTimestamp = 0;
        messages.map(function (message) {
            if (moment(message.created).isAfter(newestMessageTimestamp)) {
                newestMessageTimestamp = message.created;
            }
        });
        return newestMessageTimestamp;
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "loadAllChats$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "setReadChat$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "chatStartOnCommentRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "chatStartOnPostRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "chatStartWithSuggestionWorkerRequest$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "navigateChatOnChatStartOnCommentRequestSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "navigateChatChatStartOnPostRequestSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ChatsEffects.prototype, "navigateChatChatStartWithSuggestionWorkerRequestSuccess$", void 0);
    return ChatsEffects;
}());
export { ChatsEffects };
