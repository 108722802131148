import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMessages from './messages.reducer';

export const selectMessagesState = createFeatureSelector<
  fromMessages.MessageState
>('messages');

export const selectAllMessages = createSelector(
  selectMessagesState,
  fromMessages.selectAll,
);

export const selectMessagesByChatId = (chatId: string) =>
  createSelector(
    selectAllMessages,
    messageState =>
      messageState.filter(actualMessage => {
        if (!actualMessage) {
          return;
        } else {
          return actualMessage.chatId === chatId;
        }
      }),
  );

export const selectLastTimestampByChatId = (chatId: string) =>
  createSelector(
    selectMessagesState,
    messageState => {
      return messageState.timestamps === undefined ||
        messageState.timestamps[chatId] === undefined
        ? 0
        : messageState.timestamps[chatId].timestamp;
    },
  );
