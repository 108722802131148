// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version + '-dev',
  commitHash: env.npm_package_gitHead,
  auth0Config: {
    clientID: 'VuFxqFMVvwTNLBILFLoaaJje417hL72Q',
    domain: 'dev-auth.digitale-doerfer.de',
    redirectUri: 'https://dev-loesbar.digitale-doerfer.de/callback',
    audience: 'https://dev.digitale-doerfer.de/',
    responseType: 'token id_token',
    scope: 'openid email offline_access ',
  },
  loggedOutUrl: 'https://dev-loesbar.digitale-doerfer.de/logged-out',
  serverUrl: 'https://dev.digitale-doerfer.de',
  googleMapsApiKey: 'AIzaSyBsoK0YKWffv8doZ7-oxo1Y12K4fnNak60',
  defaultLanguage: 'de-DE',
  supportedLanguages: ['de-DE'],
};
