import { Action } from '@ngrx/store';
import {
  ClientChatMessage,
  ClientChatMessageSendRequest,
} from '@app/api/models';

export enum MessagesActionTypes {
  AllMessagesRequestSuccess = '[DVP API] Messages Loaded Success',
  AllMessagesRequestFail = '[DVP API] Messages Loaded Fail',
  SendMessageRequest = '[Chat View Component] Send Message Request',
  SendMessageRequestSuccess = '[DVP API]  Send Message Success',
  SendMessageRequestFail = '[DVP API] Send Message Success',
  PrevPageReadRequest = '[DVP API] PrevPageReadRequest',
  PrevPageReadRequestSuccess = '[DVP API] PrevPageReadRequest Success',
  PrevPageReadRequestFail = '[DVP API] PrevPageReadRequest Fail',
}

export class AllMessagesRequestSuccess implements Action {
  readonly type = MessagesActionTypes.AllMessagesRequestSuccess;
  constructor(
    public payload: {
      messages: ClientChatMessage[];
      timestamp: number;
      chatID: string;
    },
  ) {}
}

export class AllMessagesRequestFail implements Action {
  readonly type = MessagesActionTypes.AllMessagesRequestFail;
  constructor(public payload: any) {}
}

export class SendMessageRequest implements Action {
  readonly type = MessagesActionTypes.SendMessageRequest;
  constructor(public payload: { message: ClientChatMessageSendRequest }) {}
}

export class SendMessageRequestSuccess implements Action {
  readonly type = MessagesActionTypes.SendMessageRequestSuccess;
  constructor(public payload: { message: ClientChatMessage }) {}
}

export class SendMessageRequestFail implements Action {
  readonly type = MessagesActionTypes.SendMessageRequestFail;
  constructor(public payload: any) {}
}

export class PrevPageReadRequest implements Action {
  readonly type = MessagesActionTypes.PrevPageReadRequest;
  constructor(public payload: { chatID: string; nbMessagesLoaded: number }) {}
}

export class PrevPageReadRequestSuccess implements Action {
  readonly type = MessagesActionTypes.PrevPageReadRequestSuccess;
  constructor(public payload: { messages: ClientChatMessage[] }) {}
}

export class PrevPageReadRequestFail implements Action {
  readonly type = MessagesActionTypes.PrevPageReadRequestFail;
  constructor(public payload: any) {}
}

export type MessagesActions =
  | AllMessagesRequestSuccess
  | AllMessagesRequestFail
  | SendMessageRequest
  | SendMessageRequestSuccess
  | SendMessageRequestFail
  | PrevPageReadRequest
  | PrevPageReadRequestSuccess
  | PrevPageReadRequestFail;
