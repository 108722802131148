import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { CallbackComponent } from './callback/callback.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    SharedModule,
    LoginRoutingModule,
  ],
  declarations: [LoginComponent, CallbackComponent, LoggedOutComponent],
})
export class LoginModule {}
