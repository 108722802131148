import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { environment } from '@env/environment';
import { CoreModule, HttpErrorInterceptor } from '@app/core';
import { SharedModule } from '@app/shared';
import { LoesbarModule } from './loesbar/loesbar.module';
import { ShellModule } from './shell/shell.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLoadModule } from './app-load.module';
import {
  StoreRouterConnectingModule,
  NavigationActionTiming,
} from '@ngrx/router-store';
import { CustomSerializer } from './store/custom-route-serializer';
import { ChatSharedModule } from './loesbar/chat-shared/chat-shared.module';
import { SuggestionPollingService } from './loesbar/suggestion-store/suggestion-polling.service';
import { GlobalErrorHandler } from './core/global-error.interceptor';
import { coreStateReducer } from './core/store/core-store';
import { ErrorBarComponent } from './error-bar/error-bar.component';
import { SharedStoreModule } from './shared/shared-store/shared-store.module';

@NgModule({
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    SharedStoreModule,
    ShellModule,
    LoesbarModule,
    LoginModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature('coreState', coreStateReducer),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    AppLoadModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, ErrorBarComponent],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    SuggestionPollingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
