/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientUserGeneratedContentFlagResponse } from '../models/client-user-generated-content-flag-response';
import { ClientChatFlagRequest } from '../models/client-chat-flag-request';
import { ClientChatMessageSentConfirmation } from '../models/client-chat-message-sent-confirmation';
import { ClientChatMessageSendRequest } from '../models/client-chat-message-send-request';
import { ClientChatReadRequest } from '../models/client-chat-read-request';

/**
 * Chat Event Controller
 */
@Injectable({
  providedIn: 'root',
})
class CommunicationChatEventsService extends __BaseService {
  static readonly onChatFlagRequestUsingPOSTPath = '/communication/chat/event/chatFlagRequest';
  static readonly sendMessageUsingPOSTPath = '/communication/chat/event/chatMessageSendRequest';
  static readonly setReadUsingPOSTPath = '/communication/chat/event/chatReadRequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * caller must be participant of the chat
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.OnChatFlagRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onChatFlagRequestUsingPOSTResponse(params: CommunicationChatEventsService.OnChatFlagRequestUsingPOSTParams): __Observable<__StrictHttpResponse<ClientUserGeneratedContentFlagResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/communication/chat/event/chatFlagRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientUserGeneratedContentFlagResponse>;
      })
    );
  }
  /**
   * caller must be participant of the chat
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.OnChatFlagRequestUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  onChatFlagRequestUsingPOST(params: CommunicationChatEventsService.OnChatFlagRequestUsingPOSTParams): __Observable<ClientUserGeneratedContentFlagResponse> {
    return this.onChatFlagRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientUserGeneratedContentFlagResponse)
    );
  }

  /**
   * Only a participant of the chat can send messages. The temporaryMediaItemId is optional
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  sendMessageUsingPOSTResponse(params: CommunicationChatEventsService.SendMessageUsingPOSTParams): __Observable<__StrictHttpResponse<ClientChatMessageSentConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/communication/chat/event/chatMessageSendRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientChatMessageSentConfirmation>;
      })
    );
  }
  /**
   * Only a participant of the chat can send messages. The temporaryMediaItemId is optional
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * |`TEMPORARY_MEDIA_ITEM_NOT_FOUND`|404 NOT_FOUND|The requested temporary media item does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   *
   * @return OK
   */
  sendMessageUsingPOST(params: CommunicationChatEventsService.SendMessageUsingPOSTParams): __Observable<ClientChatMessageSentConfirmation> {
    return this.sendMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ClientChatMessageSentConfirmation)
    );
  }

  /**
   * Only a participant of the chat can set the read timestamp
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.SetReadUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   */
  setReadUsingPOSTResponse(params: CommunicationChatEventsService.SetReadUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.clientRequest;
    if (params.appVariantIdentifier != null) __headers = __headers.set('appVariantIdentifier', params.appVariantIdentifier.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/communication/chat/event/chatReadRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Only a participant of the chat can set the read timestamp
   *
   * ---
   *
   * ## Authenticated via OAuth 2.0
   *
   * User must be participant of chat
   *
   * ## Potential Exceptions
   *
   * |Type|Status Code|Description|
   * | ---- | ---- | ---- |
   * |`PERSON_NOT_CHAT_PARTICIPANT`|403 FORBIDDEN|The person trying to access the chat is not a participant of the chat|
   * |`CHAT_NOT_FOUND`|404 NOT_FOUND|The requested chat does not exist|
   * | ― | ― | ― |
   * |`EVENT_ATTRIBUTE_INVALID`|400 BAD_REQUEST|An attribute of the provided event is not valid|
   * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
   * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
   * @param params The `CommunicationChatEventsService.SetReadUsingPOSTParams` containing the following parameters:
   *
   * - `clientRequest`: clientRequest
   *
   * - `appVariantIdentifier`: optional header to provide identifier of the app variant
   */
  setReadUsingPOST(params: CommunicationChatEventsService.SetReadUsingPOSTParams): __Observable<null> {
    return this.setReadUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CommunicationChatEventsService {

  /**
   * Parameters for onChatFlagRequestUsingPOST
   */
  export interface OnChatFlagRequestUsingPOSTParams {

    /**
     * request
     */
    request: ClientChatFlagRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for sendMessageUsingPOST
   */
  export interface SendMessageUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientChatMessageSendRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }

  /**
   * Parameters for setReadUsingPOST
   */
  export interface SetReadUsingPOSTParams {

    /**
     * clientRequest
     */
    clientRequest: ClientChatReadRequest;

    /**
     * optional header to provide identifier of the app variant
     */
    appVariantIdentifier?: string;
  }
}

export { CommunicationChatEventsService }
