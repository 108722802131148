import { Injectable } from '@angular/core';
import { Observable, of, timer, bindNodeCallback, throwError } from 'rxjs';
import * as auth0 from 'auth0-js';
import { environment } from '@env/environment';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { ParticipantsPersonService } from '@app/api/services';
import { ClientPerson } from '@app/api/models';
import { Location } from '@angular/common';

export interface AuthResult {
  accessToken: string;
  idToken: string;
  expiresAt: number;
}

@Injectable()
export class AuthResultLocalStore {
  private isLoggedInKey = 'isLoggedIn';
  private authResult: AuthResult = null;

  get authToken(): AuthResult | null {
    if (!this.isLoggedIn) {
      return null;
    }
    return this.authResult;
  }

  set authToken(authResult: AuthResult | null) {
    if (authResult) {
      this.authResult = authResult;
      localStorage.setItem(this.isLoggedInKey, 'true');
    } else {
      this.authResult = null;
      localStorage.setItem(this.isLoggedInKey, 'false');
    }
  }

  get isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem(this.isLoggedInKey));
  }
}

const log = new Logger('AuthenticationService');

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  auth0 = new auth0.WebAuth(environment.auth0Config);

  checkSession$ = bindNodeCallback(this.auth0.checkSession.bind(this.auth0));
  parseHash$ = bindNodeCallback(this.auth0.parseHash.bind(this.auth0));

  /**
   * refreshSub is the subscription for the token renewal timer
   */
  private refreshSub: any;

  constructor(
    private participantsPersonService: ParticipantsPersonService,
    private authResultStore: AuthResultLocalStore,
    private location: Location,
  ) {}

  public login(): void {
    this.logout();
    this.saveRedirectUrl(this.location.path());
    this.auth0.authorize({ mode: 'login' });
  }

  public handleAuthentication(): Observable<boolean> {
    log.debug('handleAuthentication() called');
    return this.parseHash$().pipe(
      mergeMap(decodedHash => {
        window.location.hash = '';
        return this.localLogin(decodedHash);
      }),
      mergeMap(() => {
        return this.loadPersonFromBackend();
      }),
    );
  }

  private loadPersonFromBackend(): Observable<boolean> {
    // FIXME: this is a bad workaround until we have a proper person store!
    log.debug('Loading person from backend');
    return this.participantsPersonService.getPersonUsingGET().pipe(
      map((person: ClientPerson) => {
        log.debug('Got person from backend');
        let oldPerson = JSON.parse(sessionStorage.getItem('currentUser'));
        if (oldPerson && oldPerson.id !== person.id) {
          throw new Error('Person ID missmatch! Abort login');
        }
        
        sessionStorage.setItem('currentUser', JSON.stringify(person));
        return true;
      }),
    );
  }

  public updatePerson(person:ClientPerson){
    sessionStorage.setItem('currentUser', JSON.stringify(person));
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  public logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setAuthToken();
    this.unscheduleRenewal();
    localStorage.clear();
    sessionStorage.clear();
    this.auth0.logout({
      returnTo: environment.loggedOutUrl,
      clientID: environment.auth0Config.clientID,
    });
    return of(true);
  }

  public isLoggedIn(): boolean {
    return this.authResultStore.isLoggedIn;
  }

  public hasValidToken(): boolean {
    return (
      this.authResultStore.isLoggedIn &&
      this.authResultStore.authToken &&
      this.authResultStore.authToken.expiresAt > Date.now()
    );
  }

  public renewTokens(): Observable<boolean> {
    if (!this.isLoggedIn()) {
      return throwError('No token available. Do not allow renew.');
    }

    log.debug('Renew tokens...');
    return this.checkSession$({}).pipe(
      mergeMap(result => {
        return this.localLogin(result);
      }),
      mergeMap(() => {
        return this.loadPersonFromBackend();
      }),
      catchError(err => {
        // FIXME: is this error handling needed here?
        // Normally, everyone who calls renewTokens should implement error handling
        log.error(err);
        this.login(); // Try normal login on error
        return of(false);
      }),
    );
  }

  public scheduleRenewal() {
    log.debug('scheduleRenewal');
    if (!this.hasValidToken()) {
      return;
    }
    this.unscheduleRenewal();

    const expiresAt = this.authResultStore.authToken.expiresAt;

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap(expiresAt => {
        const now = Date.now();
        const refreshAt = expiresAt - 1000 * 30; // Refresh 30 seconds before expiry
        // Use timer to track delay until expiration
        // to run the refresh at the proper time
        return timer(Math.max(1, refreshAt - now));
      }),
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSub = expiresIn$.subscribe(() => {
      log.debug('Refreshing auth token before expiry');
      this.renewTokens().subscribe();
    });
  }

  public unscheduleRenewal() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  private localLogin(tokenPayload: any): Observable<boolean> {
    // Workaround DD-4935: avoid local login id tokenPayload is undefined
    if (!tokenPayload) {
      return of(false);
    }
    // Set the time that the access token will expire at
    const expiresAt = tokenPayload.expiresIn * 1000 + Date.now();

    const authResult: AuthResult = {
      accessToken: tokenPayload.accessToken,
      idToken: tokenPayload.idToken,
      expiresAt: expiresAt,
    };

    this.setAuthToken(authResult);

    this.scheduleRenewal();

    log.debug('Processed token payload from auth0.');
    return of(true);
  }

  /**
   * Save the given post url to redirect after login.
   */
  private saveRedirectUrl(postUrl: string) {
    const redirectUrl = localStorage.getItem('redirectUri');
    if (
      redirectUrl == null &&
      postUrl != null &&
      (postUrl.startsWith('/vorschlag') || postUrl.startsWith('/chat'))
    ) {
      localStorage.setItem('redirectUri', postUrl);
    }
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get authToken(): AuthResult | null {
    return this.authResultStore.authToken;
  }

  private setAuthToken(authResult?: AuthResult) {
    this.authResultStore.authToken = authResult;
  }
}
