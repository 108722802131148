import { MessagesActions, MessagesActionTypes } from './messages.actions';
import { ClientChatMessage } from '@app/api/models';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export interface PollingTimestampChat {
  [key: string]: { timestamp: number };
}

export interface MessageState extends EntityState<ClientChatMessage> {
  loading: boolean;
  timestamps: PollingTimestampChat;
}

const sortMessages = (a: ClientChatMessage, b: ClientChatMessage): number => {
  return a.sentTime < b.sentTime ? -1 : 1;
};

export const adapter: EntityAdapter<ClientChatMessage> = createEntityAdapter<
  ClientChatMessage
>({
  sortComparer: sortMessages,
});

export const initialMessageState: MessageState = adapter.getInitialState({
  loading: false,
  timestamps: {},
});

export function messageReducer(
  state: MessageState = initialMessageState,
  action: MessagesActions,
): MessageState {
  switch (action.type) {
    case MessagesActionTypes.AllMessagesRequestSuccess:
      // clone timestamps
      const newTimestampList = Object.assign({}, state.timestamps);
      // add or update timestamps object
      newTimestampList[action.payload.chatID] = {
        timestamp: action.payload.timestamp,
      };
      return adapter.upsertMany(action.payload.messages.reverse(), {
        ...state,
        loading: false,
        timestamps: newTimestampList,
      });
    case MessagesActionTypes.PrevPageReadRequestSuccess:
      return adapter.upsertMany(action.payload.messages, state);
    case MessagesActionTypes.SendMessageRequestSuccess:
      return adapter.addOne(action.payload.message, { ...state });
    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
