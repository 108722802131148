import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Data Privacy Controller
 */
var SharedDataprivacyService = /** @class */ (function (_super) {
    tslib_1.__extends(SharedDataprivacyService, _super);
    function SharedDataprivacyService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    SharedDataprivacyService.prototype.deletePersonUsingDELETE1Response = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/dataprivacy/myPersonAndAllMyData", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    SharedDataprivacyService.prototype.deletePersonUsingDELETE1 = function (appVariantIdentifier) {
        return this.deletePersonUsingDELETE1Response(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * The report is sent to the email the given person registered.
     *
     * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    SharedDataprivacyService.prototype.collectUserDataAndSendMailUsingPOSTResponse = function (appVariantIdentifier) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (appVariantIdentifier != null)
            __headers = __headers.set('appVariantIdentifier', appVariantIdentifier.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/dataprivacy/report/mail", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * The report is sent to the email the given person registered.
     *
     * Returns OK when the action could be started successfully. Runs the delete process asynchronously.
     *
     * ---
     *
     * ## Authenticated via OAuth 2.0
     *
     * ## Potential Exceptions
     *
     * |Type|Status Code|Description|
     * | ---- | ---- | ---- |
     * |`NOT_AUTHORIZED`|403 FORBIDDEN|The user is not authorized to do this action or view the data|
     * |`PERSON_WITH_OAUTH_ID_NOT_FOUND`|404 NOT_FOUND|No person exists with the given OAuth ID|
     * @param appVariantIdentifier optional header to provide identifier of the app variant
     * @return OK
     */
    SharedDataprivacyService.prototype.collectUserDataAndSendMailUsingPOST = function (appVariantIdentifier) {
        return this.collectUserDataAndSendMailUsingPOSTResponse(appVariantIdentifier).pipe(__map(function (_r) { return _r.body; }));
    };
    SharedDataprivacyService.deletePersonUsingDELETE1Path = '/dataprivacy/myPersonAndAllMyData';
    SharedDataprivacyService.collectUserDataAndSendMailUsingPOSTPath = '/dataprivacy/report/mail';
    SharedDataprivacyService.ngInjectableDef = i0.defineInjectable({ factory: function SharedDataprivacyService_Factory() { return new SharedDataprivacyService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: SharedDataprivacyService, providedIn: "root" });
    return SharedDataprivacyService;
}(__BaseService));
export { SharedDataprivacyService };
