import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { Logger, AuthenticationService } from '@app/core';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string = environment.version;
  error: string;
  isLoggedIn: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.forwardToAuth0();
  }

  ngOnInit() {}

  private forwardToAuth0() {
    if (!this.isLoggedIn) {
      // move forward to login page
      this.authenticationService.login();
    } else {
      // What to do here?
    }
  }
}
